import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  cloneScenario,
  type CloneScenarioInput,
  createScenario,
  type CreateScenarioInput,
  deleteScenarioById,
  type DeleteScenarioInput,
  generateScenarioData,
  type GenerateScenarioDataInput,
  getScenarioById,
  type GetScenarioByIdInput,
  getScenariosByDatasetId,
  type GetScenariosByDatasetIdInput,
  getScenariosByDataTableId,
  type GetScenariosByDataTableIdInput,
  getScenariosByModelId,
  type GetScenariosByModelIdInput,
  getScenarioVectors,
  type GetScenarioVectorsInput,
  updateModelScenarios,
  updateScenarioById,
  type UpdateScenarioByIdInput,
  type UpdateScenariosInput,
} from '../api/scenarios';
import {
  type TypeModel,
  type TypeScenario,
  type TypeScenarioVector,
} from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useCreateScenario = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeModel>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createScenario'],
    mutationFn: async (input: CreateScenarioInput) => {
      const { data }: AxiosResponse<TypeModel> = await createScenario(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useUpdateModelScenarios = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeModel>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    ...opts,
    mutationKey: ['updateModelScenarios'],
    mutationFn: async (input: UpdateScenariosInput) => {
      const { data }: AxiosResponse<TypeModel> =
        await updateModelScenarios(input);

      return data;
    },
  });

export const useUpdateScenarioById = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeModel>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useUpdateScenarioById'],
    mutationFn: async (input: UpdateScenarioByIdInput) => {
      const { data }: AxiosResponse<TypeModel> =
        await updateScenarioById(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenarioById'],
      });
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useDeleteScenarioById = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationKey: ['deleteScenarioById'],
    mutationFn: async (input: DeleteScenarioInput) => {
      const res: AxiosResponse = await deleteScenarioById(input);

      return res;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDataTableId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useGenerateScenarioData = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<Record<string, string | number>[]>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    ...opts,
    mutationKey: ['generateScenarioData'],
    mutationFn: async (input: GenerateScenarioDataInput) => {
      const { data }: AxiosResponse<Record<string, string | number>[]> =
        await generateScenarioData(input);

      return data;
    },
  });

export const useGetScenariosByDatasetId = (
  input: GetScenariosByDatasetIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenariosByDatasetId', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> =
        await getScenariosByDatasetId(input);

      return data;
    },
    ...opts,
  });

export const useGetScenarioVectors = (
  input: GetScenarioVectorsInput,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeScenarioVector[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getScenarioVectors', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenarioVector[]> =
        await getScenarioVectors(input);

      return data;
    },
    ...opts,
  });

export const useGetScenarioById = (
  input: GetScenarioByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenarioById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario> =
        await getScenarioById(input);

      return data;
    },
    ...opts,
  });

export const useCloneScenario = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeScenario>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['cloneModel'],
    mutationFn: async (input: CloneScenarioInput) => {
      const { data } = await cloneScenario(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getScenariosByDatasetId'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetScenariosByDataTableId = (
  input: GetScenariosByDataTableIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenariosByDataTableId', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> =
        await getScenariosByDataTableId(input);

      return data;
    },
    ...opts,
  });

export const useGetScenariosByModelId = (
  input: GetScenariosByModelIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeScenario[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getScenariosByModelId', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeScenario[]> =
        await getScenariosByModelId(input);

      return data;
    },
    ...opts,
  });
