import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/base';
import * as z from 'zod';

import { type DefaultMutationError } from '../../hooks';
import {
  type Invitation,
  useCreateOrganizationMember,
  useVerifyInvitation,
} from '../../hooks/useOrganizationHook';
import OutlineLogo from '../../icons/OutlineLogo';
import routes from '../../routes';
import cn from '../../utils/cn';
import FormInput from '../FormInput';

import VerificationFailed from './VerificationFailed';

const CreateMemberSchema = z
  .object({
    email: z.string().trim().email('Invalid email address'),
    first_name: z.string().trim().min(1, 'This field is required'),
    last_name: z.string().trim().min(1, 'This field is required'),
    password: z
      .string()
      .trim()
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: z
      .string()
      .min(8, 'Confirm Password must be at least 8 characters'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Confirm password does not match',
    path: ['confirmPassword'],
  });

const CreateMemberForm = ({ invitation }: { invitation: Invitation }) => {
  const { org_id, token } = useParams();
  const [error, setError] = useState<DefaultMutationError>();

  const navigate = useNavigate();

  const { invited_by, email } = invitation;

  const form = useForm({
    resolver: zodResolver(CreateMemberSchema),
    defaultValues: {
      invited_by: invited_by.user.email,
      email,
    },
  });

  const { mutate: createMember } = useCreateOrganizationMember({
    onSuccess: (data) => {
      navigate(routes.login);
    },
    onError: (error) => {
      setError(error);
    },
  });

  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset();
  }, [invited_by, invitation]);

  const onSubmit: SubmitHandler<any> = (data) => {
    if (token && org_id) {
      createMember({
        id: Number(org_id),
        data: {
          token,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          password: data.password,
        },
      });
    }
  };

  return (
    <div className="flex w-full justify-center">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="border-grey-500 flex w-full max-w-[600px] flex-col items-start gap-3 rounded-xl border-[2px] px-8 py-6 text-white"
        >
          <h1 className="text-2xl font-bold uppercase">welcome!</h1>
          <p>Create your account</p>
          <FormInput
            fullWidth
            disabled
            name="invited_by"
            labelClassName="text-white"
            label="You're invited by"
            style={{
              backgroundColor: 'white',
            }}
          />
          <FormInput
            fullWidth
            disabled
            name="email"
            label="Email"
            labelClassName="text-white"
            style={{
              backgroundColor: 'white',
            }}
          />
          <FormInput
            fullWidth
            name="first_name"
            label="First Name"
            labelClassName="text-white"
            style={{
              backgroundColor: 'white',
            }}
          />
          <FormInput
            fullWidth
            name="last_name"
            label="Last Name"
            labelClassName="text-white"
            style={{
              backgroundColor: 'white',
            }}
          />
          <FormInput
            fullWidth
            name="password"
            label="Password"
            labelClassName="text-white"
            type="password"
            style={{
              backgroundColor: 'white',
            }}
          />
          <FormInput
            fullWidth
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            labelClassName="text-white"
            style={{
              backgroundColor: 'white',
            }}
          />
          <Button
            type="submit"
            style={{
              backgroundColor: '#B8341B',
              color: 'white',
              width: '100%',
              marginTop: 20,
              padding: '12px 0px',
              borderRadius: '4px',
            }}
          >
            Create Account
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

const InvitationVerification = () => {
  const [invitation, setInvitation] = useState<Invitation | null>(null);

  const [status, setStatus] = useState<'success' | 'pending' | 'failed'>(
    'pending'
  );

  const navigate = useNavigate();
  const { org_id, token, invitation_id } = useParams();

  const { mutate: verifyInvitation } = useVerifyInvitation({
    onSuccess: (data) => {
      if (!data.is_new_user) {
        navigate(routes.home);
        return;
      }

      setInvitation(data);
      setStatus('success');
    },
    onError: (error) => {
      setStatus('failed');
    },
  });

  useEffect(() => {
    if (org_id && token && invitation_id) {
      verifyInvitation({
        id: Number(org_id),
        invitationId: Number(invitation_id),
        data: {
          token,
        },
      });
    }
  }, [org_id, token, invitation_id]);

  return (
    <div
      className={cn(
        'space-y-4 h-full 3xl:h-screen bg-[#01285F] px-[90px] py-7'
      )}
    >
      <div className="flex items-center justify-start gap-3 text-white">
        <OutlineLogo />
        <p className="text-[22px] font-bold">ISBE Group</p>
      </div>
      <div>
        {status === 'success' && invitation && (
          <CreateMemberForm invitation={invitation} />
        )}
        {status === 'failed' && <VerificationFailed />}
      </div>
    </div>
  );
};

export default InvitationVerification;
