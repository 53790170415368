import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Checkbox, Collapse } from '@mui/material';
import _ from 'lodash';
import { Pencil, Trash2 } from 'lucide-react';

import { type TypePredefinedExpense } from '../../common/dataTypes';
import { toastPromise } from '../../common/utils';
import {
  useGetExpenseSetById,
  useGetPreDefinedExpenses,
  useUpdateExpenseSet,
} from '../../hooks/useExpenseHook';

import AddExpenseFormDialog, {
  type AddExpenseFormSchemaType,
} from './AddExpenseFormDialog';

type CheckboxesCollapseProps = {
  name: string;
  title: string;
  group?: 'default' | 'user';
  items: {
    label: string;
    value: string;
  }[];
};

const CheckboxesCollapse = ({
  title,
  name,
  items,
  group = 'default',
}: CheckboxesCollapseProps) => {
  const [open, setOpen] = useState(true);
  const [checkedAll, setCheckAll] = useState(false);

  const [searchParams] = useSearchParams();
  const { org_id, project_id } = useParams();

  const expenseSetId = searchParams.get('expense_set_id');

  const { data: expenseSet } = useGetExpenseSetById(
    {
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id) ?? NaN,
      expenseSetId: Number(expenseSetId) ?? NaN,
    },
    {
      enabled: !!org_id && !!project_id && !!expenseSetId,
    }
  );

  const { register, setValue, getValues } = useFormContext();

  const defaultName = `${group}.${name}`;

  const data = getValues(defaultName);

  const isIndeterminate =
    !checkedAll && Object.keys(data ?? {}).some((key) => data[key]);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!expenseSet?.predefined_expenses) {
      return;
    }

    const preDefinedExpenses = expenseSet?.predefined_expenses ?? [];

    const defaultValues = items.reduce(
      (acc, cur) => {
        acc[cur.value] = preDefinedExpenses.includes(Number(cur.value));
        return acc;
      },
      {} as Record<string, boolean>
    );

    if (_.isEqual(data, defaultValues) || !defaultValues || data) {
      return;
    }

    setValue(defaultName, defaultValues);
  }, [expenseSet?.predefined_expenses, defaultName, items]);

  useEffect(() => {
    const isCheckedAll = items.every((item) => data?.[item?.value]);

    setCheckAll(isCheckedAll);
  }, [data, items]);

  const handleToggleAll = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();

    const isChecked = !isIndeterminate && (e.target as any).checked;

    setCheckAll(isChecked);

    const all = items.reduce(
      (acc, key) => {
        acc[key.value] = isIndeterminate ? false : isChecked;
        return acc;
      },
      {} as Record<string, boolean>
    );

    setValue(defaultName, all);
  };

  const handleUpdate = (input: AddExpenseFormSchemaType) => {};

  return (
    <div className="px-6">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1 text-[#333]"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <Checkbox
          checked={checkedAll}
          onClick={handleToggleAll}
          indeterminate={isIndeterminate}
        />
        <label className="text-lg font-bold capitalize">{title}</label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="mb-4 ml-12 flex flex-col border-l border-gray-300 pl-3">
          {data &&
            items.map((expenseSet) => (
              <div key={expenseSet.value} className="flex justify-between">
                <div className="flex items-center gap-1">
                  <Checkbox
                    id={expenseSet.value}
                    checked={data[expenseSet.value] || false}
                    {...register(`${defaultName}.${expenseSet.value}`)}
                  />
                  <label htmlFor={expenseSet.value}>{expenseSet.label}</label>
                </div>
                {group === 'user' && (
                  <div className="flex items-center gap-3">
                    <AddExpenseFormDialog
                      trigger={{
                        label: (
                          <button>
                            <Pencil width={20} height={20} color="#999999" />
                          </button>
                        ),
                      }}
                      title="Edit User-Defined Expense"
                      categories={[
                        {
                          label: 'Hello',
                          value: 'hello',
                        },
                      ]}
                      defaultValues={{
                        name: expenseSet.label,
                      }}
                      onSubmit={handleUpdate}
                    />
                    <button>
                      <Trash2 width={20} height={20} color="#999999" />
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      </Collapse>
    </div>
  );
};

const PredefinedExpensesForm = () => {
  const [open, setOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const { org_id, project_id } = useParams();

  const datasetId = searchParams.get('dataset_id');

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id) ?? NaN,
      datasetId: Number(datasetId) ?? NaN,
    },
    {
      enabled: !!org_id && !!project_id && !!datasetId,
    }
  );

  const defaultExpenseSets = predefinedExpenseSets?.reduce((acc, cur) => {
    const groupOrder = cur.group.ifs_order;

    acc[groupOrder] = [...(acc[groupOrder] ?? []), cur];

    acc[groupOrder] = (acc[groupOrder] as TypePredefinedExpense[]).sort(
      (a, b) => a.ifs_order - b.ifs_order
    );

    return acc;
  }, [] as TypePredefinedExpense[][]);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <div className="rounded-[4px] border border-[#E4E7EC] bg-[#FFF] p-6">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1 text-[#666]"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="text-lg font-semibold capitalize">
          Predefined Expenses
        </label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="py-2">
          <>
            {defaultExpenseSets &&
              Array.from(Array(defaultExpenseSets.length - 1).keys()).map(
                (_, index) => {
                  const items = defaultExpenseSets[index + 1];

                  return (
                    <CheckboxesCollapse
                      name={items[0].group.name}
                      title={items[0].group.name}
                      items={items.map((e) => ({
                        label: e.name,
                        value: e.id.toString(),
                      }))}
                    />
                  );
                }
              )}
          </>
        </div>
      </Collapse>
    </div>
  );
};

const UserDefinedExpensesForm = () => {
  const [open, setOpen] = useState(true);

  const { watch, setValue } = useFormContext();

  const defaultData = watch('default');
  const userData = watch('user');

  const handleToggle = () => {
    setOpen(!open);
  };

  const categories = useMemo(
    () =>
      Object.keys(defaultData ?? {}).reduce(
        (acc, cur) => {
          acc = [
            ...acc,
            {
              label: cur,
              value: cur,
            },
          ];
          return acc;
        },
        [] as { label: string; value: string }[]
      ),
    [defaultData]
  );

  const handleAddExpense = (input: AddExpenseFormSchemaType) => {
    const { category, name } = input;

    setValue(`user.${category}.${name}`, true);
  };

  return (
    <div className="my-6 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] p-6">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1 text-[#666]"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="text-lg font-semibold capitalize">
          User-Defined Expenses
        </label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="px-6 pt-3">
          <AddExpenseFormDialog
            title="Add User-Defined Expense"
            categories={
              categories.length
                ? categories
                : [
                    {
                      label: 'Hello',
                      value: 'hello',
                    },
                  ]
            }
            onSubmit={handleAddExpense}
          />
        </div>
        {Object.keys(userData ?? {}).map((category) => (
          <CheckboxesCollapse
            key={category}
            name={category}
            title={category}
            group="user"
            items={Object.keys(userData[category]).map((name) => ({
              label: name,
              value: name,
            }))}
          />
        ))}
      </Collapse>
    </div>
  );
};

const CreateExpenseForm = () => {
  const form = useForm();
  const [searchParams] = useSearchParams();

  const { handleSubmit, watch, formState } = form;
  const { org_id, project_id } = useParams();
  const expenseSetId = searchParams.get('expense_set_id');

  const { data: expenseSet } = useGetExpenseSetById(
    {
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id) ?? NaN,
      expenseSetId: Number(expenseSetId) ?? NaN,
    },
    {
      enabled: !!org_id && !!project_id && !!expenseSetId,
    }
  );

  const { mutateAsync: updateExpense, isPending } = useUpdateExpenseSet();

  const allDefault = Object.values(watch('default') ?? {})
    .map((e: any) => Object.keys(e).filter((key) => e[key]))
    .flat();

  const onSubmit = (data: any) => {
    if (allDefault.length === 0 || !org_id || !project_id || !expenseSet) {
      return;
    }

    const res = updateExpense({
      orgId: Number(org_id),
      projectId: Number(project_id),
      expenseSetId: expenseSet.id,
      data: {
        name: expenseSet.name,
        dataset: expenseSet.dataset,
        predefined_expenses: allDefault.map(Number),
      },
    });

    toastPromise({
      promise: res,
      content: 'Expense set updated successfully',
    });
  };

  return (
    <div className="flex flex-col pb-12">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="sticky top-[60px] z-50 flex justify-between overflow-hidden bg-white py-3">
            <h1 className="text-[28px] font-bold">
              Select Predefined Expenses and User-Defined Expenses
            </h1>
            <LoadingButton
              loading={isPending}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </div>
          <div>
            <PredefinedExpensesForm />
            <UserDefinedExpensesForm />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateExpenseForm;
