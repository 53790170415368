import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Plus } from 'lucide-react';
import * as z from 'zod';

import {
  useCreateModel,
  useGetDatasetById,
  useGetDataTablesByDatasetId,
  useUpdateModel,
} from '../../hooks';
import { useGetScenariosByDatasetId } from '../../hooks/useScenariosHook';
import { handleQueryError } from '../../utils/api';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

const CreateModelSchema = z.object({
  name: z.string().trim(),
  description: z.string().trim().optional(),
  dataset: z.string().trim(),
  scenarios: z.record(z.coerce.string().trim()).optional().nullable(),
});

type CreateModelSchemaType = z.infer<typeof CreateModelSchema>;

type CreateModelFormDialogProps = {
  open: boolean;
  title: string;
  showTrigger?: boolean;
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
  };
  edit?: {
    defaultValues: CreateModelSchemaType;
    type: 'model' | 'scenarios';
  };
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
};

const CreateModelFormDialog = ({
  open,
  title,
  trigger,
  edit,
  showTrigger = true,
  handleOpenDialog,
  handleCloseDialog,
}: CreateModelFormDialogProps) => {
  const [error, setError] = useState();

  const { project_id, org_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const datasetId = searchParams.get('dataset_id');
  const modelId = searchParams.get('model_id');

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!org_id && !!project_id,
    }
  );

  const { data: dataTables } = useGetDataTablesByDatasetId(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!project_id && !!org_id,
    }
  );

  const { data: scenarios } = useGetScenariosByDatasetId(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!org_id && !!project_id,
    }
  );

  const defaultValues = edit?.defaultValues;

  const form = useForm<CreateModelSchemaType>({
    resolver: zodResolver(CreateModelSchema),
    defaultValues: defaultValues
      ? {
          name: defaultValues.name,
          description: defaultValues.description,
          dataset: defaultValues.dataset,
          scenarios: defaultValues.scenarios,
        }
      : {
          name: '',
          description: '',
          dataset: dataset?.name,
          scenarios: {},
        },
  });

  const { handleSubmit, formState, setValue, watch, reset } = form;
  const { isSubmitting, errors } = formState;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (dataset) {
      setValue('dataset', dataset.name);
    }
  }, [dataset]);

  const { mutate: createModel } = useCreateModel({
    onSuccess(data) {
      searchParams.set('model_id', data.id.toString());

      setSearchParams(searchParams);

      reset();
      handleCloseDialog();
    },
    onError(error) {
      handleQueryError(error, setError);
    },
  });

  const { mutate: updateModel } = useUpdateModel({
    onSuccess() {
      reset();
      handleCloseDialog();
    },
    onError(error) {
      handleQueryError(error, setError);
    },
  });

  const onSubmit: SubmitHandler<CreateModelSchemaType> = async () => {
    const watchData = watch();

    const { name, description, scenarios } = watchData;

    const data = {
      name,
      description,
      dataset: Number(datasetId),
      scenarios: scenarios
        ? Object.values(scenarios).map((s) => Number(s))
        : [],
    };

    if (defaultValues) {
      updateModel({
        orgId: Number(org_id),
        projectId: Number(project_id),
        modelId: Number(modelId),
        data,
      });
      return;
    }

    createModel({
      orgId: Number(org_id),
      projectId: Number(project_id),
      data,
    });
  };

  const getScenariosByDataTable = (dataTableId: number) =>
    scenarios
      ?.filter((s) => s.data_table === dataTableId)
      .map((scenario) => ({
        value: scenario.id,
        label: scenario.name,
      })) ?? [];

  const isEditModel = defaultValues ? edit.type === 'model' : true;
  const isEditModelScenarios = defaultValues ? edit.type === 'scenarios' : true;

  return (
    <>
      {showTrigger && (
        <button onClick={handleOpenDialog} style={trigger?.style}>
          {trigger ? (
            trigger.label
          ) : (
            <Plus width={20} height={20} color="#E8F2FF" />
          )}
        </button>
      )}
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-2xl font-bold">{title}</h2>
        </DialogTitle>
        <DialogContent>
          <div>
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mb-2 space-y-6"
              >
                <div className="space-y-6">
                  {isEditModel && (
                    <>
                      <FormInput
                        label="Model Name"
                        name="name"
                        inputProps={{
                          maxLength: 30,
                        }}
                        required
                        fullWidth
                      />
                      <FormInput
                        label="Model Description"
                        name="description"
                        fullWidth
                      />
                    </>
                  )}
                  {isEditModelScenarios && (
                    <div className="space-y-3">
                      {!defaultValues && (
                        <h2 className="text-lg font-semibold">Scenarios</h2>
                      )}
                      <div className="grid grid-cols-2 gap-3">
                        {scenarios &&
                          dataTables?.map((dataTable) => {
                            const options = getScenariosByDataTable(
                              dataTable.id
                            );

                            const defaultValue =
                              defaultValues?.scenarios?.[dataTable.name] ??
                              options[0]?.value;

                            return (
                              <FormSelect
                                options={options}
                                defaultValue={defaultValue}
                                key={dataTable.id}
                                label={dataTable.name}
                                name={`scenarios.${dataTable.name}`}
                                fullWidth
                              />
                            );
                          })}
                      </div>
                    </div>
                  )}
                  {!defaultValues && (
                    <FormInput
                      label="Dataset"
                      name="dataset"
                      disabled
                      value={dataset?.name}
                      required
                      fullWidth
                    />
                  )}
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    {isEditModel || isEditModelScenarios ? 'Save' : 'Create'}
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateModelFormDialog;
