import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button, Dialog, OutlinedInput } from '@mui/material';
import { Trash2 } from 'lucide-react';

import { type DataRow } from '../common/BaseTable';
import { type TableType } from '../common/dataTypes';
import IconButton from '../common/IconButton';
import { toastPromise } from '../common/utils';
import { useDeleteDataset, useDeleteProject } from '../hooks';
import { useCurrentOrganization } from '../utils/helpers';

type ConfirmDeleteDialogProps = {
  label?: string;
  type: TableType;
  row: DataRow;
};

const ConfirmDeleteDialog = ({
  label,
  type,
  row,
}: ConfirmDeleteDialogProps) => {
  const [open, setOpen] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const currentOrg = useCurrentOrganization();

  const { reset } = useFormContext();

  const { project_id } = useParams();

  const [error, setError] = useState<{
    isError: boolean;
    message: string;
  }>({
    isError: false,
    message: '',
  });

  const { mutateAsync: deleteDataset } = useDeleteDataset({
    onSuccess: () => {
      setOpen(false);
      reset();
    },
  });

  const { mutateAsync: deleteProject } = useDeleteProject({
    onSuccess: () => {
      setOpen(false);
      reset();
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  };

  const handleSubmit = () => {
    if (confirmText !== row.name) {
      setError({
        isError: true,
        message: `${type} name does not match`,
      });
      return;
    }

    if (type === 'project') {
      const res = deleteProject({
        projectId: row.id,
        orgId: currentOrg?.id ?? NaN,
      });

      toastPromise({
        promise: res,
        content: `Delete ${type} ${row.name} successfully`,
      });
    }

    if (type === 'dataset') {
      const res = deleteDataset({
        datasetId: row.id,
        orgId: currentOrg?.id ?? NaN,
        projectId: Number(project_id),
      });

      toastPromise({
        promise: res,
        content: `Delete ${type} ${row.name} successfully`,
      });
    }
  };

  return (
    <>
      <IconButton
        label={label}
        onClick={handleOpen}
        icon={<Trash2 width={20} height={20} />}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <div className="flex flex-col gap-6 p-6">
          <h2 className="text-xl font-bold">
            Are you sure you want to delete this {type}?
          </h2>
          <p className="text-[#666]">
            Type "<span className="font-bold">{row.name}</span>" to delete. You
            will not be able to recover it once it deleted.
          </p>
          <div>
            <OutlinedInput fullWidth size="small" onChange={handleOnChange} />
            {error && (
              <p className="w-full pt-2 text-start text-[14px] capitalize text-[#FF816A]">
                {error.message as string}
              </p>
            )}
          </div>

          <div className="flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              tabIndex={3}
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              tabIndex={2}
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
                color: '#FFF',
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmDeleteDialog;
