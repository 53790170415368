import React, { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useGetDataTablesByDatasetId } from '../../hooks';
import { useGetScenariosByModelId } from '../../hooks/useScenariosHook';
import cn from '../../utils/cn';
import { useCurrentOrganization } from '../../utils/helpers';

type DataRow = {
  id: number;
  inputTable: string;
  scenario: string | null;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = () => [
  columnHelper.accessor('inputTable', {
    cell: (info) => <div className="py-2 text-start">{info.getValue()}</div>,
    header: () => (
      <div className="flex items-center gap-2">
        <p>Input table</p>
      </div>
    ),
  }),
  columnHelper.accessor('scenario', {
    id: 'scenario',
    cell: (info) =>
      info.getValue() ? (
        <div>{info.getValue()}</div>
      ) : (
        <div className="text-gray-400">--</div>
      ),
    header: () => <div>Scenario</div>,
  }),
];

const ScenarioInputTable = () => {
  const currentOrg = useCurrentOrganization();
  const { project_id } = useParams();

  const [searchParams] = useSearchParams();

  const modelId = searchParams.get('model_id');
  const datasetId = searchParams.get('dataset_id');

  const { data: scenarios } = useGetScenariosByModelId(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!modelId && !!currentOrg?.id,
    }
  );

  const { data: dataTables } = useGetDataTablesByDatasetId(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId,
      retry: 1,
    }
  );

  const data = useMemo(
    () =>
      scenarios?.map((scenario) => ({
        id: scenario.id,
        inputTable:
          dataTables?.find((dataTable) => dataTable.id === scenario.data_table)
            ?.name ?? 'Unknown',
        scenario: scenario.name,
      })) ?? [],
    [scenarios, dataTables]
  );

  const table = useReactTable({
    data,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border border-[#E4E7EC] bg-[#F8F8F8]"
            >
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={cn(
                    'border border-[#E4E7EC] px-6 py-2 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer px-6 py-2">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border border-[#E4E7EC] px-6 text-[#333]"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScenarioInputTable;
