import React from 'react';

const HelpCircle = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="help-circle">
      <path
        id="Vector"
        d="M8.49992 15.1663C12.1818 15.1663 15.1666 12.1816 15.1666 8.49967C15.1666 4.81778 12.1818 1.83301 8.49992 1.83301C4.81802 1.83301 1.83325 4.81778 1.83325 8.49967C1.83325 12.1816 4.81802 15.1663 8.49992 15.1663Z"
        stroke="#2196F3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector 2"
        d="M6.5 6.5C6.5 4.16665 10.1667 4.16667 10.1667 6.5C10.1667 8.16667 8.5 7.83329 8.5 9.83329"
        stroke="#2196F3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M8.5 12.5067L8.50667 12.4993"
        stroke="#2196F3"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);

export default HelpCircle;
