import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ChevronLeft } from 'lucide-react';

import {
  useApproveSharedInvitation,
  useDisapproveSharedInvitation,
  useGetOrgSharedRequestById,
} from '../hooks/useOrganizationHook';
import routes from '../routes';
import { type ApproveStatus } from '../types';
import cn from '../utils/cn';
import { useCurrentOrganization } from '../utils/helpers';

export type DataRow = {
  id: number;
  email: string;
  name: string;
  status: ApproveStatus;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => {
      const { mutate: approve } = useApproveSharedInvitation();
      const { mutate: disapprove } = useDisapproveSharedInvitation();

      const currentOrg = useCurrentOrganization();

      const id = info.row.original.id;
      const status = info.row.original.status;
      const email = info.row.original.email;

      const handleApprove = () => {
        approve({
          orgId: Number(currentOrg?.id),
          reqId: id,
        });
      };

      const handleDisapprove = () => {
        disapprove({
          orgId: Number(currentOrg?.id),
          reqId: id,
        });
      };

      return (
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col py-2">
            <p className="font-semibold">{info.getValue()}</p>
            <p>{email}</p>
          </div>
          <div>
            {status === 'Disapproved' && (
              <button className="py-2 text-[#B8341B]">Rejected</button>
            )}
            {status === 'Approved' && (
              <button className="py-2 text-[#4782D6]">Approved</button>
            )}
            {status === 'Pending' && (
              <div className="flex gap-3 py-2">
                <button
                  onClick={handleApprove}
                  className="w-[115px] rounded-[4px] bg-[#E1FBE7] py-1.5 text-[#0B9919]"
                >
                  Approve
                </button>
                <button
                  onClick={handleDisapprove}
                  className="w-[115px] rounded-[4px] bg-[#FFF4F2] py-1.5 text-[#B8341B]"
                >
                  Reject
                </button>
              </div>
            )}
          </div>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-1">
        <p>Receiver</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
];

const SharingRequestDetailView = () => {
  const [data, setData] = useState<DataRow[]>([]);

  const { request_id: requestId } = useParams();
  const navigate = useNavigate();

  const currentOrg = useCurrentOrganization();

  const { data: request } = useGetOrgSharedRequestById(
    {
      orgId: Number(currentOrg?.id),
      requestId: Number(requestId),
    },
    {
      enabled: !!currentOrg?.id || !!requestId,
    }
  );

  const handleGoBack = () => {
    navigate(`/${routes.sharedModelVault.index}`);
  };

  useEffect(() => {
    if (request?.sharing_invitations) {
      setData(
        request.sharing_invitations.map((i) => ({
          id: i.id,
          email: i.email,
          name: i.receiver.full_name,
          status: i.approve_status,
        }))
      );
    }
  }, [request]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col gap-6 p-6">
      <button onClick={handleGoBack} className="flex items-center gap-2">
        <ChevronLeft />
        <p className="text-2xl font-bold">{request?.model.name}</p>
      </button>
      <div className="flex flex-col gap-6">
        <h2 className="text-xl font-semibold">Members Approval List</h2>
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={cn(
                      'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                    )}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="px-6 transition">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SharingRequestDetailView;
