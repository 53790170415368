import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useResetPasswordVerify } from '../hooks/useAuthHook';
import routes from '../routes';

function PasswordResetVerify() {
  const { mutate: verifyResetPasswordToken } = useResetPasswordVerify({
    onSuccess: (data) => {
      navigate(routes.resetPassword.setPassword + '?token=' + token, {
        replace: true,
      });
    },
    onError: (error) => {
      navigate(routes.resetPassword.fail, { replace: true });
    },
  });

  // Get the token from the magic link
  const { token } = useParams();
  const navigate = useNavigate();

  // Send token to server to verify

  useEffect(() => {
    if (token) {
      const data = {
        token,
      };

      verifyResetPasswordToken({ data });
    }
  }, [token]);

  return <div />;
}

export default PasswordResetVerify;
