import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import styled from '@emotion/styled';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  Collapse,
  FormControl,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { EllipsisVertical, LockKeyhole } from 'lucide-react';

import { type TypeScenario } from '../../common/dataTypes';
import { useGetDataTablesByDatasetId } from '../../hooks';
import {
  useCloneScenario,
  useDeleteScenarioById,
  useGetScenarioById,
  useGetScenariosByDataTableId,
} from '../../hooks/useScenariosHook';
import cn from '../../utils/cn';
import { arrangeByBase, useCurrentOrganization } from '../../utils/helpers';
import MenuDropdown from '../MenuDropdown';
import CreateScenarioFormDialog from '../scenario/CreateScenarioFormDialog';
import SearchInput from '../SearchInput';
import ConfirmDeleteScenarioDialog from '../SimpleConfirmDeleteDialog';

const Select = styled(MuiSelect)({
  '& .MuiSelect-icon': {
    color: '#E8F2FF',
  },
  '& .MuiSelect-root': {
    backgroundColor: '#01285F !important',
  },
  '& .MuiSelect-select': {
    color: '#E8F2FF',
    backgroundColor: '#01285F !important',
  },
});

const ScenarioTypeSection = ({
  type,
  scenarios,
}: {
  type: 'shared' | 'current' | 'received';
  scenarios: TypeScenario[];
}) => {
  const currentOrg = useCurrentOrganization();

  const [openCollapse, setOpenCollapse] = useState(true);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const [openDialogCreateScenario, setOpenDialogCreateScenario] =
    useState(false);

  const { project_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);

  const scenarioId = searchParams.get('scenario_id');
  const scenarioType = searchParams.get('scenario_type');
  const dataTableId = searchParams.get('dataTable_id');

  const { mutateAsync: deleteScenarioById } = useDeleteScenarioById();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { data: scenario } = useGetScenarioById(
    {
      scenarioId: Number(scenarioId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!scenarioId && !!currentOrg?.id,
    }
  );

  const { mutate: cloneScenario } = useCloneScenario({
    onSuccess(data) {
      searchParams.set('scenario_id', data.id.toString());
      setSearchParams(searchParams);
    },
  });

  useEffect(() => {
    const baseScenario = scenarios.find((m) => m.is_base);

    if (!scenarioId && baseScenario) {
      searchParams.set('scenario_id', baseScenario.id.toString() as string);
      searchParams.set('scenario_type', 'current');

      setSearchParams(searchParams);
    }
  }, [scenarioId, scenarios]);

  const handleOpenDialogCreateScenario = () => {
    setOpenDialogCreateScenario(true);
  };

  const handleCloseDialogCreateScenario = () => {
    setOpenDialogCreateScenario(false);
  };

  const handleToggleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleSelect = (id: number) => {
    newSearchParams.set('scenario_id', id.toString());
    newSearchParams.set('scenario_type', type);

    setSearchParams(newSearchParams);
  };

  const handleDelete = async () => {
    if (!scenarioId) {
      return;
    }

    try {
      const res = await deleteScenarioById({
        orgId: currentOrg?.id ?? NaN,
        projectId: Number(project_id),
        scenarioId: Number(scenarioId),
      });

      if (res.status === 204) {
        handleCloseDropdown();
        handleCloseConfirmDeleteDialog();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloneScenario = (scenarioId: number) => {
    cloneScenario({
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
      scenarioId,
    });
  };

  const handleOpenConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(true);

  const handleCloseConfirmDeleteDialog = () =>
    setOpenConfirmDeleteDialog(false);

  const handleCloseDropdown = () => setAnchorEl(null);

  return (
    <>
      <div className="flex flex-col gap-2">
        <button
          onClick={handleToggleCollapse}
          className="flex items-center gap-1 text-[#E8F2FF]"
        >
          {openCollapse ? <ArrowDropUp /> : <ArrowDropDown />}
          <p className="capitalize">{type}</p>
        </button>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <div className="flex flex-col gap-2">
            {scenarios?.map((s) => (
              <button
                key={s.id}
                onClick={() => handleSelect(s.id)}
                className={cn(
                  'rounded-[4px] text-[#E8F2FF] flex items-center justify-between transition px-3 py-2 text-start',
                  {
                    'bg-[#21438C] font-semibold':
                      Number(scenarioId) === s.id && scenarioType === type,
                  }
                )}
              >
                {type === 'current' && <p>{s.name}</p>}
                {(type === 'shared' || type === 'received') && (
                  <div className="flex gap-2">
                    <LockKeyhole />
                    <div className="text-start">
                      <label>{s.name}</label>
                      <p className="text-sm">From Guest 01</p>
                    </div>
                  </div>
                )}
                {Number(scenarioId) === s.id &&
                  !s.is_base &&
                  scenarioType === type && (
                    <div>
                      <MenuDropdown
                        trigger={
                          <button>
                            <EllipsisVertical width={20} height={20} />
                          </button>
                        }
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        style={{
                          marginTop: '5px',
                          marginLeft: '-5px',
                        }}
                        open={open}
                      >
                        {type === 'current' && (
                          <MenuItem
                            onClick={() => {
                              handleCloseDropdown();
                              handleOpenDialogCreateScenario();
                            }}
                          >
                            Edit
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={() => {
                            handleCloneScenario(s.id);
                          }}
                        >
                          Duplicate
                        </MenuItem>
                        <MenuItem>Archive</MenuItem>
                        <MenuItem onClick={handleOpenConfirmDeleteDialog}>
                          Delete
                        </MenuItem>
                      </MenuDropdown>
                    </div>
                  )}
              </button>
            ))}
          </div>
        </Collapse>
      </div>
      <ConfirmDeleteScenarioDialog
        open={openConfirmDeleteDialog}
        handleOnClose={handleCloseConfirmDeleteDialog}
        handleOnSubmit={handleDelete}
      />
      <CreateScenarioFormDialog
        title="Edit Scenario"
        defaultValues={scenario}
        open={openDialogCreateScenario}
        handleCloseDialog={handleCloseDialogCreateScenario}
        handleOpenDialog={handleOpenDialogCreateScenario}
        showTrigger={false}
      />
    </>
  );
};

const ScenarioDrawerSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const newSearchParams = new URLSearchParams(searchParams);

  const [openDialogCreateScenario, setOpenDialogCreateScenario] =
    useState(false);

  const { project_id } = useParams();

  const datasetId = searchParams.get('dataset_id');
  const dataTableId = searchParams.get('dataTable_id');

  const currentOrg = useCurrentOrganization();

  const { data: scenarios } = useGetScenariosByDataTableId(
    {
      dataTableId: Number(dataTableId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id) ?? NaN,
    },
    {
      enabled: !!dataTableId && !!currentOrg?.id,
    }
  );

  const { data: dataTables } = useGetDataTablesByDatasetId(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId,
    }
  );

  const defaultDataTable =
    dataTables?.find((dataTable) => dataTable.id === Number(dataTableId)) ??
    dataTables?.[0];

  const baseScenario = useMemo(
    () => scenarios?.find((m) => m.is_base),
    [scenarios]
  );

  useEffect(() => {
    defaultDataTable?.id &&
      searchParams.set('dataTable_id', defaultDataTable.id.toString());

    setSearchParams(searchParams);
  }, [defaultDataTable?.id]);

  useEffect(() => {
    if (baseScenario) {
      searchParams.set('scenario_id', baseScenario.id.toString());
      setSearchParams(searchParams);
    }
  }, [baseScenario, dataTableId]);

  const handleSelectDataTable = (dataTableId: number) => {
    newSearchParams.set('dataTable_id', dataTableId.toString());
    if (baseScenario) {
      newSearchParams.set('scenario_id', baseScenario.id.toString());
    }

    setSearchParams(newSearchParams);
  };

  const handleOpenDialogCreateScenario = () => {
    setOpenDialogCreateScenario(true);
  };

  const handleCloseDialogCreateScenario = () => {
    setOpenDialogCreateScenario(false);
  };

  return (
    <div>
      <div className={cn('border-b border-[#6A88BB] px-3 pb-4')}>
        <FormControl fullWidth>
          <label
            htmlFor="dataTableSelector"
            className="min-h-[29px] pb-2 font-semibold text-[#E8F2FF]"
          >
            Input Table
          </label>
          <Select
            id="dataTableSelector"
            size="small"
            value={Number(defaultDataTable?.id)}
            className="bottom-0 border border-[#6A88BB] bg-[#01285F]"
          >
            {dataTables?.map((d) => (
              <MenuItem
                key={d.id}
                value={d.id}
                onClick={() => handleSelectDataTable(d.id)}
              >
                <span className="capitalize ">{d.name}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex flex-col gap-3 px-3 py-6">
        <div className="flex items-center justify-between">
          <span className="w-full text-lg font-semibold text-[#E8F2FF]">
            Scenario
          </span>
          <CreateScenarioFormDialog
            title="Create New Scenario"
            open={openDialogCreateScenario}
            handleCloseDialog={handleCloseDialogCreateScenario}
            handleOpenDialog={handleOpenDialogCreateScenario}
          />
        </div>
        <SearchInput className="border-none bg-[#21438C] text-[#E8F2FF] placeholder-[#E8F2FF]" />
        {scenarios && (
          <ScenarioTypeSection
            type="current"
            scenarios={
              arrangeByBase(
                scenarios.filter((s) => !s.sharing)
              ) as TypeScenario[]
            }
          />
        )}
        {scenarios && (
          <div className="border-t border-[#6A88BB] pt-4">
            <ScenarioTypeSection
              type="shared"
              scenarios={scenarios.filter((s) => s.is_shared)}
            />
          </div>
        )}
        {scenarios && (
          <div className="border-t border-[#6A88BB] pt-4">
            <ScenarioTypeSection
              type="received"
              scenarios={scenarios.filter((s) => s.is_received)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ScenarioDrawerSection;
