import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ArrowUpward } from '@mui/icons-material';
import {
  Avatar,
  MenuItem,
  Select as MuiSelect,
  type SelectChangeEvent,
  styled,
} from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import _ from 'lodash';
import { ChevronLeft } from 'lucide-react';

import { type TypeProject } from '../common/dataTypes';
import {
  useGetOrgInvitationById,
  useGetOrgMemberById,
  useUpdateOrgInvitation,
} from '../hooks/useOrganizationHook';
import { useUpdateProjectMember } from '../hooks/useProjectsHook';
import { useGetUser } from '../hooks/useUserHook';
import routes from '../routes';
import { type Role } from '../types';
import cn from '../utils/cn';
import { useCurrentOrganization } from '../utils/helpers';

import ConfirmDeleteProjectDialog from './userManagement/ConfirmDeleteProjectDialog';

export type DataRow = {
  id: number;
  name: string;
  lastActive: string | null;
  role: 'project_member' | 'project_owner' | 'Unknown';
};

export type User = {
  id: number;
  userId?: number;
  role: Role;
  first_name?: string;
  last_name?: string;
  profile_pic?: string;
  updated_at: string;
  email: string;
  projects: TypeProject[];
};

const columnHelper = createColumnHelper<DataRow>();

const Select = styled(MuiSelect)({
  padding: '0px !important',
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
    padding: '0px !important',
  },

  '& .MuiSelect-select': {
    backgroundColor: '#F8F8F8',
    paddingLeft: '10px !important',
    paddingRight: '60px !important',
  },
  '& .MuiSelect-nativeInput': {},
});

const columns = (user: User) => [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p className="w-full">{info.getValue()}</p>
      </div>
    ),
    header: () => (
      <div className="items-centert flex gap-2">
        <p>Project</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('lastActive', {
    id: 'lastActive',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'Y-MM-dd h:mm aaa')}
        </div>
      ) : (
        <div className="w-full text-start text-gray-400">--</div>
      ),
    header: () => <div>Last active</div>,
  }),
  columnHelper.accessor('role', {
    id: 'role',
    cell: (info) => {
      const currentRole = info.getValue();
      const project = info.row.original;

      const params = useParams();
      const userId = params.user_id;

      const [role, setRole] = useState<Role>(currentRole);
      const { data: currentUser } = useGetUser();

      const currentOrg = useCurrentOrganization();

      const { data: member } = useGetOrgMemberById(
        {
          id: currentOrg?.id ?? NaN,
          memberId: Number(userId) ?? NaN,
        },
        {
          enabled: !!userId && !!currentOrg?.id,
        }
      );

      const { data: invitation } = useGetOrgInvitationById(
        {
          id: currentOrg?.id ?? NaN,
          invitationId: Number(userId),
        },
        {
          enabled: !!userId && !!currentOrg?.id,
        }
      );

      const projectMember = member?.project_memberships.find(
        (p) => p.project.id === project.id
      );

      useEffect(() => {
        if (currentRole) {
          setRole(currentRole);
        }
      }, [currentRole]);

      const { mutate: updateProjectMember } = useUpdateProjectMember();

      const { mutate: updateOrgInvitation } = useUpdateOrgInvitation();

      const roles = [
        {
          label: 'Project Member',
          value: 'project_member',
        },
        {
          label: 'Project Owner',
          value: 'project_owner',
        },
      ];

      if (!info.getValue()) {
        return <div className="w-full text-start text-gray-400">--</div>;
      }

      const handleChangeRole = (event: SelectChangeEvent<unknown>) => {
        if (!currentOrg) {
          return;
        }

        const selectedRole = event.target.value as Role;

        if (projectMember) {
          updateProjectMember({
            orgId: currentOrg.id,
            projectId: project.id,
            memberId: projectMember.id,
            data: {
              role: selectedRole,
            },
          });
        } else if (invitation) {
          updateOrgInvitation({
            id: currentOrg.id,
            invitationId: invitation.id,
            data: {
              role: selectedRole,
            },
          });
        }

        setRole(selectedRole);
      };

      return (
        <div className="flex w-full justify-between capitalize">
          <Select
            id="role"
            size="small"
            value={role}
            disabled={currentUser?.id === user?.userId}
            onChange={handleChangeRole}
            className="bottom-0"
            placeholder="Please select"
          >
            {roles.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <span className="capitalize">{option.label}</span>
              </MenuItem>
            ))}
          </Select>
          <ConfirmDeleteProjectDialog project={project} />
        </div>
      );
    },
    header: () => <div>Project roles</div>,
  }),
];

const UserAccountDetails = () => {
  const [data, setData] = useState<DataRow[]>([]);
  const navigate = useNavigate();

  const organization = useCurrentOrganization();
  const params = useParams();

  const [searchParams] = useSearchParams();

  const type = searchParams.get('type');

  const userId = params.user_id;

  const { data: member } = useGetOrgMemberById(
    {
      id: organization?.id ?? NaN,
      memberId: Number(userId) ?? NaN,
    },
    {
      enabled: !!userId && !!organization?.id && type === 'member',
    }
  );

  const { data: invitation } = useGetOrgInvitationById(
    {
      id: organization?.id ?? NaN,
      invitationId: Number(userId),
    },
    {
      enabled: !!userId && !!organization?.id && type === 'invitation',
    }
  );

  const user = useMemo(
    () =>
      member &&
      ({
        ..._.omit(member?.user, ['id', 'role', 'projects']),
        id: member?.id,
        userId: member?.user.id,
        role: member?.role,
        projects: member?.project_memberships.map((p) => p.project),
        updated_at: member?.updated_at,
      } as User),
    [member]
  );

  useEffect(() => {
    if (user) {
      setData(
        user.projects.map((project) => ({
          id: project.id,
          name: project.name,
          lastActive: project.updated_at,
          role: project.role,
        }))
      );
    } else if (invitation) {
      setData(
        invitation.projects.map((project) => ({
          id: project.id,
          name: project.name,
          lastActive: project.updated_at,
          role: invitation.role as 'project_member' | 'project_owner',
        }))
      );
    }
  }, [user, invitation]);

  const table = useReactTable({
    data,
    columns: columns(user ?? (member as unknown as User)),
    getCoreRowModel: getCoreRowModel(),
  });

  const handleGoBack = () => {
    navigate(routes.userManagement.index);
  };

  return (
    <div className="bp-[#F5F9FF] flex flex-col gap-6 p-6">
      <button onClick={handleGoBack} className="flex items-center gap-2">
        <ChevronLeft />
        <p className="text-[28px] font-bold">
          {type === 'invitation'
            ? invitation?.email
            : `${user?.first_name} ${user?.last_name}`}
        </p>
      </button>
      <div className="flex gap-6">
        <div className="basis-[330px] space-y-3 bg-white pt-2">
          {user?.updated_at && (
            <p className="text-sm font-semibold text-[#666]">
              Last active on{' '}
              {format(user?.updated_at ?? ('' as string), 'Y-MM-dd h:mm aaa')}
            </p>
          )}
          <div className="flex flex-col gap-3 rounded-[4px] border border-[#DBE7F6] p-6">
            <Avatar
              src={user?.profile_pic}
              sx={{
                width: 80,
                height: 80,
                marginBottom: 2,
              }}
              alt="member avatar"
            />
            <div className="flex flex-col">
              <p className="text-sm font-light text-[#999999]">Full name</p>
              <p className="font-semibold">
                {user?.first_name} {user?.last_name}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-sm font-light text-[#999999]">Email address</p>
              <p className="font-semibold">
                {type === 'member' ? user?.email : invitation?.email}
              </p>
            </div>
          </div>
        </div>
        <div className="flex grow flex-col gap-3">
          <p className="text-xl font-semibold">Project access</p>
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!rounded-md border border-[#E4E7EC] bg-[#F8F8F8]"
                >
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={cn(
                        'border-t border-b border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="cursor-pointer py-3 transition">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l first:pl-6 last:border-r last:pr-6"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div />
      </div>
    </div>
  );
};

export default UserAccountDetails;
