import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import routes from '../routes';
import { useCurrentOrganization, useCurrentProject } from '../utils/helpers';

const AuthorizedRoute = () => {
  const location = useLocation();

  const currentOrg = useCurrentOrganization();
  const currentProject = useCurrentProject();

  if (
    currentOrg &&
    currentOrg.role === 'organization_admin' &&
    currentProject &&
    !currentProject.role
  ) {
    return (
      <Navigate to={routes.projects.index} state={{ from: location }} replace />
    );
  }

  return <Outlet />;
};

export default AuthorizedRoute;
