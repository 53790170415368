import React, {
  type CSSProperties,
  type Dispatch,
  type ReactNode,
} from 'react';

import { Menu as MuiMenu, type PopoverOrigin, styled } from '@mui/material';

import cn from '../utils/cn';

type MenuProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<React.SetStateAction<HTMLElement | null>>;
  trigger: ReactNode;
  triggerClassName?: React.HTMLAttributes<HTMLDivElement>['className'];
  children: ReactNode;
  transformOrigin?: PopoverOrigin;
  style?: CSSProperties;
};

const Menu = styled(MuiMenu)({
  '& .MuiList-root': {
    padding: 0,
  },
});

const MenuDropdown = ({
  open,
  anchorEl,
  setAnchorEl,
  trigger,
  triggerClassName,
  children,
  ...props
}: MenuProps) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        onClick={handleClick}
        className={cn('flex items-center', triggerClassName)}
      >
        {trigger}
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
          ...props.transformOrigin,
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{
          marginTop: '5px',
          ...props.style,
        }}
      >
        {children}
      </Menu>
    </div>
  );
};

export default MenuDropdown;
