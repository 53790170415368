import { CircleAlert, CircleCheck } from 'lucide-react';
import { toast } from 'sonner';

import { type Aircraft } from '../components/dataset/Aircraft/AircraftTable';

import { type TypeDataset } from './dataTypes';

export function formatDate(dateString: string) {
  return new Date(dateString).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

export const formatDatasetConfig = (datasetId: number, data: TypeDataset) => {
  const { id, name, description, configuration } = data;

  const datasetData = {
    id,
    datasetId,
    name,
    description,
    granularity: configuration.granularity,
    startDate: configuration.start_period,
    endDate: configuration.end_period,
    startPeriodYear: configuration.start_period,
    startPeriodMonth: '',
    startPeriodWeek: '',
    positions: configuration.positions.reduce(
      (
        acc: string[],
        pos: {
          name: string;
        }
      ) => {
        acc.push(pos.name.trim());
        return acc;
      },
      [] as string[]
    ),
    aircrafts: configuration.aircrafts.reduce(
      (
        acc: Aircraft[],
        aircraft: { name: string; airports: string[] },
        index
      ) => {
        acc.push({
          aircraft: aircraft.name,
          airports: aircraft.airports,
          hierarchy: index + 1,
        });
        return acc;
      },
      [] as Aircraft[]
    ),
    retirementAge: configuration.retirement_age.toString(),
    yearsOfService: configuration.years_of_service.toString(),
    duration: configuration.duration.toString(),
    laborGroup: configuration.labor_group,
  };

  return datasetData;
};

export const toastPromise = ({
  promise,
  content,
}: {
  promise: Promise<any>;
  content: string;
}) =>
  toast.promise(promise, {
    success: (data) => (
      <div className="flex w-full gap-2 rounded-[4px] bg-[#2E7D32] p-3 text-white">
        <div className="flex items-center">
          <CircleCheck />
        </div>
        <div className="flex flex-col">
          <span className="text-lg font-bold">Success</span>
          <p className="text-sm font-light">{content}</p>
        </div>
      </div>
    ),
    error: (data) => (
      <div className="flex w-full gap-2 rounded-[4px] bg-[#FF816A] p-3 text-white">
        <div className="flex items-center">
          <CircleAlert />
        </div>
        <div className="flex flex-col">
          <span className="text-lg font-bold">Error</span>
          <p className="text-sm font-light">{data.message}</p>
        </div>
      </div>
    ),
    unstyled: true,
  });
