import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Collapse, MenuItem, Tooltip } from '@mui/material';
import { EllipsisVertical, GitCompareArrows, LockKeyhole } from 'lucide-react';

import { type TypeModel } from '../../common/dataTypes';
import {
  useDeleteModel,
  useGetDatasetById,
  useGetDataTablesByDatasetId,
  useGetModelById,
  useGetModelsByDatasetId,
} from '../../hooks';
import { useCloneModel } from '../../hooks/useModelsHook';
import { useGetScenariosByModelId } from '../../hooks/useScenariosHook';
import cn from '../../utils/cn';
import { arrangeByBase, useCurrentOrganization } from '../../utils/helpers';
import MenuDropdown from '../MenuDropdown';
import CreateModelFormDialog from '../model/CreateModelFormDialog';
import SearchInput from '../SearchInput';
import ConfirmDeleteScenarioDialog from '../SimpleConfirmDeleteDialog';

const ModelTypeSection = ({
  models,
  type = 'current',
}: {
  models: TypeModel[];
  type: 'shared' | 'current' | 'received';
}) => {
  const [openDialogCreateDataTable, setOpenDialogCreateDataTable] =
    useState(false);

  const currentOrg = useCurrentOrganization();

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const { project_id } = useParams();

  const modelId = searchParams.get('model_id');
  const datasetId = searchParams.get('dataset_id');
  const modelMode = searchParams.get('mode');

  const [openCollapse, setOpenCollapse] = useState(true);

  const { data: model } = useGetModelById(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!modelId && !!currentOrg?.id,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!currentOrg?.id && !!project_id,
    }
  );

  const { data: scenarios } = useGetScenariosByModelId(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!modelId && !!currentOrg?.id,
    }
  );

  const { data: dataTables } = useGetDataTablesByDatasetId(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId,
    }
  );

  const { mutate: cloneModel } = useCloneModel({
    onSuccess(data) {
      handleCloseDropdown();

      searchParams.set('model_id', data.id.toString());
      setSearchParams(searchParams);
    },
  });

  useEffect(() => {
    const baseModel = models.find((m) => m.is_base);

    if (!modelId && baseModel && !modelMode) {
      newSearchParams.set('model_id', baseModel.id.toString() as string);

      setSearchParams(newSearchParams);
    }
  }, [modelId, models, modelMode]);

  const handleOpenDialogCreateDataTable = () => {
    setOpenDialogCreateDataTable(true);
  };

  const handleCloseDialogCreateDataTable = () => {
    setOpenDialogCreateDataTable(false);
  };

  const handleSelect = (modelId: number) => {
    newSearchParams.set('model_id', modelId.toString());
    newSearchParams.delete('mode');

    setSearchParams(newSearchParams);
  };

  const { mutateAsync: deleteModel } = useDeleteModel({
    onSuccess: () => {
      handleCloseDropdown();
      handleCloseConfirmDeleteDialog();
    },
  });

  const handleCloseDropdown = () => setAnchorEl(null);

  const handleOpenConfirmDeleteDialog = () => setOpenConfirmDeleteDialog(true);

  const handleCloseConfirmDeleteDialog = () =>
    setOpenConfirmDeleteDialog(false);

  const handleDelete = async () => {
    if (!modelId) {
      return;
    }

    deleteModel({
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
      modelId: Number(modelId),
    });
  };

  const handleCloneModel = (modelId: number) => {
    cloneModel({
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
      modelId,
    });
  };

  const handleToggleCurrentModel = () => {
    setOpenCollapse(!openCollapse);
  };

  const defaultValues =
    model && dataset && scenarios
      ? {
          name: model.name,
          description: model.description,
          dataset: dataset.name,
          scenarios: scenarios.reduce(
            (acc, curr) => {
              const table =
                dataTables?.find(
                  (dataTable) => dataTable.id === curr.data_table
                )?.name ?? 'Unknown';

              acc[table] = curr.id.toString();
              return acc;
            },
            {} as Record<string, string>
          ),
        }
      : undefined;

  return (
    <>
      <div className="flex flex-col gap-2">
        <button
          onClick={handleToggleCurrentModel}
          className="flex items-center gap-1 text-[#E8F2FF]"
        >
          {openCollapse ? <ArrowDropUp /> : <ArrowDropDown />}
          <p className="capitalize">{type}</p>
        </button>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <div className="py-1">
            {models.map((model) => (
              <button
                onClick={() => handleSelect(model.id)}
                className={cn(
                  'flex text-[#E8F2FF] items-center transition w-full rounded-[4px] px-3 py-2 justify-between',
                  {
                    'bg-[#21438C] font-semibold': model.id === Number(modelId),
                  }
                )}
              >
                {!model.sharing && <p>{model.name}</p>}

                {model.sharing && model.is_shared && (
                  <div className="flex gap-2">
                    <LockKeyhole />
                    <p className="w-[160px] text-start">
                      <label>{model.name}</label>
                      <p className="w-full overflow-hidden text-ellipsis text-sm">
                        To {model.sharing.shared_with}
                      </p>
                    </p>
                  </div>
                )}

                {model.sharing && model.is_received && (
                  <div className="flex gap-2">
                    <LockKeyhole />
                    <div className="text-start">
                      <label>{model.name}</label>
                      <p className="text-sm">
                        From {model.sharing.shared_by?.full_name}
                      </p>
                    </div>
                  </div>
                )}

                {Number(modelId) === model.id && !model.is_base && (
                  <div>
                    <MenuDropdown
                      trigger={
                        <button>
                          <EllipsisVertical width={20} height={20} />
                        </button>
                      }
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      style={{
                        marginTop: '5px',
                        marginLeft: '-5px',
                      }}
                      open={open}
                    >
                      {type === 'current' && (
                        <MenuItem
                          onClick={() => {
                            handleCloseDropdown();
                            handleOpenDialogCreateDataTable();
                          }}
                        >
                          Edit
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          handleCloneModel(model.id);
                        }}
                      >
                        Duplicate
                      </MenuItem>
                      <MenuItem>Archive</MenuItem>
                      <MenuItem onClick={handleOpenConfirmDeleteDialog}>
                        Delete
                      </MenuItem>
                    </MenuDropdown>
                  </div>
                )}
              </button>
            ))}
          </div>
        </Collapse>
      </div>
      <ConfirmDeleteScenarioDialog
        open={openConfirmDeleteDialog}
        handleOnClose={handleCloseConfirmDeleteDialog}
        handleOnSubmit={handleDelete}
      />
      <CreateModelFormDialog
        title="Edit Model"
        edit={
          defaultValues && {
            defaultValues,
            type: 'model',
          }
        }
        showTrigger={false}
        open={openDialogCreateDataTable}
        handleOpenDialog={handleOpenDialogCreateDataTable}
        handleCloseDialog={handleCloseDialogCreateDataTable}
      />
    </>
  );
};

const ModelDrawerSection = () => {
  const [openDialogCreateDataTable, setOpenDialogCreateDataTable] =
    useState(false);

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const datasetId = searchParams.get('dataset_id');
  const projectId = Number(params.project_id);

  const currentOrg = useCurrentOrganization();

  const handleOpenDialogCreateDataTable = () => {
    setOpenDialogCreateDataTable(true);
  };

  const handleCloseDialogCreateDataTable = () => {
    setOpenDialogCreateDataTable(false);
  };

  const handleSwitchToComparison = () => {
    searchParams.set('mode', 'compare');
    searchParams.delete('model_id');

    setSearchParams(searchParams);
  };

  const { data: models } = useGetModelsByDatasetId(
    {
      projectId,
      datasetId: Number(datasetId),
      orgId: currentOrg?.id ?? NaN,
    },
    {
      enabled: !!datasetId,
    }
  );

  return (
    <div className="flex flex-col gap-4 border-t border-[#6A88BB] px-3 py-6">
      <div className="flex items-center justify-between">
        <span className="text-lg font-semibold text-[#E8F2FF]">Model</span>
        <div className="flex items-center gap-2">
          <Tooltip title="Compare Model" placement="top" arrow>
            <GitCompareArrows
              size={20}
              color="white"
              className="cursor-pointer"
              onClick={handleSwitchToComparison}
            />
          </Tooltip>
          <CreateModelFormDialog
            title="Create New Model"
            open={openDialogCreateDataTable}
            handleOpenDialog={handleOpenDialogCreateDataTable}
            handleCloseDialog={handleCloseDialogCreateDataTable}
          />
        </div>
      </div>
      <SearchInput className="border-none bg-[#21438C] text-[#E8F2FF] placeholder-[#E8F2FF]" />
      <ModelTypeSection
        models={
          (arrangeByBase(models?.filter((m) => !m.sharing)) as TypeModel[]) ??
          []
        }
        type="current"
      />
      <div className="border-t border-[#6A88BB] pt-4">
        <ModelTypeSection
          type="shared"
          models={
            (models
              ?.filter((m) => m.is_shared)
              .flatMap((m) =>
                m.sharing?.shared_with.map((u) => ({
                  ...m,
                  shared_with: u,
                }))
              ) as unknown as TypeModel[]) ?? []
          }
        />
      </div>
      <div className="border-t border-[#6A88BB] pt-4">
        <ModelTypeSection
          type="received"
          models={(models?.filter((m) => m.is_received) as TypeModel[]) ?? []}
        />
      </div>
    </div>
  );
};

export default ModelDrawerSection;
