import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import { ChevronRight } from 'lucide-react';

import DatasetsView from '../components/dataset/DatasetsView';
import ProjectViewDataset from '../components/dataset/ProjectViewDataset';
import ProjectViewExpense from '../components/expense/ProjectViewExpense';
import { useGetDatasetById, useGetProjectById } from '../hooks';
import { useCurrentOrganization } from '../utils/helpers';

import ProjectViewModel from './ProjectViewModel';
import ProjectViewScenario from './ProjectViewScenario';

const TAB_DATASET = 'dataset';
const TAB_SCENARIO = 'scenario';
const TAB_MODEL = 'model';
const TAB_EXPENSE = 'expense';

const BreadCrumbs = () => {
  const currentOrg = useCurrentOrganization();

  const { project_id, org_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const datasetId = searchParams.get('dataset_id');
  const modelMode = searchParams.get('mode');
  const section = searchParams.get('section');
  const modelId = searchParams.get('model_id');
  const expenseId = searchParams.get('expense_id');
  const expenseSetId = searchParams.get('expense_set_id');

  const { data: project } = useGetProjectById(
    {
      projectId: Number(project_id) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
    },
    {
      enabled: !!project_id && !!currentOrg?.id,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!currentOrg?.id,
    }
  );

  return (
    <div className="flex">
      {project && (
        <div className="flex items-center gap-2 text-[#666666]">
          <Link
            to={`/${org_id}/projects`}
            className="transition hover:text-black"
          >
            Projects
          </Link>
          <ChevronRight size={16} color="#666666" />
          <Link
            to={`/${org_id}/projects/${project.id}`}
            className="transition hover:text-black"
          >
            {project.name}
          </Link>
        </div>
      )}
      {dataset && (
        <div className="flex items-center gap-2 pl-2 text-[#666666]">
          <ChevronRight size={16} color="#666666" />
          <p
            onClick={() => {
              searchParams.set('section', 'dataset');
              searchParams.delete('mode');
              searchParams.delete('model_id');
              searchParams.delete('mode');
              searchParams.delete('scenario_id');
              searchParams.delete('expense_id');
              searchParams.delete('expense_set_id');
              setSearchParams(searchParams);
            }}
            className="cursor-pointer transition hover:text-black"
          >
            {dataset.name}
          </p>
        </div>
      )}

      {modelId && section === 'model' && (
        <div className="flex items-center gap-2 pl-2 text-[#666666]">
          <ChevronRight size={16} color="#666666" />
          <p
            onClick={() => {
              searchParams.set('section', 'model');
              searchParams.delete('mode');
              setSearchParams(searchParams);
            }}
            className="cursor-pointer transition hover:text-black"
          >
            Model
          </p>
        </div>
      )}

      {modelId && section === 'scenario' && (
        <div className="flex items-center gap-2 pl-2 text-[#666666]">
          <ChevronRight size={16} color="#666666" />
          <p
            onClick={() => {
              searchParams.set('section', 'scenario');
              searchParams.delete('mode');
              setSearchParams(searchParams);
            }}
            className="cursor-pointer transition hover:text-black"
          >
            Scenario
          </p>
        </div>
      )}

      {expenseSetId && section === 'expense' && (
        <>
          <div className="flex items-center gap-2 pl-2 text-[#666666]">
            <ChevronRight size={16} color="#666666" />
            <p
              onClick={() => {
                searchParams.set('section', 'expense');
                searchParams.delete('mode');
                searchParams.delete('expense_id');
                setSearchParams(searchParams);
              }}
              className="cursor-pointer transition hover:text-black"
            >
              Expense
            </p>
          </div>
          <div className="flex items-center gap-2 pl-2 text-[#666666]">
            <ChevronRight size={16} color="#666666" />
            <p
              onClick={() => {
                searchParams.set('section', 'expense');
                searchParams.delete('mode');
                searchParams.delete('expense_id');
                setSearchParams(searchParams);
              }}
              className="cursor-pointer transition hover:text-black"
            >
              Default
            </p>
          </div>
        </>
      )}

      {modelMode === 'compare' && (
        <div className="flex items-center gap-2 pl-2 text-[#666666]">
          <ChevronRight size={16} color="#666666" />
          <p className="cursor-pointer transition hover:text-black">
            Comparison
          </p>
        </div>
      )}
    </div>
  );
};

function ProjectView() {
  const { project_id } = useParams();

  const currentOrg = useCurrentOrganization();

  const [searchParams] = useSearchParams();
  const qTab = searchParams.get('section');
  const datasetId = searchParams.get('dataset_id');

  const { data: project, isSuccess } = useGetProjectById(
    {
      projectId: Number(project_id) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
    },
    {
      enabled: !!project_id,
    }
  );

  const [selectedTab, setSelectedTab] = React.useState(TAB_DATASET);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    document.title = `${project.name} | ISBE Group`;

    if (
      qTab &&
      [TAB_DATASET, TAB_MODEL, TAB_SCENARIO, TAB_EXPENSE].includes(qTab)
    ) {
      setSelectedTab(qTab);
    } else {
      setSelectedTab(TAB_DATASET);
    }
  }, [isSuccess, qTab]);

  if (!datasetId) {
    return (
      <div className="flex flex-col">
        <div className="px-12 pt-6">
          <BreadCrumbs />
        </div>
        <DatasetsView />
      </div>
    );
  }

  return (
    <div>
      <Box>
        <div className=" bg-white pb-12">
          <div className="px-12 py-5">
            <BreadCrumbs />
          </div>
          {selectedTab === TAB_MODEL && <ProjectViewModel />}
          <div className="px-12">
            {selectedTab === TAB_DATASET && <ProjectViewDataset />}
            {selectedTab === TAB_SCENARIO && <ProjectViewScenario />}
            {selectedTab === TAB_EXPENSE && <ProjectViewExpense />}
          </div>
        </div>
      </Box>
    </div>
  );
}

export default ProjectView;
