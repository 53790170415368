import React from 'react';

import { Button, Dialog } from '@mui/material';

type ConfirmDiscardsDialogProps = {
  open: boolean;
  handleOnClose: () => void;
  handleOnSubmit: () => void;
};

const ConfirmGenerateIFSDialog = ({
  open,
  handleOnClose,
  handleOnSubmit,
}: ConfirmDiscardsDialogProps) => (
  <Dialog
    open={open}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <div className="flex flex-col gap-6 p-6">
      <h2 className="text-xl font-bold">Are you sure you want to generate?</h2>
      <div className="flex justify-end gap-3">
        <Button
          onClick={handleOnClose}
          variant="outlined"
          tabIndex={3}
          style={{
            color: '#666',
            borderColor: '#B3B3B3',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOnSubmit}
          tabIndex={2}
          variant="contained"
          style={{
            backgroundColor: '#2196F3',
            color: '#FFF',
          }}
        >
          Yes
        </Button>
      </div>
    </div>
  </Dialog>
);

export default ConfirmGenerateIFSDialog;
