import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import { type CalculateEndPeriod, calculateEndPeriod } from '../api/utils';

import { type DefaultMutationError } from './index';

const calculateEndPeriodFn = async (input: CalculateEndPeriod) => {
  const { data }: AxiosResponse<any> = await calculateEndPeriod(input);

  return data;
};

export const useCalculateEndPeriod = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof calculateEndPeriodFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['calculateEndPeriod'],
    mutationFn: (input: CalculateEndPeriod) => calculateEndPeriodFn(input),
    ...opts,
  });
