import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Collapse, MenuItem } from '@mui/material';
import { EllipsisVertical } from 'lucide-react';

import { type TypeExpenseSet } from '../../common/dataTypes';
import {
  useGetExpenseSetById,
  useGetExpenseSets,
  useGetPreDefinedExpenses,
} from '../../hooks/useExpenseHook';
import cn from '../../utils/cn';
import MenuDropdown from '../MenuDropdown';

type ExpenseCollapseProps = {
  title: string;
  items: {
    label: string;
    value: string;
  }[];
};

const ExpenseCollapse = ({ title, items }: ExpenseCollapseProps) => {
  const [open, setOpen] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const { org_id, project_id } = useParams();

  const expenseSetId = searchParams.get('expense_set_id');
  const expenseId = searchParams.get('expense_id');

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleSelect = (id: number) => {
    searchParams.set('expense_id', id.toString());
    searchParams.delete('mode');

    setSearchParams(searchParams);
  };

  const { data: expenseSet } = useGetExpenseSetById(
    {
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id) ?? NaN,
      expenseSetId: Number(expenseSetId) ?? NaN,
    },
    {
      enabled: !!org_id && !!project_id && !!expenseSetId,
    }
  );

  const preDefinedExpenses = expenseSet?.predefined_expenses;

  return (
    <div className="text-[#E8F2FF]">
      <button
        type="button"
        onClick={handleToggle}
        className="flex items-center gap-1"
      >
        {open ? <ArrowDropUp /> : <ArrowDropDown />}
        <label className="capitalize">{title}</label>
      </button>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="mb-4 ml-[11px] mt-2 flex flex-col border-l border-gray-300 pl-4">
          {preDefinedExpenses &&
            items
              .filter((i) => preDefinedExpenses.includes(Number(i.value)))
              .map((e: any) => (
                <div key={e.value} className="flex justify-between">
                  <div
                    className={cn(
                      'flex transition w-full items-center gap-1 rounded-[4px]',
                      {
                        'bg-[#21438C]': Number(e.value) === Number(expenseId),
                      }
                    )}
                  >
                    <button
                      className="w-full p-2 text-start"
                      onClick={() => handleSelect(e.value)}
                    >
                      {e.label}
                    </button>
                  </div>
                </div>
              ))}
        </div>
      </Collapse>
    </div>
  );
};

const ExpensesSectionCollapse = () => {
  const [searchParams] = useSearchParams();

  const { org_id, project_id } = useParams();

  const datasetId = searchParams.get('dataset_id');

  const { data: predefinedExpenseSets } = useGetPreDefinedExpenses(
    {
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id) ?? NaN,
      datasetId: Number(datasetId) ?? NaN,
    },
    {
      enabled: !!org_id && !!project_id && !!datasetId,
    }
  );

  const SalaryExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 1)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const BenefitsExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 2)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const TaxesExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 3)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const TravelExpenses = predefinedExpenseSets
    ?.filter((e) => e.group.id === 4)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const Training = predefinedExpenseSets
    ?.filter((e) => e.group.id === 5)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const RetirementBenefits = predefinedExpenseSets
    ?.filter((e) => e.group.id === 6)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  const Operations = predefinedExpenseSets
    ?.filter((e) => e.group.id === 7)
    .map((e) => ({
      label: e.name,
      value: e.id.toString(),
    }));

  return (
    <div>
      <div className="py-2">
        {SalaryExpenses && (
          <ExpenseCollapse title="Salary" items={SalaryExpenses} />
        )}
        {BenefitsExpenses && (
          <ExpenseCollapse title="Benefits" items={BenefitsExpenses} />
        )}
        {TaxesExpenses && (
          <ExpenseCollapse title="Taxes" items={TaxesExpenses} />
        )}
        {TravelExpenses && (
          <ExpenseCollapse title="Travel Expenses" items={TravelExpenses} />
        )}
        {Training && <ExpenseCollapse title="Training" items={Training} />}
        {RetirementBenefits && (
          <ExpenseCollapse
            title="Retirement Benefits"
            items={RetirementBenefits}
          />
        )}
        {Operations && (
          <ExpenseCollapse title="Operations" items={Operations} />
        )}
      </div>
    </div>
  );
};

const ExpenseTypeSection = ({
  expenses,
  type = 'current',
}: {
  expenses: TypeExpenseSet[];
  type: 'shared' | 'current' | 'received';
}) => {
  const [openCollapse, setOpenCollapse] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const expenseSetId = searchParams.get('expense_set_id');

  const handleToggleCurrentModel = () => {
    setOpenCollapse(!openCollapse);
  };

  const handleSelect = (modelId: number) => {
    searchParams.set('expense_set_id', modelId.toString());
    searchParams.delete('mode');

    setSearchParams(searchParams);
  };

  return (
    <>
      <div className="flex flex-col gap-2">
        <button
          onClick={handleToggleCurrentModel}
          className="flex items-center gap-1 text-[#E8F2FF]"
        >
          {openCollapse ? <ArrowDropUp /> : <ArrowDropDown />}
          <p className="capitalize">{type}</p>
        </button>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <>
            <div className="py-1">
              {expenses.map((e) => (
                <button
                  onClick={() => handleSelect(e.id)}
                  className={cn(
                    'flex text-[#E8F2FF] items-center transition w-full rounded-[4px] px-3 py-2 justify-between',
                    {
                      'bg-[#21438C] font-semibold':
                        e.id === Number(expenseSetId),
                    }
                  )}
                >
                  {<p>{e.name}</p>}

                  {Number(expenseSetId) === e.id && (
                    <div>
                      <MenuDropdown
                        trigger={
                          <button>
                            <EllipsisVertical width={20} height={20} />
                          </button>
                        }
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        style={{
                          marginTop: '5px',
                          marginLeft: '-5px',
                        }}
                        open={open}
                      >
                        {type === 'current' && (
                          <MenuItem onClick={() => {}}>Edit</MenuItem>
                        )}
                        <MenuItem onClick={() => {}}>Duplicate</MenuItem>
                        <MenuItem>Archive</MenuItem>
                        <MenuItem onClick={() => {}}>Delete</MenuItem>
                      </MenuDropdown>
                    </div>
                  )}
                </button>
              ))}
            </div>
            <ExpensesSectionCollapse />
          </>
        </Collapse>
      </div>
    </>
  );
};

const ExpenseDrawerSection = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { org_id, project_id } = useParams();

  const datasetId = searchParams.get('dataset_id');
  const expenseSetId = searchParams.get('expense_set_id');

  const { data: expenses } = useGetExpenseSets(
    {
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id) ?? NaN,
      datasetId: Number(datasetId) ?? NaN,
    },
    {
      enabled: !!org_id && !!project_id && !!datasetId,
    }
  );

  useEffect(() => {
    if (expenses?.[0] && !expenseSetId) {
      searchParams.set('expense_set_id', expenses[0].id.toString());
      setSearchParams(searchParams);
    }
  }, [expenseSetId, expenses]);

  return (
    <div className="flex flex-col gap-4 border-t border-[#6A88BB] px-3 py-6">
      {expenses && <ExpenseTypeSection expenses={expenses} type="current" />}
    </div>
  );
};

export default ExpenseDrawerSection;
