import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Dialog } from '@mui/material';
import { Trash2 } from 'lucide-react';

import { useGetOrgMemberById } from '../../hooks/useOrganizationHook';
import { useDeleteProjectMember } from '../../hooks/useProjectsHook';
import { useGetUser } from '../../hooks/useUserHook';
import { useCurrentOrganization } from '../../utils/helpers';

type ConfirmDeleteProjectDialogProps = {
  project: {
    id: number;
    name: string;
  };
};

const ConfirmDeleteProjectDialog = ({
  project,
}: ConfirmDeleteProjectDialogProps) => {
  const [open, setOpen] = useState(false);
  const params = useParams();

  const currentOrg = useCurrentOrganization();

  const { mutate: deleteProjectMember } = useDeleteProjectMember();

  const userId = params.user_id;

  const { data: member } = useGetOrgMemberById(
    {
      id: currentOrg?.id ?? NaN,
      memberId: Number(userId) ?? NaN,
    },
    {
      enabled: !!userId && !!currentOrg?.id,
    }
  );

  const projectMember = member?.project_memberships.find(
    (p) => p.project.id === project.id
  );

  const { data: currentUser } = useGetUser();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    if (!currentOrg || !projectMember) {
      return;
    }

    deleteProjectMember({
      orgId: currentOrg.id,
      projectId: project.id,
      memberId: projectMember.id,
    });
  };

  return (
    <>
      <button type="button" onClick={handleOpen}>
        <Trash2 color="#666666" width={20} height={20} />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <div className="flex flex-col gap-6 p-6">
          <h2 className="text-xl font-bold">
            {member?.user.id === currentUser?.id
              ? 'Are you sure you want to leave this project?'
              : `Are you sure you want to delete this user from ${project.name}?`}
          </h2>
          <div className="flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              tabIndex={3}
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              tabIndex={2}
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
                color: '#FFF',
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmDeleteProjectDialog;
