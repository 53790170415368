import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BaseTable from '../../common/BaseTable';
import { useGetProjects } from '../../hooks';
import { useCurrentOrganization } from '../../utils/helpers';

import AccessDeniedDialog from './AccessDeniedDialog';

export type ProjectRow = {
  id: number;
  name: string;
  description: string | undefined;
  modified: string | undefined;
};

const defaultData: ProjectRow[] = [];

const ProjectsTable = () => {
  const [data, setData] = useState<ProjectRow[]>(defaultData);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const organization = useCurrentOrganization();

  const { data: projects, isSuccess } = useGetProjects(
    organization?.id ?? NaN,
    {
      enabled: !!organization,
    }
  );

  const handleSelect = (id: number) => {
    if (
      organization?.role === 'organization_admin' &&
      !projects?.find((project) => project.id === id)?.role
    ) {
      setOpenDialog(true);
      return;
    }

    navigate(id.toString());
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (isSuccess) {
      setData(
        projects.map((project) => ({
          id: project.id,
          name: project.name,
          description: project.description,
          modified: project.updated_at,
          role: project.role,
        }))
      );
    }
  }, [projects, isSuccess]);

  return (
    <>
      <BaseTable data={data} handleSelect={handleSelect} type="project" />
      <AccessDeniedDialog
        title={'Permission Denied'}
        open={openDialog}
        content={"You don't have permission to go to this project"}
        handleOnClose={handleCloseDialog}
      />
    </>
  );
};

export default ProjectsTable;
