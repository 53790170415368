import { useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import * as z from 'zod';

import FormInput from '../components/FormInput';
import PasswordChangeSentDialog from '../components/PasswordChangeSentDialog';
import { useResetPasswordRequest } from '../hooks/useAuthHook';
import { handleQueryError } from '../utils/api';
import cn from '../utils/cn';

const PasswordChangeFormFormSchema = z.object({
  email: z.string().trim().email('Enter your email address'),
});

type PasswordChangeFormSchemaType = z.infer<
  typeof PasswordChangeFormFormSchema
>;

const PasswordChangeForm = () => {
  const [error, setError] = useState(Object);
  const [searchParams, setSearchParams] = useSearchParams();

  const [openChangePasswordSentDialog, setOpenChangePasswordSentDialog] =
    useState(false);

  const { mutate: changePassword, isPending } = useResetPasswordRequest({
    onSuccess: () => {
      // @todo: Need to handle this
      setOpenChangePasswordSentDialog(true);
    },
    onError: (error) => {
      handleQueryError(error, setError);
    },
  });

  const form = useForm<PasswordChangeFormSchemaType>({
    resolver: zodResolver(PasswordChangeFormFormSchema),
  });

  const { handleSubmit, watch } = form;

  const onSubmit: SubmitHandler<PasswordChangeFormSchemaType> = (data) => {
    changePassword({ data });
  };

  const handleCTAOnClick = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('type');
    setSearchParams(newSearchParams);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div
      className={cn(
        'absolute flex w-full translate-y-[120vh] justify-center transition duration-500',
        {
          'translate-y-0': searchParams.get('type') === 'reset_password',
        }
      )}
    >
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="border-grey-500 flex w-full max-w-[600px] flex-col items-start gap-3 rounded-xl border-[2px] px-8 py-6 text-white backdrop-blur-sm"
        >
          <div className="flex flex-col pb-6">
            <h1 className="text-2xl font-bold uppercase">
              forgot your password
            </h1>
            <p className="text-sm">Your password will be reset by email.</p>
          </div>
          <FormInput
            fullWidth
            name="email"
            label="Email"
            labelClassName="text-white"
            style={{
              backgroundColor: 'white',
            }}
          />
          <LoadingButton
            loading={isPending}
            type="submit"
            style={{
              backgroundColor: '#B8341B',
              color: 'white',
              width: '100%',
              marginTop: 20,
              padding: '12px 0px',
              borderRadius: '4px',
            }}
          >
            Submit
          </LoadingButton>
          <button
            type="button"
            onClick={handleCTAOnClick}
            className="w-full py-5 text-center"
          >
            <p>Back to Login</p>
          </button>
          <div className="w-full text-center font-light">
            <p>@{currentYear} ISBE Group</p>
          </div>
          <PasswordChangeSentDialog
            open={openChangePasswordSentDialog}
            handleOnClose={() => {
              setOpenChangePasswordSentDialog(false);
            }}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default PasswordChangeForm;
