import React, { useState } from 'react';

import { MailPlus } from 'lucide-react';

import {
  useGetOrganizations,
  useResendInvitation,
} from '../../hooks/useOrganizationHook';
import InviteSentDialog from '../SuccessDialog';

type ResendOrgInvitationProps = {
  invitationId: number;
};

const ResendOrgInvitation = ({ invitationId }: ResendOrgInvitationProps) => {
  const { mutate: resendInvitation } = useResendInvitation({
    onSuccess: () => {
      setOpenSuccessDialog(true);
    },
  });

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const { data: organizations } = useGetOrganizations();

  const organization = organizations?.[0];

  const handleOnClick = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    if (!organization) {
      return;
    }

    resendInvitation({
      id: organization.id,
      invitationId,
    });
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  return (
    <>
      <button onClick={handleOnClick}>
        <MailPlus width={20} height={20} color="#666666" />
      </button>
      <InviteSentDialog
        content="Invitation has been resent successfully."
        open={openSuccessDialog}
        handleOnClose={handleCloseSuccessDialog}
      />
    </>
  );
};

export default ResendOrgInvitation;
