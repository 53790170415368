import React from 'react';

import { Button, Dialog } from '@mui/material';

type ConfirmDiscardsDialogProps = {
  title: string;
  open: boolean;
  content: React.ReactNode;
  handleOnClose: () => void;
};

const AccessDeniedDialog = ({
  title,
  open,
  handleOnClose,
  content,
}: ConfirmDiscardsDialogProps) => (
  <Dialog
    open={open}
    onClose={handleOnClose}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <div className="flex flex-col gap-6 p-6">
      <h2 className="text-xl font-bold">{title}</h2>
      <div className="text-base text-[#666]">{content}</div>
      <div className="flex justify-end gap-3">
        <Button onClick={handleOnClose} variant="contained" tabIndex={3}>
          Ok
        </Button>
      </div>
    </div>
  </Dialog>
);

export default AccessDeniedDialog;
