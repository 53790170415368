import React, { useEffect, useState } from 'react';

import { ArrowUpward } from '@mui/icons-material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import { useGetOrgSharedRequests } from '../../../hooks/useOrganizationHook';
import cn from '../../../utils/cn';
import { useCurrentOrganization } from '../../../utils/helpers';

import MemberApprovalListDialog from './MemberApprovalListDialog';

export type DataRow = {
  id: number;
  name: string;
  project: string;
  requester: string;
  approvedReceivers: string;
  createdAt: string;
  action?: React.ReactNode;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => (
      <div className="group flex items-center gap-2 py-3 text-start">
        <p className="w-full">{info.getValue()}</p>
      </div>
    ),
    header: () => (
      <div className="items-centert flex gap-2">
        <p>Model Name</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('project', {
    id: 'project',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>Project Name</div>,
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'yyyy/MM/dd')}
        </div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>Date Requested</div>,
  }),
  columnHelper.accessor('approvedReceivers', {
    id: 'approvedReceivers',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>No. of Receivers</div>,
  }),
  columnHelper.accessor('action', {
    id: 'action',
    cell: (info) => {
      const id = info.row.original.id;

      return (
        <div className="w-full">
          <MemberApprovalListDialog id={id} />
        </div>
      );
    },
    header: () => <div>Action</div>,
  }),
];

const SentRequestsTable = () => {
  const [data, setData] = useState<DataRow[]>([]);

  const currentOrg = useCurrentOrganization();

  const { data: sharedRequests } = useGetOrgSharedRequests(
    currentOrg?.id ?? NaN,
    {
      enabled: !!currentOrg?.id && currentOrg?.role === 'organization_admin',
    }
  );

  useEffect(() => {
    if (sharedRequests) {
      setData(
        sharedRequests.map((r) => {
          const approvedReceivers = r.sharing_invitations.filter(
            (i) => i.accept_status === 'Accepted'
          );

          return {
            id: r.id,
            name: r.model.name,
            project: r.project.name,
            approvedReceivers: `${approvedReceivers.length} of ${r.sharing_invitations.length}`,
            createdAt: format(r.updated_at, 'yyyy/MM/dd'),
          };
        }) as DataRow[]
      );
    }
  }, [sharedRequests]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full">
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
            >
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={cn(
                    'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer px-6 py-3 transition">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!table.getRowModel().rows.length && (
        <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
          <p className="text-lg text-[#999]">No sharing requests available</p>
        </div>
      )}
      <div className="h-4" />
    </div>
  );
};

export default SentRequestsTable;
