import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';

import TopBar from '../common/TopBar';
import { useGetUser } from '../hooks/useUserHook';
import cn from '../utils/cn';

import CustomDrawer from './drawer/CustomDrawer';

const ProtectedRoute = () => {
  const [open, setOpen] = useState(true);
  const { data: user, isFetched } = useGetUser();
  const location = useLocation();
  const pathName = location.pathname;

  const [onHover, setOnHover] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className="relative flex">
      <div
        onMouseLeave={() => {
          setOnHover(false);
        }}
        onMouseEnter={() => {
          setOnHover(true);
        }}
      >
        {!pathName.split('/').includes('login') && <CustomDrawer open={open} />}
      </div>
      <div className="relative w-full">
        <header className="sticky top-0 z-20 w-full">
          <button
            onMouseLeave={() => {
              setOnHover(false);
            }}
            onMouseEnter={() => {
              setOnHover(true);
            }}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            className={cn(
              'absolute shadow-md -left-[12px] top-4 z-50 flex w-fit items-center justify-center overflow-hidden rounded-full border border-[#E2E8F0] bg-white p-1 opacity-0 transition',
              {
                'opacity-100': onHover,
              }
            )}
          >
            {open ? (
              <ArrowBackIosNew
                style={{
                  height: '16px',
                  width: '16px',
                }}
              />
            ) : (
              <ArrowForwardIos
                style={{
                  height: '16px',
                  width: '16px',
                }}
              />
            )}
          </button>
          <TopBar />
        </header>
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedRoute;
