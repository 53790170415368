import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

type ConfirmUpdateDatasetDialogInput = {
  open: boolean;
  handleOnClose: () => void;
  handlePrimaryAction: () => void;
  handleSecondaryAction: () => void;
};

const ConfirmUpdateDatasetDialog = ({
  open,
  handlePrimaryAction,
  handleSecondaryAction,
  handleOnClose,
}: ConfirmUpdateDatasetDialogInput) => (
  <Dialog
    open={open}
    onClose={handleOnClose}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <DialogTitle id="alertTitle" style={{ cursor: 'move' }}>
      <h2 className="font-bold">How do want to save the dataset</h2>
    </DialogTitle>
    <DialogContent>
      All of the upload IFS files will be overwritten. Do you want to overwrite
      the existing dataset or create a new one?
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={handleSecondaryAction} tabIndex={3}>
        Overwrite
      </Button>
      <Button variant="contained" onClick={handlePrimaryAction} tabIndex={3}>
        Create new
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmUpdateDatasetDialog;
