import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button, styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

import FormInput from '../components/FormInput';
import FormSelect from '../components/FormSelect';
import CreateModelFormDialog from '../components/model/CreateModelFormDialog';
import ModelComparisonView from '../components/model/ModelComparisonView';
import ModelOutputTabs from '../components/model/ModelOutputTabs';
import ScenarioInputTable from '../components/model/ScenarioInputTable';
import ShareModelFormDialog from '../components/model/ShareModelFormDialog';
import { useGetDatasetById, useGetDataTablesByDatasetId } from '../hooks';
import {
  useGetModelById,
  useGetModelOutput,
  useShareModelToHost,
} from '../hooks/useModelsHook';
import { useGetScenariosByModelId } from '../hooks/useScenariosHook';
import { useCurrentProject } from '../utils/helpers';

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

type StyledTabsProps = {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

type StyledTabProps = {
  label: string;
  id: string;
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#01285F',
  },
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    gap: '16px',
    paddingLeft: '46px',
    borderBottom: '1px solid #E4E7EC',
    backgroundColor: '#F9FAFB',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Nunito Sans, sans-serif',
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '16px',
  '&.MuiButtonBase-root': {
    minWidth: '0px !important',
    padding: '0px !important',
  },
  '&.Mui-selected': {
    color: '#01285F',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={value} {...other}>
      {value === index && children}
    </div>
  );
};

const tabs = ['Details', 'Overview'];

const ProjectViewDetails = () => {
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();

  const currentProject = useCurrentProject();
  const { org_id } = useParams();

  const datasetId = searchParams.get('dataset_id');
  const modelId = searchParams.get('model_id');

  const [openDialogCreateDataTable, setOpenDialogCreateDataTable] =
    useState(false);

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!org_id && !!project_id,
    }
  );

  const { data: scenarios } = useGetScenariosByModelId(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!modelId && !!org_id && !!project_id,
    }
  );

  const { data: model } = useGetModelById(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!modelId && !!org_id && !!project_id,
    }
  );

  const { data: dataTables } = useGetDataTablesByDatasetId(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!project_id && !!org_id,
      retry: 1,
    }
  );

  const { mutate: shareToHost } = useShareModelToHost();

  const handleShareToHost = (modelId: number) => {
    shareToHost({
      modelId,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    });
  };

  const handleOpenDialogCreateDataTable = () => {
    setOpenDialogCreateDataTable(true);
  };

  const handleCloseDialogCreateDataTable = () => {
    setOpenDialogCreateDataTable(false);
  };

  const defaultValues =
    model && dataset && scenarios
      ? {
          name: model.name,
          description: model.description,
          dataset: dataset.name,
          scenarios: scenarios.reduce(
            (acc, curr) => {
              const table =
                dataTables?.find(
                  (dataTable) => dataTable.id === curr.data_table
                )?.name ?? 'Unknown';

              acc[table] = curr.id.toString();
              return acc;
            },
            {} as Record<string, string>
          ),
        }
      : undefined;

  const roles = [
    {
      label: 'Project Owner',
      value: 'project_owner',
    },
    {
      label: 'Project Member',
      value: 'project_member',
    },
  ];

  const isGuestProject = currentProject?.is_guest_project;

  return (
    <div className="space-y-5 px-10">
      {currentProject?.role === 'project_owner' && (
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">{model?.name}</h1>
          {isGuestProject && !model?.is_received ? (
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                handleShareToHost(Number(modelId));
              }}
              style={{
                color: '#B8341B',
                borderColor: '#B8341B',
                display: 'flex',
                gap: 4,
                borderRadius: 4,
                padding: '6px 12px',
              }}
            >
              Share To Host
            </Button>
          ) : (
            !model?.is_base &&
            !model?.sharing && <ShareModelFormDialog roles={roles} />
          )}
        </div>
      )}
      <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
        <h2 className="text-xl font-bold">Description</h2>
        <p>{model?.description}</p>
      </div>
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold">Scenario</h2>
        {!model?.sharing && (
          <CreateModelFormDialog
            title="Select scenarios"
            edit={
              defaultValues && {
                defaultValues,
                type: 'scenarios',
              }
            }
            trigger={{
              label: 'Edit',
              style: {
                color: '#fff',
                height: '35px',
                borderRadius: '4px',
                backgroundColor: '#B8341B',
                padding: '2px 16px',
              },
            }}
            open={openDialogCreateDataTable}
            handleOpenDialog={handleOpenDialogCreateDataTable}
            handleCloseDialog={handleCloseDialogCreateDataTable}
          />
        )}
      </div>
      <ScenarioInputTable />
    </div>
  );
};

const ProjectViewModel = () => {
  const [index, setIndex] = useState(0);
  const [searchParams] = useSearchParams();

  const { org_id, project_id } = useParams();

  const datasetId = searchParams.get('dataset_id');
  const modelId = searchParams.get('model_id');

  const form = useForm();

  const { handleSubmit, watch } = form;

  const modelMode = searchParams.get('mode');

  const [enabledRunOutput, setEnabledRunOutput] = useState(false);

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!org_id && !!project_id,
    }
  );

  const selectedAircraft = watch('aircraft');
  const selectedEmployeeId = watch('employeeId');

  const { refetch: refetchModelOutput } = useGetModelOutput(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
      data: {
        filters: {
          aircraft_type:
            selectedAircraft === 'all' ? undefined : selectedAircraft,
          employee_id: selectedEmployeeId?.length
            ? selectedEmployeeId
            : undefined,
        },
      },
    },
    {
      enabled:
        !!modelId &&
        !!org_id &&
        !!project_id &&
        enabledRunOutput &&
        enabledRunOutput,
    }
  );

  const aircrafts =
    dataset?.configuration.aircrafts.map((a) => ({
      label: a.name,
      value: a.name,
    })) ?? [];

  useEffect(() => {
    setEnabledRunOutput(false);
  }, [selectedAircraft, selectedEmployeeId]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  const onSubmit = (data: Record<string, any>) => {
    setEnabledRunOutput(true);
    refetchModelOutput();
  };

  if (modelMode === 'compare') {
    return <ModelComparisonView />;
  }

  return (
    <div className="space-y-6">
      <Tabs value={index} onChange={handleChangeTab}>
        {tabs.map((tab, i) => (
          <Tab id={i.toString()} key={tab} label={tab} />
        ))}
      </Tabs>
      <TabPanel index="0" value={index.toString()}>
        <ProjectViewDetails />
      </TabPanel>
      <TabPanel index="1" value={index.toString()}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="px-11">
            <div className="flex items-center justify-between">
              <h2 className="text-3xl font-bold">Overview</h2>
              <Button
                variant="contained"
                type="submit"
                style={{
                  color: '#fff',
                  height: '35px',
                  backgroundColor: '#B8341B',
                }}
              >
                Run
              </Button>
            </div>
            <div className="flex w-[40%] gap-4">
              <FormSelect
                name="aircraft"
                fullWidth
                options={[
                  {
                    label: 'All',
                    value: 'all',
                  },
                ].concat(aircrafts)}
                defaultValue="all"
                label="Aircraft"
              />
              <FormInput
                name="employeeId"
                fullWidth
                type="number"
                placeholder="Employee ID"
                label="Employee"
              />
            </div>
            <ModelOutputTabs enable={enabledRunOutput} />
          </form>
        </FormProvider>
      </TabPanel>
    </div>
  );
};

export default ProjectViewModel;
