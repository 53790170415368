import React, { useEffect, useState } from 'react';

import { ArrowUpward } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { useGetOrgSharedRequestById } from '../../../hooks/useOrganizationHook';
import { type ApproveStatus } from '../../../types';
import cn from '../../../utils/cn';
import { useCurrentOrganization } from '../../../utils/helpers';

export type DataRow = {
  id: number;
  name: string;
  status: ApproveStatus;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => {
      const name = info.getValue();

      return (
        <div className="flex w-full items-center justify-between">{name}</div>
      );
    },
    header: () => (
      <div className="flex items-center gap-1">
        <p>Receiver Name</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      const status = info.getValue();

      const StatusLabel = ({
        label,
        value,
      }: {
        value: 'pending' | 'approved' | 'disapproved';
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'approved',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
                'bg-[#FFF4F2] text-[#B8341B]': value === 'disapproved',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return (
        <div className="flex w-full items-center justify-between">
          {status === 'Approved' ? (
            <StatusLabel value="approved" label="Approved" />
          ) : null}
          {status === 'Pending' ? (
            <StatusLabel value="pending" label="Pending" />
          ) : null}
          {status === 'Disapproved' ? (
            <StatusLabel value="disapproved" label="Disapproved" />
          ) : null}
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-1">
        <p>Status</p>
      </div>
    ),
  }),
];

const MemberApprovalListDialog = ({ id }: { id: number }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<DataRow[]>([]);

  const currentOrg = useCurrentOrganization();

  const { data: request } = useGetOrgSharedRequestById(
    {
      orgId: Number(currentOrg?.id),
      requestId: Number(id),
    },
    {
      enabled: !!currentOrg?.id || !!id,
    }
  );

  useEffect(() => {
    if (request?.sharing_invitations) {
      setData(
        request.sharing_invitations.map((i) => ({
          id: i.id,
          name: i.email,
          status: i.approve_status,
        }))
      );
    }
  }, [request]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <>
      <button onClick={handleOpenDialog} className="text-[#2196F3]">
        View details
      </button>
      <Dialog open={open} maxWidth="md" fullWidth onClose={handleCloseDialog}>
        <div className="flex flex-col gap-6 p-12">
          <h2 className="text-xl font-semibold">Members Approval List</h2>
          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className={cn(
                        'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="px-6 transition">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Dialog>
    </>
  );
};

export default MemberApprovalListDialog;
