import React from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { GitCompareArrows } from 'lucide-react';
import * as z from 'zod';

import { type TypeModel } from '../../common/dataTypes';
import { useGetModelsByDatasetId } from '../../hooks';
import { arrangeByBase, useCurrentOrganization } from '../../utils/helpers';
import FormAutoComplete from '../FormAutoComplete';

import ModelComparisonOutputTabs from './ModelComparisonOutputTabs';

const ModelComparisonProps = z.object({
  modelA: z.string(),
  modelB: z.string(),
});

type ModelComparisonType = z.infer<typeof ModelComparisonProps>;

const ModelComparisonView = () => {
  const currentOrg = useCurrentOrganization();

  const [searchParams] = useSearchParams();
  const { project_id } = useParams();

  const datasetId = searchParams.get('dataset_id');

  const [open, setOpen] = React.useState(false);

  const form = useForm<ModelComparisonType>({
    resolver: zodResolver(ModelComparisonProps),
  });

  const { data: models } = useGetModelsByDatasetId(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!currentOrg?.id && !!project_id,
    }
  );

  const { watch, handleSubmit } = form;

  const { modelA, modelB } = watch();

  const handleSetOpen = (value: boolean) => {
    setOpen(value);
  };

  const onSubmit: SubmitHandler<ModelComparisonType> = (data) => {
    if (data.modelA && data.modelB) {
      setOpen(true);
      return;
    }
  };

  const listModel = models
    ? arrangeByBase(models.filter((m) => !m.sharing) as TypeModel[]).map(
        (model) => ({
          label: model.name,
          value: model.id.toString(),
        })
      )
    : [
        {
          label: '',
          value: '',
        },
      ];

  return (
    <div className="flex flex-col gap-10 px-10">
      <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
        <h2 className="text-xl font-bold">Instruction</h2>
        <p>
          This comparison page allows you to compare models on the “Current”
          section. The comparison works by subtracting Model B from Model A to
          highlight the differences. In the results, green indicates an
          increase, while red indicates a decrease.
        </p>
      </div>
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex items-center justify-between"
        >
          <div className="flex w-[50%] gap-4">
            <FormAutoComplete
              fullWidth
              label="Model A"
              name="modelA"
              options={listModel}
            />
            <FormAutoComplete
              fullWidth
              label="Model B"
              name="modelB"
              options={listModel}
            />
          </div>
          <Button
            style={{
              color: 'white',
              display: 'flex',
              gap: 4,
              backgroundColor: '#B8341B',
            }}
            type="submit"
          >
            <GitCompareArrows size={20} />
            <p>Run Comparison</p>
          </Button>
        </form>
      </FormProvider>
      <div>
        <ModelComparisonOutputTabs
          enable={open}
          baseId={Number(modelA)}
          targetId={Number(modelB)}
          setEnable={handleSetOpen}
        />
      </div>
    </div>
  );
};

export default ModelComparisonView;
