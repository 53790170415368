import React from 'react';

import { useCurrentMembership } from '../../utils/helpers';
import LastModifiedFilter from '../LastModifiedFilter';
import SearchInput from '../SearchInput';

import CreateProjectDialog from './CreateProjectDialog';
import ProjectsTable from './ProjectsTable';

const ProjectsView = () => {
  const membership = useCurrentMembership();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-6 px-12 py-6">
        <h1 className="text-3xl font-bold">Projects</h1>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="min-w-[300px]">
              <SearchInput />
            </div>
            <LastModifiedFilter />
          </div>
          {membership?.organization?.role === 'organization_admin' && (
            <CreateProjectDialog />
          )}
        </div>
        <ProjectsTable />
      </div>
    </div>
  );
};

export default ProjectsView;
