import React from 'react';

import { Dialog } from '@mui/material';

import Success from '../icons/Success';

type InviteSentDialogProps = {
  title?: string;
  content: React.ReactNode;
  open: boolean;
  handleOnClose: () => void;
};

const SuccessDialog = ({
  title = 'Success',
  open,
  handleOnClose,
  content,
}: InviteSentDialogProps) => (
  <Dialog
    open={open}
    onClose={handleOnClose}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <div className="flex flex-col gap-2 p-8 text-center">
      <div className="flex justify-center">
        <Success />
      </div>
      <h2 className="text-xl font-bold">{title}</h2>
      <p className="text-base text-[#666]">{content}</p>
      <button
        onClick={handleOnClose}
        className="rounded-md bg-[#2196F3] px-48 py-1 text-white"
      >
        OK
      </button>
    </div>
  </Dialog>
);

export default SuccessDialog;
