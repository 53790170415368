import React, {
  type SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  styled,
  Switch,
  Tab as MuiTab,
  Tabs as MuiTabs,
} from '@mui/material';
import {
  type ColumnsStylesInterface,
  DataGridPremium,
  type GridColDef,
  type GridExcelExportOptions,
  type GridPinnedColumns,
  type GridPinnedRowsProp,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { type GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ArrowDownToLine } from 'lucide-react';

import {
  getColsFromRowReport,
  transformDataReport,
} from '../../common/dataTableFormatter';
import { useGetDatasetById } from '../../hooks';
import {
  useGetModelById,
  useGetModelOutput,
  useGetModelsByDatasetId,
} from '../../hooks/useModelsHook';

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

type StyledTabsProps = {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

type StyledTabProps = {
  label: string;
  id: string;
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#B8341B',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  '&.MuiButtonBase-root': {
    padding: '10px 10px !important',
  },
  '&.Mui-selected': {
    color: '#B8341B',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

export const Reports = {
  average_expense: 'Average Expenses',
  average_rate: 'Average Rate',
  stats: 'Stats',
  kpis: 'KPIs',
  financials: 'Financials',
  crew_requirements: 'Crew Requirements',
  movement_matrix: 'Movement Matrix',
  training: 'Training',
  seniority_distribution: 'Seniority Distribution',
};

export const TABS = [
  'financials',
  'kpis',
  'crew_requirements',
  'movement_matrix',
  'training',
  'seniority_distribution',
];

export type Report = keyof typeof Reports;

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="h-[380px] space-y-6"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const ModelTablesTab = ({
  enable,
  report,
  apiRef,
}: {
  enable: boolean;
  report: Report;
  apiRef: React.MutableRefObject<GridApiPremium>;
}) => {
  const { watch } = useFormContext();

  const [searchParams] = useSearchParams();

  const [reportTableRows, setReportTableRows] = useState<Object[]>([]);
  const [reportTableCols, setReportTableCols] = useState<GridColDef[]>([]);

  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>();
  const [pinnedRows, setPinnedRows] = useState<GridPinnedRowsProp>();

  const { org_id, project_id } = useParams();
  const modelId = searchParams.get('model_id');

  const selectedAircraft = watch('aircraft');
  const selectedEmployeeId = watch('employeeId');

  const {
    data: modelOutput,
    isFetching: isFetchingModelOutput,
    isRefetching: isRefetchingModelOutput,
  } = useGetModelOutput(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
      data: {
        filters: {
          aircraft_type:
            selectedAircraft === 'all' ? undefined : selectedAircraft,
          employee_id: selectedEmployeeId?.length
            ? selectedEmployeeId
            : undefined,
        },
      },
    },
    {
      enabled: !!modelId && !!org_id && !!project_id && enable,
    }
  );

  useEffect(() => {
    if (modelOutput && report && modelOutput[report]) {
      const currentReport = modelOutput[report];

      const transformedData = transformDataReport(currentReport, report);
      const cols = getColsFromRowReport(transformedData[0], report);

      setReportTableCols(cols);
      setReportTableRows(transformedData);

      if (report === 'crew_requirements') {
        setPinnedColumns({
          left: ['hierarchy', 'seat'],
        });
      } else if (report === 'movement_matrix') {
        setPinnedColumns({
          left: ['employee_id'],
        });
      } else if (report === 'seniority_distribution') {
        setPinnedColumns({
          left: ['step', 'seniority'],
        });
      } else if (report === 'financials') {
        setPinnedColumns({
          left: ['expense_type_verbose'],
        });
      } else if (report === 'training') {
        setPinnedColumns({
          left: ['from', 'to'],
        });
      } else if (report === 'stats') {
        setPinnedColumns({
          left: ['stats_name'],
        });
      } else if (report === 'average_expense') {
        setPinnedColumns({
          left: ['expense_name'],
        });
      } else if (report === 'average_rate') {
        setPinnedColumns({
          left: ['crew_type'],
        });
      }

      if (
        report !== 'movement_matrix' &&
        report !== 'stats' &&
        report !== 'average_expense' &&
        report !== 'average_rate' &&
        report !== 'kpis'
      ) {
        setPinnedRows({
          bottom: [transformedData[transformedData.length - 1]],
        });
      } else {
        setPinnedRows(undefined);
      }
    } else {
      setReportTableCols([]);
      setReportTableRows([]);
    }
  }, [modelOutput, report]);

  return (
    <DataGridPremium
      treeData={
        report === 'financials' ||
        report === 'average_expense' ||
        report === 'kpis'
      }
      getTreeDataPath={(row) => row.tree ?? [row.id.toString()]}
      groupingColDef={{
        headerName:
          report === 'financials'
            ? 'Expense'
            : report === 'average_expense'
              ? 'Average Expense'
              : report === 'kpis'
                ? 'KPIs'
                : '',
      }}
      apiRef={apiRef}
      rows={reportTableRows}
      columns={reportTableCols}
      pagination={false}
      disableSelectionOnClick
      loading={isRefetchingModelOutput || isFetchingModelOutput}
      disableAggregation
      density="compact"
      initialState={{ aggregation: { model: { gross: 'sum' } } }}
      pinnedColumns={pinnedColumns}
      defaultGroupingExpansionDepth={2}
      experimentalFeatures={{ rowPinning: true }}
      pinnedRows={pinnedRows}
    />
  );
};

const ModelOutputTabs = ({ enable }: { enable: boolean }) => {
  const [index, setIndex] = useState(0);
  const [checkVariance, setCheckVariance] = useState(false);
  const [report, setReport] = useState<Report>('financials');
  const [searchParams] = useSearchParams();

  const { watch } = useFormContext();

  const [reportTableRows, setReportTableRows] = useState<Object[]>([]);
  const [reportTableCols, setReportTableCols] = useState<GridColDef[]>([]);

  const { org_id, project_id } = useParams();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const value = event.currentTarget.id as Report;

    setIndex(newValue);
    setReport(value);
  };

  const modelId = searchParams.get('model_id');
  const datasetId = searchParams.get('dataset_id');

  const { data: models } = useGetModelsByDatasetId(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!org_id && !!project_id,
    }
  );

  const baseModel = models?.find((m) => m.is_base);

  const { data: baseModelOutput } = useGetModelOutput(
    {
      modelId: Number(baseModel?.id) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
      data: {
        filters: {},
      },
    },
    {
      enabled: !!baseModel?.id && enable && !!org_id && !!project_id,
    }
  );

  const { data: model } = useGetModelById(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!modelId && !!org_id,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!org_id && !!project_id,
    }
  );

  const apiRef = useGridApiRef();

  const handleOnToggleVariance = useCallback(() => {
    if (!baseModelOutput) {
      return;
    }

    if (!checkVariance) {
      const transformedData = transformDataReport(
        baseModelOutput[report],
        report
      );

      const newCols = getColsFromRowReport(
        reportTableRows[0],
        report,
        transformedData
      );

      setCheckVariance(true);
      setReportTableCols(newCols);
    } else {
      const newCols = getColsFromRowReport(reportTableRows[0], report);

      setReportTableCols(newCols);
      setCheckVariance(false);
    }
  }, [report, checkVariance, baseModelOutput, reportTableRows]);

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};
    reportTableCols.forEach((colDef) => {
      colStyles[colDef.field] = {
        numFmt: '#,##0.00',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: `${dataset?.name}_${model?.name}_${report}`,
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <div className="flex items-center justify-between">
        <Tabs value={index} onChange={handleChange}>
          {TABS.map((report) => (
            <Tab id={report} key={report} label={Reports[report as Report]} />
          ))}
        </Tabs>
        <div className="flex items-center gap-1">
          {baseModel && modelId && Number(modelId) !== baseModel.id && (
            <div className="flex items-center">
              <label className="text-sm font-semibold text-[#333]">
                Variance
              </label>
              <Switch
                checked={checkVariance}
                onChange={handleOnToggleVariance}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
          )}
          <Button
            size="small"
            onClick={handleExportToExcel}
            variant="outlined"
            className="space-x-1 font-semibold"
          >
            <ArrowDownToLine />
            <label>Export to Excel</label>
          </Button>
        </div>
      </div>
      {report && (
        <div className="py-6">
          <CustomTabPanel value={report} index={report}>
            <ModelTablesTab enable={enable} report={report} apiRef={apiRef} />
            {report === 'kpis' && (
              <>
                <ModelTablesTab
                  enable={enable}
                  report={'average_expense'}
                  apiRef={apiRef}
                />
                <ModelTablesTab
                  enable={enable}
                  report={'average_rate'}
                  apiRef={apiRef}
                />
                <ModelTablesTab
                  enable={enable}
                  report={'stats'}
                  apiRef={apiRef}
                />
              </>
            )}
          </CustomTabPanel>
        </div>
      )}
    </Box>
  );
};

export default ModelOutputTabs;
