import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { MenuItem, Switch } from '@mui/material';
import { EllipsisVertical } from 'lucide-react';

import { type TypeScenarioVector } from '../../common/dataTypes';
import { useUpdateVector } from '../../hooks';
import { useGetScenarioVectors } from '../../hooks/useScenariosHook';
import { useDeleteVector } from '../../hooks/useVectorHook';
import cn from '../../utils/cn';
import { useCurrentOrganization } from '../../utils/helpers';
import MenuDropdown from '../MenuDropdown';
import SimpleConfirmDeleteDialog from '../SimpleConfirmDeleteDialog';
import CreateVectorFormDialog from '../vector/CreateVectorFormDialog';

const getVectorDirection = (vector: TypeScenarioVector) => {
  if (
    (vector.delta && vector.delta > 0) ||
    (vector.delta_percentage && vector.delta_percentage > 0)
  ) {
    return 'increase';
  }

  if (
    (vector.delta && vector.delta < 0) ||
    (vector.delta_percentage && vector.delta_percentage < 0)
  ) {
    return 'decrease';
  }

  if (vector.new_value) {
    return 'set';
  }
};

const Vector = ({
  vector,
  onToggle,
}: {
  vector: TypeScenarioVector;
  onToggle: () => void;
}) => {
  const [checked, setChecked] = useState(vector.is_active);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [openDialogCreateVector, setOpenDialogCreateVector] = useState(false);

  const open = Boolean(anchorEl);

  const [searchParams] = useSearchParams();
  const scenarioId = Number(searchParams.get('scenario_id')) ?? NaN;

  const currentOrg = useCurrentOrganization();

  const { project_id: projectId } = useParams();

  const { mutate: updateVector } = useUpdateVector({
    onSuccess() {
      onToggle();
    },
  });

  const { mutate: deleteVector } = useDeleteVector();

  const direction = getVectorDirection(vector);

  const handleCloseDropdown = () => setAnchorEl(null);

  const handleOpenDialogCreateVector = () => setOpenDialogCreateVector(true);

  const handleToggleCheck = () => {
    setChecked(!checked);
  };

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDelete = () => {
    if (currentOrg && vector && projectId) {
      deleteVector({
        orgId: currentOrg.id,
        projectId: Number(projectId),
        vectorId: vector.id,
      });
    }
  };

  useEffect(() => {
    onToggle();
  }, []);

  useEffect(() => {
    if (!currentOrg) {
      return;
    }

    if (checked) {
      updateVector({
        orgId: currentOrg.id,
        vectorId: vector.id,
        projectId: Number(projectId),
        data: {
          ...vector,
          scenario: scenarioId,
          is_active: true,
        },
      });
    } else {
      updateVector({
        orgId: currentOrg.id,
        vectorId: vector.id,
        projectId: Number(projectId),
        data: {
          ...vector,
          scenario: scenarioId,
          is_active: false,
        },
      });
    }
  }, [checked]);

  return (
    <div className="flex items-center justify-between gap-3">
      <div className="flex grow items-center gap-3 rounded-[4px] border border-[#CCC] bg-[#FFF] px-6 py-3">
        <span
          className={cn('font-bold transition uppercase text-[#333]', {
            'text-[#B3B3B3]': !checked,
          })}
        >
          {direction}
        </span>
        <span
          className={cn(
            'rounded-full bg-[#FFF9EB] transition px-3 py-1 font-semibold text-[#C08100]',
            {
              'text-[#999] bg-[#F5F5F5]': !checked,
            }
          )}
        >
          {vector.field_name}
        </span>
        {direction === 'set' && (
          <p
            className={cn('space-x-3 transition', {
              'text-[#B3B3B3]': !checked,
            })}
          >
            <span className="font-semibold uppercase text-[#333]">to</span>
            <span className="rounded-full bg-[#EFFCFF] px-3 py-1 font-semibold">
              {vector.new_value}%
            </span>
          </p>
        )}
        {(direction === 'increase' || direction === 'decrease') && (
          <p
            className={cn('space-x-3 transition', {
              '!text-[#B3B3B3]': !checked,
            })}
          >
            <span className="font-bold uppercase">by</span>
            <span className="rounded-full bg-[#EFFCFF] px-3 py-1 font-semibold">
              {vector.delta
                ? vector.delta?.toString().replace('-', '')
                : vector.delta_percentage?.toString().replace('-', '')}
            </span>
          </p>
        )}
        <p
          className={cn('space-x-3 transition', {
            'text-[#B3B3B3]': !checked,
          })}
        >
          <span className="font-bold uppercase">on</span>
          <span>aircraft_type</span>
          <span>=</span>
          <span
            className={cn(
              'rounded-full bg-[#E8F2FF] transition px-3 py-1 font-semibold capitalize text-[#01285F]',
              {
                'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
              }
            )}
          >
            {
              vector?.criterias?.find((c) => c.field_name === 'aircraft_type')
                ?.value
            }
          </span>
          <span className="font-bold uppercase">AND</span>
          <span>timeline in</span>
          <span
            className={cn(
              'rounded-full bg-[#FFF4F2] px-3 py-1 transition font-semibold capitalize text-[#B8341B]',
              {
                'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
              }
            )}
          >
            {
              vector?.criterias?.find((c) => c.field_name === 'period')
                ?.value_from
            }
          </span>
          <span>to</span>
          <span
            className={cn(
              'rounded-full bg-[#FFF4F2] px-3 transition py-1 font-semibold capitalize text-[#B8341B]',
              {
                'text-[#B3B3B3] bg-[#F5F5F5]': !checked,
              }
            )}
          >
            {
              vector?.criterias?.find((c) => c.field_name === 'period')
                ?.value_to
            }
          </span>
        </p>
      </div>
      <Switch
        checked={checked}
        onChange={handleToggleCheck}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <MenuDropdown
        trigger={
          <button className="w-fit">
            <EllipsisVertical width={20} height={20} />
          </button>
        }
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        style={{
          marginTop: '5px',
          marginLeft: '-5px',
        }}
        open={open}
      >
        {vector && (
          <MenuItem
            onClick={() => {
              handleCloseDropdown();
              handleOpenDialogCreateVector();
            }}
          >
            Edit
          </MenuItem>
        )}
        <MenuItem>View</MenuItem>
        <MenuItem>Archive</MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setOpenConfirmDelete(true);
          }}
        >
          Delete
        </MenuItem>
      </MenuDropdown>
      <SimpleConfirmDeleteDialog
        open={openConfirmDelete}
        handleOnClose={handleCloseConfirmDelete}
        handleOnSubmit={handleDelete}
      />
      <CreateVectorFormDialog
        open={openDialogCreateVector}
        handleCloseDialog={() => {
          setOpenDialogCreateVector(false);
        }}
        handleOpenDialog={() => {
          setOpenDialogCreateVector(true);
        }}
        title="Edit Vector"
        defaultValues={{
          id: vector.id,
          name: vector.name,
          description: vector.description,
          aircraft_type:
            vector.criterias.find((f) => f.field_name === 'aircraft_type')
              ?.value ?? '',
          crew_type:
            vector.criterias.find((f) => f.field_name === 'crew_type')?.value ??
            '',
          from:
            vector.criterias.find((f) => f.field_name === 'period')
              ?.value_from ?? '',
          to:
            vector.criterias.find((f) => f.field_name === 'period')?.value_to ??
            '',
          field: vector.field_name,
          type: vector.delta ? 'delta' : 'percentage',
          action:
            (vector.delta
              ? (vector.delta as number)
              : (vector.delta_percentage as number)) > 0
              ? 'increase'
              : 'decrease',
          amount: vector.delta
            ? Math.abs(vector.delta)
            : Math.abs(vector.delta_percentage as number),
        }}
        showTrigger={false}
      />
    </div>
  );
};

const VectorsSection = ({ handleActivate }: { handleActivate: () => void }) => {
  const { project_id } = useParams();

  const [searchParams] = useSearchParams();
  const scenarioId = searchParams.get('scenario_id');

  const currentOrg = useCurrentOrganization();

  const { data: vectors } = useGetScenarioVectors(
    {
      scenarioId: Number(scenarioId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!scenarioId && !!currentOrg,
    }
  );

  return (
    <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] p-5">
      {vectors?.map((vector) => (
        <Vector key={vector.id} vector={vector} onToggle={handleActivate} />
      ))}
    </div>
  );
};

export default VectorsSection;
