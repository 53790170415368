import { type Granularity } from '../common/dataTypes';
import { type LaborGroup } from '../types';
import { mainApi } from '../utils/api';

export type UpdateDatasetInput = {
  id: number;
  orgId: number;
  projectId: number;
  data: {
    name?: string;
    description?: string;
    project: number;
  };
};

export type CreateDatasetInput = {
  orgId: number;
  projectId: number;
  data: {
    name: string;
    description?: string;
    project: number;
  };
};

export type DatasetConfiguration = {
  dataset: number;
  granularity: Granularity;
  start_period: string;
  duration: number;
  aircrafts: string;
  labor_group: LaborGroup;
  positions: string[];
  years_of_service: number;
  retirement_age: number;
};

export type CreateDatasetConfigurationInput = {
  data: DatasetConfiguration;
  datasetId: number;
};

export type UpdateDatasetConfigurationInput = {
  data: DatasetConfiguration;
  id: number;
  datasetId: number;
};

export type GetDatasetsInput = {
  projectId: number;
  orgId: number;
};

export type GetDatasetByIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type DeleteDatasetInput = {
  datasetId: number;
  orgId: number;
  projectId: number;
};

const getDatasets = ({ projectId, orgId }: GetDatasetsInput) =>
  mainApi.get(`organizations/${orgId}/projects/${projectId}/datasets/`);

const getDatasetById = ({ orgId, projectId, datasetId }: GetDatasetByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/`
  );

const createDataset = ({ orgId, projectId, data }: CreateDatasetInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/datasets/`, data);

const deleteDataset = ({ datasetId, projectId, orgId }: DeleteDatasetInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/datasets/${datasetId}/`
  );

const updateDataset = ({ id, data, orgId, projectId }: UpdateDatasetInput) =>
  mainApi.patch(
    `organizations/${orgId}/projects/${projectId}/datasets/${id}/`,
    data
  );

export {
  createDataset,
  deleteDataset,
  getDatasets,
  getDatasetById,
  updateDataset,
};
