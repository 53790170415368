import { useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import * as z from 'zod';

import FormInput from '../components/FormInput';
import LoginByLinkSentDialog from '../components/LoginByLinkSentDialog';
import SuccessDialog from '../components/SuccessDialog';
import { useLoginByLink } from '../hooks';
import { handleQueryError } from '../utils/api';
import cn from '../utils/cn';

const LoginByLinkFormSchema = z.object({
  email: z.string().trim().email(),
});

type LoginByLinkFormSchemaType = z.infer<typeof LoginByLinkFormSchema>;

const LoginByLinkForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(Object);
  const [searchParams, setSearchParams] = useSearchParams();

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const { mutate: loginByLink, isPending } = useLoginByLink({
    onSuccess: () => {
      setOpenSuccessDialog(true);
      reset();
    },
    onError: (error) => {
      handleQueryError(error, setError);
    },
  });

  const form = useForm<LoginByLinkFormSchemaType>({
    resolver: zodResolver(LoginByLinkFormSchema),
  });

  const { handleSubmit, reset } = form;

  const onSubmit: SubmitHandler<LoginByLinkFormSchemaType> = (data) => {
    setEmail(data.email);
    loginByLink({ data });
  };

  const handleCTAOnClick = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('type');
    setSearchParams(newSearchParams);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div
      className={cn(
        'absolute flex w-full translate-y-[120vh] justify-center transition duration-500',
        {
          'translate-y-0': searchParams.get('type') === 'login_by_link',
        }
      )}
    >
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="border-grey-500 flex w-full max-w-[600px] flex-col items-start gap-3 rounded-xl border-[2px] px-8 py-6 text-white backdrop-blur-sm"
        >
          <h1 className="text-2xl font-bold uppercase">login by link</h1>
          <FormInput
            fullWidth
            name="email"
            label="Email"
            labelClassName="text-white"
            style={{
              backgroundColor: 'white',
            }}
          />
          <LoadingButton
            loading={isPending}
            type="submit"
            style={{
              backgroundColor: '#B8341B',
              color: 'white',
              width: '100%',
              marginTop: 20,
              padding: '12px 0px',
              borderRadius: '4px',
            }}
          >
            Submit
          </LoadingButton>
          <button
            type="button"
            onClick={handleCTAOnClick}
            className="w-full py-5 text-center"
          >
            <p>Back to Login</p>
          </button>
          <div className="w-full text-center font-light">
            <p>@{currentYear} ISBE Group</p>
          </div>
          <LoginByLinkSentDialog
            open={openSuccessDialog}
            handleOnClose={() => {
              setOpenSuccessDialog(false);
            }}
          />
        </form>
      </FormProvider>
      <SuccessDialog
        content={
          <p>
            We’ve resent log in link to{' '}
            <span className="font-semibold">{email}</span>
          </p>
        }
        open={openSuccessDialog}
        handleOnClose={() => {
          setOpenSuccessDialog(false);
        }}
      />
    </div>
  );
};

export default LoginByLinkForm;
