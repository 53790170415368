import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@mui/material';

import FormInput from '../FormInput';

type ExpenseDetailFormProps = {
  type: 'default' | 'user';
  defaultValues: {
    name: string;
    group: string;
    description: string;
  };
};

const ExpenseDetailForm = ({ type, defaultValues }: ExpenseDetailFormProps) => {
  const form = useForm({
    defaultValues,
  });

  const { reset } = form;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const isDisable = type === 'default';

  return (
    <FormProvider {...form}>
      <form className="flex flex-col">
        <div className="flex items-center justify-between">
          <h1 className="text-[28px] font-bold">Title of Selected Expense</h1>
          <Button variant="contained">Save change</Button>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-1">
            <FormInput
              disabled={isDisable}
              fullWidth
              label="Name"
              name="name"
            />
          </div>
          <div className="col-span-1">
            <FormInput
              disabled={isDisable}
              fullWidth
              label="Category"
              name="group"
            />
          </div>
          <div className="col-span-1">
            <FormInput
              disabled={isDisable}
              fullWidth
              label="Annualized Adjustment"
              name="annualizedAdjustment"
              type="number"
            />
          </div>
          <div className="col-span-3">
            <FormInput
              disabled={isDisable}
              fullWidth
              label="Description"
              name="description"
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ExpenseDetailForm;
