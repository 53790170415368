import React, { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';

import { formatDatasetConfig } from '../../common/utils';
import { useGetDatasetById, useGetProjectById } from '../../hooks';
import { useGenerateIFS, useGetIFS } from '../../hooks/useIFSHook';
import {
  generateMonthList,
  generateWeekList,
  transformToLabel,
} from '../../utils/helpers';
import ConfirmGenerateIFSDialog from '../ConfirmGenerateIFSDialog';
import GenerateNoChangeWarningDialog from '../GenerateNoChangeWarningDialog';
import SectionLine from '../SectionLine';

import CreateDatasetDialog from './CreateDatasetDialog';
import IFSTable from './IFSTable';
import GenerateLoadingDialog from './LoadingDialog';

const ProjectViewDataset = () => {
  const { project_id: projectId, org_id: orgId } = useParams();

  const [searchParams] = useSearchParams();
  const datasetId = Number(searchParams.get('dataset_id')) ?? NaN;

  const monthList = useMemo(() => generateMonthList(), []);
  const weekList = useMemo(() => generateWeekList(), []);

  const { data: project } = useGetProjectById(
    {
      projectId: Number(projectId) ?? NaN,
      orgId: Number(orgId) ?? NaN,
    },
    {
      enabled: !!projectId && !!orgId,
    }
  );

  const {
    data: dataset,
    isSuccess: isFetchedDatasetSuccessfully,
    isPending: isFetchingDataset,
  } = useGetDatasetById(
    {
      datasetId,
      orgId: Number(orgId) ?? NaN,
      projectId: Number(projectId),
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const aircrafts = useMemo(
    () => dataset?.configuration.aircrafts,
    [dataset?.configuration.aircrafts]
  );

  const [openConfirmGenerateIFSDialog, setOpenConfirmGenerateIFSDialog] =
    useState(false);

  const [openAlertGenerateIFSDialog, setOpenAlertGenerateIFSDialog] =
    useState(false);

  const [openGenerateLoadingDialog, setOpenGenerateLoadingDialog] =
    useState(false);

  const {
    data: ifs,
    isFetching: isFetchingIFS,
    isSuccess,
  } = useGetIFS(
    {
      projectId: Number(projectId),
      datasetId,
      orgId: Number(orgId) ?? NaN,
    },
    {
      enabled: !!datasetId && !!orgId && !!projectId,
    }
  );

  const { mutate: generateIfs } = useGenerateIFS({
    onSuccess: async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      if (isSuccess) {
        setOpenGenerateLoadingDialog(false);
      }
    },
  });

  if (!isFetchedDatasetSuccessfully) {
    return null;
  }

  if (isFetchingDataset) {
    return <div>Loading dataset...</div>;
  }

  const { name, description, configuration, owner, is_ifs_schema_outdated } =
    dataset;

  const startMonthOrWeek = configuration.start_period.split('-')[0];
  const endMonthOrWeek = configuration.end_period.split('-')[0];

  const startMonthOrWeekLabel =
    configuration.granularity === 'monthly'
      ? monthList.find((m) => m.value === startMonthOrWeek)?.label
      : weekList.find((w) => w.value === startMonthOrWeek)?.label;

  const endMonthOrWeekLabel =
    configuration.granularity === 'monthly'
      ? monthList.find((m) => m.value === endMonthOrWeek)?.label
      : weekList.find((w) => w.value === endMonthOrWeek)?.label;

  const datasetData = formatDatasetConfig(datasetId, dataset);

  const isAuthorized = project?.role === 'project_owner';
  const isGuestProject = project?.is_guest_project;

  return (
    <div className="space-y-6 bg-white">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Dataset Information</h1>
        {isAuthorized && !isGuestProject && (
          <CreateDatasetDialog
            defaultValues={datasetData}
            trigger={{
              label: 'Edit',
              className:
                'px-4 transition bg-transparent border border-[#B8341B] text-[#B8341B] hover:bg-[#B8341B] hover:text-white',
            }}
          />
        )}
      </div>
      <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
        <div className="flex">
          <p className="flex-grow">Dataset Name:</p>
          <p className="w-[89%] font-bold">{name}</p>
        </div>
        <div className="flex">
          <p className="flex-grow">Description:</p>
          <p className="w-[89%] font-bold">{description}</p>
        </div>
        <div className="flex">
          <p className="w-[120px]">Last modified:</p>
          <p className="w-[89%] font-bold">
            {format(new Date(configuration.updated_at), 'Y/MM/dd h:mm aaa')}
          </p>
        </div>
        <div className="flex">
          <p className="w-[120px]">Owner:</p>
          <p className="w-[89%] font-bold">{owner}</p>
        </div>
      </div>
      <div className="flex items-center gap-3 py-4">
        <div className="flex items-center gap-2">
          <h2 className="text-nowrap text-xl font-bold">
            IFS (Input File Set) Parameters
          </h2>
        </div>
        <SectionLine className="bg-black" />
      </div>
      <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-white px-6 py-4">
        <h2 className="text-xl font-bold">Reporting Period</h2>
        <div className="flex gap-6">
          <p className="capitalize">
            Granularity:{' '}
            <span className="font-bold">{configuration.granularity}</span>
          </p>
          <p>
            Duration:{' '}
            <span className="font-bold">{configuration.duration}</span>
          </p>
          <p>
            Start:{' '}
            <span className="font-bold">
              {(startMonthOrWeekLabel ? `${startMonthOrWeekLabel}-` : '') +
                configuration.start_period.split('-')[
                  configuration.start_period.split('-').length - 1
                ]}
            </span>
          </p>
          <p>
            End:{' '}
            <span className="font-bold">
              {(endMonthOrWeekLabel ? `${endMonthOrWeekLabel}-` : '') +
                configuration.end_period.split('-')[
                  configuration.end_period.split('-').length - 1
                ]}
            </span>
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-white px-6 py-4">
        <h2 className="text-xl font-bold">Aircraft and Airports</h2>
        <table className="border-separate border-spacing-0 rounded-[4px] border  border-[#E4E7EC]">
          {aircrafts?.map((a: { name: string; airports: string[] }) => (
            <tr key={name}>
              <td className="w-[5%] border-b border-r px-6 py-2 last:border-b-0">
                {a.name}
              </td>
              <td className="border-b px-6 py-2">
                {(a.airports as string[])?.join(', ')}
              </td>
            </tr>
          ))}
        </table>
      </div>
      <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-white px-6 py-4">
        <h2 className="text-xl font-bold">Labor Group and Positions</h2>
        <table className="border-separate border-spacing-0 rounded-[4px] border  border-[#E4E7EC]">
          <tr>
            <td className="w-[5%] border-r px-6 py-2 capitalize">
              {configuration.labor_group}
            </td>
            <td className="px-6 py-2">
              {configuration.positions
                .map((p: { name: string }) => transformToLabel(p.name))
                .join(', ')}
            </td>
          </tr>
        </table>
        <p>
          Retirement Age:{' '}
          <span className="font-bold">{configuration.retirement_age}</span>
        </p>
      </div>
      <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-white px-6 py-4">
        <h2 className="text-xl font-bold">Years of Service</h2>
        <p>
          Years of Service:{' '}
          <span className="font-bold">{configuration.years_of_service}</span>
        </p>
      </div>
      {!isGuestProject && (
        <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-white px-6 py-4">
          <div className="flex justify-between">
            <h2 className="text-xl font-bold">IFS (Input File Set) Status</h2>
            {isAuthorized && (
              <LoadingButton
                loading={isFetchingIFS}
                onClick={() => {
                  if (is_ifs_schema_outdated) {
                    setOpenAlertGenerateIFSDialog(true);
                    return;
                  }

                  setOpenConfirmGenerateIFSDialog(true);
                }}
                variant="contained"
                style={{
                  fontSize: '14px',
                  backgroundColor: '#B8341B',
                  color: '#FFF',
                }}
              >
                Generate
              </LoadingButton>
            )}
          </div>
          {ifs && !!Object.keys(ifs).length && (
            <IFSTable
              dataset={dataset}
              ifs={{
                ...ifs,
                files: ifs.files,
              }}
            />
          )}
          <ConfirmGenerateIFSDialog
            open={openConfirmGenerateIFSDialog}
            handleOnClose={() => {
              setOpenConfirmGenerateIFSDialog(false);
            }}
            handleOnSubmit={() => {
              setOpenGenerateLoadingDialog(true);
              setOpenConfirmGenerateIFSDialog(false);

              generateIfs({
                projectId: Number(projectId),
                datasetId,
                orgId: Number(orgId) ?? NaN,
              });
            }}
          />
          <GenerateLoadingDialog
            title="Generating"
            open={openGenerateLoadingDialog}
          />
          <GenerateNoChangeWarningDialog
            open={openAlertGenerateIFSDialog}
            handleOnClose={() => {
              setOpenAlertGenerateIFSDialog(false);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectViewDataset;
