import React from 'react';

import { Search } from 'lucide-react';

import cn from '../utils/cn';

type SearchInputProps = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

const SearchInput = ({ className }: SearchInputProps) => (
  <label
    htmlFor="search"
    className={cn(
      'flex w-full items-center gap-2 rounded-full border border-[#CCC] px-3 py-2 bg-white hover:border-[#333] transition',
      className
    )}
  >
    <Search
      width={20}
      height={20}
      className={cn('text-[#666666]', className)}
    />
    <input
      id="search"
      placeholder="Search"
      className={cn(
        'text-sm font-semibold placeholder-[#666] outline-none bg-white',
        className
      )}
    />
  </label>
);

export default SearchInput;
