import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  createDataset,
  deleteDataset,
  getDatasets,
  getOrganizations,
  updateDataset,
} from '../api';
import {
  type CreateDatasetInput,
  type DeleteDatasetInput,
  getDatasetById,
  type GetDatasetByIdInput,
  type UpdateDatasetInput,
} from '../api/datasets';
import { type TypeDataset, type TypeOrganization } from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

const createDatasetFn = async (input: CreateDatasetInput) => {
  const {
    data,
  }: AxiosResponse<{
    id: number;
  }> = await createDataset(input);

  return data;
};

const getDatasetsFn = async (id: number) => {
  const { data: organizations }: AxiosResponse<TypeOrganization[]> =
    await getOrganizations();

  const { data }: AxiosResponse<TypeDataset[]> = await getDatasets({
    projectId: id,
    orgId: organizations?.[0].id ?? NaN,
  });

  const result = data.sort((p1: TypeDataset, p2: TypeDataset) => {
    if (new Date(p1.created_at) > new Date(p2.created_at)) {
      return -1;
    } else {
      return 1;
    }
  });

  return result;
};

export const useDeleteDataset = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteDataset'],
    mutationFn: async (input: DeleteDatasetInput) => {
      const { data }: AxiosResponse = await deleteDataset(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getDatasets'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useUpdateDataset = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeDataset>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateDataset'],
    mutationFn: async (input: UpdateDatasetInput) => {
      const { data }: AxiosResponse<TypeDataset> = await updateDataset(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getDatasets'],
      });
      queryClient.refetchQueries({
        queryKey: ['getDatasetById'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreateDataset = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<ReturnType<typeof createDatasetFn>>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['createDatasetFn'],
    mutationFn: (input: CreateDatasetInput) => createDatasetFn(input),
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getDatasets'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useGetDatasets = (
  id: number,
  opts?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getDatasetsFn>>,
      DefaultQueryError
    >
  >
) =>
  useQuery({
    queryKey: ['getDatasets', id],
    queryFn: () => getDatasetsFn(id),
    ...opts,
  });

export const useGetDatasetById = (
  input: GetDatasetByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeDataset>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getDatasetById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDataset> = await getDatasetById(input);

      return data;
    },
    ...opts,
  });
