import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, IconButton, Stack } from '@mui/material';
import { CircleX } from 'lucide-react';

import FormInput from '../../FormInput';

type AircraftData = {
  aircraft: string;
  airports: string[];
};

type EditAircraftFormProps = {
  defaultValues: AircraftData;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  handlePrimaryAction: (data: AircraftData) => void;
  handleSecondaryAction?: () => void;
};

const EditAircraftForm = ({
  defaultValues,
  handlePrimaryAction,
  handleSecondaryAction,
}: EditAircraftFormProps) => {
  const [airports, setAirports] = useState<string[]>([]);

  const form = useForm({
    defaultValues: {
      aircraft: defaultValues?.aircraft,
      airport: '',
    },
  });

  const {
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  useEffect(() => {
    if (!defaultValues) {
      return;
    }

    reset({
      aircraft: defaultValues.aircraft,
    });

    setAirports(defaultValues.airports.filter((a) => a.length > 0) ?? []);
  }, [defaultValues]);

  const onSubmit: SubmitHandler<any> = (_) => {
    const aircraft = watch('aircraft');
    const airport = watch('airport');

    if (!aircraft) {
      setError('aircraft', {
        message: 'This field is required.',
      });
      return;
    }

    if (airports.length === 0 && !airport.length) {
      setError('airport', {
        message: 'This field is required.',
      });
      return;
    }

    handlePrimaryAction({
      aircraft,
      airports: airport.length > 0 ? [...airports, airport] : airports,
    });
  };

  const handleAddAirport = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const value = watch('airport');

    if (value.length === 0) {
      setError('airport', {
        message: 'This field is required.',
      });
      return;
    }

    if (airports.includes(value)) {
      setError('airport', {
        message: 'This item is already added.',
      });
      return;
    }

    setAirports([...airports, value]);
    form.setValue('airport', '');
  };

  const handleClearAirport = (item: string) => {
    setAirports(airports.filter((rec) => rec !== item));
  };

  return (
    <FormProvider {...form}>
      <form className="space-y-6">
        <FormInput
          fullWidth
          name="aircraft"
          label="Aircraft"
          labelClassName="text-[#2196F3] font-semibold"
          helperText={errors['aircraft']?.message as string}
        />
        <FormInput
          fullWidth
          required={airports.length === 0}
          label="Airport"
          helperText={errors['airport']?.message as string}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ',') {
              handleAddAirport(e);
            }
          }}
          labelClassName="text-[#2196F3] font-semibold"
          name="airport"
          endAdornment={
            <IconButton onClick={handleAddAirport} aria-label="add">
              <Add
                style={{
                  width: '16px',
                  height: '16px',
                  color: '#666666',
                }}
              />
            </IconButton>
          }
        />
        <div>
          <Stack
            direction="row"
            style={{
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {airports.map((item, index) => (
              <Chip
                key={index}
                label={
                  <div className="flex items-center gap-1">
                    {item}
                    <CircleX
                      onClick={() => {
                        handleClearAirport(item);
                      }}
                      width={20}
                      height={20}
                    />
                  </div>
                }
                style={{
                  fontSize: '16px',
                  backgroundColor: airports.includes(item)
                    ? '#2196F3'
                    : '#F8F8F8',
                  color: airports.includes(item) ? '#FFF' : '#666',
                }}
              />
            ))}
          </Stack>
        </div>
        <div className="flex-end flex justify-end gap-3">
          <Button
            onClick={handleSecondaryAction}
            variant="outlined"
            tabIndex={3}
            style={{
              color: '#666',
              borderColor: '#B3B3B3',
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={handleSubmit((event) => onSubmit(event))}
            tabIndex={2}
            variant="contained"
            style={{
              backgroundColor: '#2196F3',
              color: '#FFF',
            }}
          >
            Save
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditAircraftForm;
