import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Plus } from 'lucide-react';
import { z } from 'zod';

import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

type CreateScenarioFormDialogProps = {
  title: string;
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: () => void;
  };
  categories: {
    label: string;
    value: string;
  }[];
  onSubmit: (data: AddExpenseFormSchemaType) => void;
  defaultValues?: {
    name: string;
    category?: string;
    description?: string;
    isCustom?: boolean;
  };
};

const CreateScenarioSchema = z.object({
  name: z.string().trim().min(1, 'Name is required'),
  category: z.string().trim().min(1, 'Category is required'),
  description: z.string().trim().optional(),
  isCustom: z.boolean().optional(),
});

export type AddExpenseFormSchemaType = z.infer<typeof CreateScenarioSchema>;

const AddExpenseFormDialog = ({
  title,
  trigger,
  onSubmit,
  categories,
  defaultValues,
}: CreateScenarioFormDialogProps) => {
  const [open, setOpen] = useState(false);

  const form = useForm<AddExpenseFormSchemaType>({
    resolver: zodResolver(CreateScenarioSchema),
    defaultValues,
  });

  const { formState, handleSubmit, register, reset } = form;

  const { isSubmitting } = formState;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleOpenDialog = () => {
    trigger?.onClick?.();
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    reset();
  };

  const submit: SubmitHandler<AddExpenseFormSchemaType> = (data) => {
    onSubmit(data);
    setOpen(false);
  };

  return (
    <>
      <button
        onClick={handleOpenDialog}
        className="flex items-center text-start text-[#B8341B]"
        type="button"
        style={trigger?.style}
      >
        {trigger ? (
          trigger.label
        ) : (
          <div className="flex cursor-pointer items-center gap-1">
            <Plus width={20} height={20} />
            <label className="cursor-pointer">Add</label>
          </div>
        )}
      </button>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="px-6 pt-2 text-2xl font-bold">{title}</h2>
        </DialogTitle>
        <DialogContent>
          <div className="z-50 px-6">
            <FormProvider {...form}>
              <form onSubmit={handleSubmit(submit)} className="mb-2 space-y-6">
                <div className="space-y-6">
                  <FormInput label="Name" name="name" fullWidth />
                  <FormSelect
                    name="category"
                    label="Category"
                    fullWidth
                    options={categories}
                  />
                  <FormInput label="Description" name="description" fullWidth />
                  <label htmlFor="isCustom" className="flex items-center gap-2">
                    <Checkbox
                      id="isCustom"
                      className="!p-0"
                      {...register('isCustom')}
                    />
                    <p>Custom Expense File Set included</p>
                  </label>
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialog}
                    variant="outlined"
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    {defaultValues ? 'Save' : 'Create'}
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddExpenseFormDialog;
