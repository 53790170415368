import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import BaseTable from '../../common/BaseTable';
import { useGetDatasets } from '../../hooks';

export type ProjectRow = {
  id: number;
  name: string;
  description: string | undefined;
  modified: string | undefined;
};

const defaultData: ProjectRow[] = [];

const DatasetsTable = () => {
  const { project_id: projectId } = useParams();
  const [data, setData] = useState<ProjectRow[]>(defaultData);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { data: datasets, isSuccess } = useGetDatasets(
    Number(projectId) ?? NaN,
    {
      enabled: !!projectId,
    }
  );

  const handleSelect = (id: number) => {
    const searchParams = new URLSearchParams();

    searchParams.set('dataset_id', id.toString());

    navigate(`${pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    if (isSuccess) {
      setData(
        datasets.map((dataset) => ({
          id: dataset.id,
          name: dataset.name,
          description: dataset.description,
          modified: dataset.updated_at,
        }))
      );
    }
  }, [datasets, isSuccess]);

  return <BaseTable data={data} handleSelect={handleSelect} type="dataset" />;
};

export default DatasetsTable;
