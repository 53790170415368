import React from 'react';

import { Button, Dialog } from '@mui/material';
import { TriangleAlert } from 'lucide-react';

type GenerateNoChangeWarningDialogProps = {
  open: boolean;
  handleOnClose: () => void;
};

const GenerateNoChangeWarningDialog = ({
  open,
  handleOnClose,
}: GenerateNoChangeWarningDialogProps) => (
  <Dialog
    open={open}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <div className="flex flex-col gap-6 p-6">
      <div className="flex items-center gap-2 text-xl font-bold">
        <TriangleAlert />
        <p>Warning: IFS Generation Not Required</p>
      </div>
      <div className="text-base text-[#666]">
        The conditions necessary for IFS generation have not been met and is not
        necessary.
      </div>
      <div className="flex justify-end gap-3">
        <Button
          onClick={handleOnClose}
          tabIndex={2}
          variant="contained"
          style={{
            backgroundColor: '#B8341B',
            color: '#FFF',
          }}
        >
          OK
        </Button>
      </div>
    </div>
  </Dialog>
);

export default GenerateNoChangeWarningDialog;
