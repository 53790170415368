import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import {
  createOrganization,
  getOrganizationById,
  getOrganizations,
} from '../api';
import {
  acceptOrgReceivedInvitation,
  type AcceptOrgReceivedInvitationInput,
  approveSharedInvitation,
  type ApproveSharedRequestInput,
  createMember,
  type CreateOrganizationInput,
  type CreateOrganizationMemberInput,
  deleteMemberById,
  type DeleteMemberByIdInput,
  deleteOrgInvitationById,
  type DeleteOrgInvitationByIdInput,
  disapproveSharedInvitation,
  type DisapproveSharedRequestInput,
  getOrganizationInvitations,
  getOrgInvitationById,
  type GetOrgInvitationByIdInput,
  getOrgMemberById,
  type GetOrgMemberByIdInput,
  getOrgMembers,
  getSharedRequestById,
  type GetSharedRequestByIdInput,
  getSharedRequests,
  inviteMember,
  type InviteMemberInput,
  rejectOrgReceivedInvitation,
  type RejectOrgReceivedInvitationInput,
  resendInvitation,
  type ResendInvitationInput,
  updateInvitation,
  updateMember,
  type UpdateOrganizationInvitationInput,
  type UpdateOrganizationMemberInput,
  verifyInvitation,
  type VerifyInvitationInput,
} from '../api/organization';
import {
  type TypeInvitation,
  type TypeMember,
  type TypeOrganization,
  type TypeProject,
  type TypeSharedRequest,
  type TypeUser,
} from '../common/dataTypes';
import { type ProjectRole, type Role } from '../types';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export type Project = {
  id: number;
  member_count: number;
  created_at: string;
  updated_at: string;
  role: ProjectRole;
  name: string;
  description: string;
  organization: number;
};

export type Invitation = {
  id: number;
  email: string;
  role: Role;
  expire_at: string;
  organization: number;
  is_new_user: boolean;
  invited_by: {
    user: {
      id: number;
      username: string;
      email: string;
    };
  };
  updated_at: string;
  projects: Project[];
};

type InviteMemberResponse = {
  id: number;
  name: string;
  description: string;
  member_count: number;
  project_count: number;
  members: TypeUser[];
  projects: TypeProject[];
};

export const useGetOrganizations = (
  opts?: Partial<
    UseQueryOptions<Awaited<TypeOrganization[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getOrganizationsFn'],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeOrganization[]> =
        await getOrganizations();

      const result = data.sort((p1: TypeOrganization, p2: TypeOrganization) => {
        if (new Date(p1.created_at) > new Date(p2.created_at)) {
          return -1;
        } else {
          return 1;
        }
      });

      return result;
    },
    ...opts,
  });

export const useGetOrgInvitations = (
  id: number,
  opts?: Partial<UseQueryOptions<Awaited<Invitation[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgInvitations ', id],
    queryFn: async () => {
      const { data }: AxiosResponse<Invitation[]> =
        await getOrganizationInvitations(id);

      return data;
    },
    ...opts,
  });

export const useGetOrgInvitationById = (
  input: GetOrgInvitationByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<Invitation>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgInvitationById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<Invitation> =
        await getOrgInvitationById(input);

      return data;
    },
    ...opts,
  });

export const useGetOrgMemberById = (
  input: GetOrgMemberByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeMember>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgMemberById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeMember> = await getOrgMemberById(input);

      return data;
    },
    ...opts,
  });

export const useInviteOrganizationMember = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<InviteMemberResponse>,
      DefaultMutationError,
      any,
      any
    >
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['inviteMember'],
    mutationFn: async (input: InviteMemberInput) => {
      const { data }: AxiosResponse<InviteMemberResponse> =
        await inviteMember(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrgInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useCreateOrganizationMember = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<InviteMemberResponse>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['createMember'],
    mutationFn: async (input: CreateOrganizationMemberInput) => {
      const { data }: AxiosResponse<InviteMemberResponse> =
        await createMember(input);

      return data;
    },
    ...opts,
  });

export const useVerifyInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<Invitation>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['verifyInvitation'],
    mutationFn: async (input: VerifyInvitationInput) => {
      const { data }: AxiosResponse<Invitation> = await verifyInvitation(input);

      return data;
    },
    ...opts,
  });

export const useResendInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['resendInvitation'],
    mutationFn: async (input: ResendInvitationInput) => {
      const { data }: AxiosResponse<AxiosResponse> =
        await resendInvitation(input);

      return data;
    },
    ...opts,
  });

export const useUpdateOrgMember = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeMember>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['updateOrgMember'],
    mutationFn: async (input: UpdateOrganizationMemberInput) => {
      const { data }: AxiosResponse<TypeMember> = await updateMember(input);

      return data;
    },
    ...opts,
  });

export const useUpdateOrgInvitation = (
  opts?: Partial<
    UseMutationOptions<Awaited<TypeInvitation>, DefaultMutationError, any, any>
  >
) =>
  useMutation({
    mutationKey: ['updateOrgInvitation'],
    mutationFn: async (input: UpdateOrganizationInvitationInput) => {
      const { data }: AxiosResponse<TypeInvitation> =
        await updateInvitation(input);

      return data;
    },
    ...opts,
  });

export const useDeleteInvitationById = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteInvitationById'],
    mutationFn: async (input: DeleteOrgInvitationByIdInput) => {
      const { data }: AxiosResponse = await deleteOrgInvitationById(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrgInvitations'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useDeleteMemberById = (
  opts?: Partial<
    UseMutationOptions<Awaited<AxiosResponse>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['deleteMemberById'],
    mutationFn: async (input: DeleteMemberByIdInput) => {
      const { data }: AxiosResponse = await deleteMemberById(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getOrgMembers', variables.id],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};

export const useCreateOrganization = (
  opts?: Partial<
    UseMutationOptions<
      Awaited<{
        id: string;
      }>,
      DefaultMutationError,
      any,
      any
    >
  >
) =>
  useMutation({
    mutationKey: ['createOrganizationFn'],
    mutationFn: async (input: CreateOrganizationInput) => {
      const {
        data: project,
      }: AxiosResponse<{
        id: string;
      }> = await createOrganization(input);

      return project;
    },
    ...opts,
  });

export const useGetOrganizationById = (
  id: number,
  opts?: Partial<UseQueryOptions<Awaited<TypeOrganization>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrganizationByIdFn', id],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeOrganization> =
        await getOrganizationById(id);

      return data;
    },
    ...opts,
  });

export const useGetOrgMembers = (
  id: number,
  opts?: Partial<UseQueryOptions<Awaited<TypeMember[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getOrgMembers ', id],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeMember[]> = await getOrgMembers(id);

      return data;
    },
    ...opts,
  });

export const useGetOrgSharedRequests = (
  id: number,
  opts?: Partial<
    UseQueryOptions<Awaited<TypeSharedRequest[]>, DefaultQueryError>
  >
) =>
  useQuery({
    queryKey: ['getSharedRequests', id],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedRequest[]> =
        await getSharedRequests(id);

      return data;
    },
    ...opts,
  });

export const useGetOrgSharedRequestById = (
  input: GetSharedRequestByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeSharedRequest>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getSharedRequestById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeSharedRequest> =
        await getSharedRequestById(input);

      return data;
    },
    ...opts,
  });

export const useAcceptReceivedInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['acceptReceivedInvitation'],
    mutationFn: async (input: AcceptOrgReceivedInvitationInput) => {
      const { data }: AxiosResponse = await acceptOrgReceivedInvitation(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getReceivedSharingInvitations'],
      });
      queryClient.refetchQueries({
        queryKey: ['getProjects'],
      });
    },
  });
};

export const useRejectReceivedInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['rejectReceivedInvitation'],
    mutationFn: async (input: RejectOrgReceivedInvitationInput) => {
      const { data }: AxiosResponse = await rejectOrgReceivedInvitation(input);

      return data;
    },
    onSuccess() {
      queryClient.refetchQueries({
        queryKey: ['getReceivedSharingInvitations'],
      });
    },
  });
};

export const useApproveSharedInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['approveSharedInvitation'],
    mutationFn: async (input: ApproveSharedRequestInput) => {
      const { data }: AxiosResponse = await approveSharedInvitation(input);

      return data;
    },
    onSuccess() {
      queryClient.refetchQueries({
        queryKey: ['getReceivedSharingInvitations'],
      });
      queryClient.refetchQueries({
        queryKey: ['getSharedRequests'],
      });
      queryClient.refetchQueries({
        queryKey: ['getSharedRequestById'],
      });
    },
  });
};

export const useDisapproveSharedInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['disapproveSharedInvitation'],
    mutationFn: async (input: DisapproveSharedRequestInput) => {
      const { data }: AxiosResponse = await disapproveSharedInvitation(input);

      return data;
    },
    onSuccess() {
      queryClient.refetchQueries({
        queryKey: ['getReceivedSharingInvitations'],
      });
      queryClient.refetchQueries({
        queryKey: ['getSharedRequests'],
      });
      queryClient.refetchQueries({
        queryKey: ['getSharedRequestById'],
      });
    },
  });
};
