import React, { useState } from 'react';

import { styled, Tab as MuiTab, Tabs as MuiTabs } from '@mui/material';

import { useCurrentOrganization } from '../../utils/helpers';
import SearchInput from '../SearchInput';

import PendingRequestsTable from './admin/PendingRequestsTable';
import AdminReceivedInvitationsTable from './admin/ReceivedInvitationsTable';
import OwnerReceivedInvitationsTable from './owner/ReceivedInvitationsTable';
import SentRequestsTable from './owner/SentRequestsTable';
import SentInvitationsTable from './SentInvitationsTable';

type StyledTabsProps = {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

type StyledTabProps = {
  label: string;
  id: string;
};

const tabs = () => {
  const currentOrg = useCurrentOrganization();

  if (currentOrg?.role === 'organization_admin') {
    return ['Sent Invitations', 'Received Invitations', 'Pending Requests'];
  }

  return ['Sent Invitations', 'Received Invitations', 'Sent Requests'];
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  textTransform: 'capitalize',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    backgroundColor: '#B8341B',
  },
  '& .MuiTabs-flexContainer': {
    gap: '12px',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  minWidth: 'fit-content !important',
  fontFamily: 'Poppins, sans-serif',
  padding: '4px 16px !important',
  textTransform: 'none',
  transition: 'width 2s',
  minHeight: '40px !important',
  transitionProperty:
    'color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
  transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  transitionDuration: '250ms',
  '&.MuiButtonBase-root': {
    backgroundColor: '#F5F5F5',
    borderRadius: '100px',
    width: 'fit-content !important',
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: 'black',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={value} {...other}>
      {value === index && children}
    </div>
  );
};

const SharedModelVault = () => {
  const currentOrg = useCurrentOrganization();

  const [index, setIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setIndex(newValue);
  };

  return (
    <div className="flex flex-col gap-6 px-12 py-6">
      <h1 className="text-3xl font-bold">Shared Model Vault</h1>
      <div className="flex gap-3">
        <div className="w-[300px]">
          <SearchInput />
        </div>
        <Tabs value={index} onChange={handleChange}>
          {tabs().map((tab, i) => (
            <Tab id={i.toString()} key={tab} label={tab} />
          ))}
        </Tabs>
      </div>
      <div>
        <TabPanel index="0" value={index.toString()}>
          <SentInvitationsTable />
        </TabPanel>
        <TabPanel index="1" value={index.toString()}>
          {currentOrg?.role === 'organization_admin' ? (
            <AdminReceivedInvitationsTable />
          ) : (
            <OwnerReceivedInvitationsTable />
          )}
        </TabPanel>
        <TabPanel index="2" value={index.toString()}>
          {currentOrg?.role === 'organization_admin' ? (
            <PendingRequestsTable />
          ) : (
            <SentRequestsTable />
          )}
        </TabPanel>
      </div>
    </div>
  );
};

export default SharedModelVault;
