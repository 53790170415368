import React, { useState } from 'react';

import { Button, Dialog } from '@mui/material';
import { CircleX, Trash2 } from 'lucide-react';

import {
  useDeleteInvitationById,
  useDeleteMemberById,
  useGetOrganizations,
} from '../../hooks/useOrganizationHook';

type ConfirmDeleteUserDialogProps = {
  users: {
    avatarImage?: string;
    email: string;
    id: number;
    name?: string | undefined | null;
    type?: 'member' | 'invitation';
  }[];
};

const ConfirmDeleteUserDialog = ({ users }: ConfirmDeleteUserDialogProps) => {
  const [open, setOpen] = useState(false);

  const { data: organizations } = useGetOrganizations();

  const organization = organizations?.[0];

  const { mutate: deleteMemberById } = useDeleteMemberById({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const { mutate: deleteInvitationById } = useDeleteInvitationById({
    onSuccess: () => {
      setOpen(false);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    if (!organization) {
      return;
    }

    users.forEach((u) => {
      if (u.type === 'member') {
        deleteMemberById({
          id: organization.id,
          userId: u.id,
        });
      } else if (u.type === 'invitation') {
        deleteInvitationById({
          id: organization.id,
          invitationId: u.id,
        });
      }
    });
  };

  return (
    <>
      <button type="button" onClick={handleOpen}>
        <Trash2 color="#666666" width={20} height={20} />
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <div className="flex flex-col gap-6 p-6">
          <h2 className="text-xl font-bold">
            Are you sure you want to delete these users?
          </h2>
          <ul className="text-base text-[#666]">
            {users.map(
              (u) =>
                u && (
                  <li className="flex items-center justify-between border-b border-[#E4E7EC] py-2">
                    <p>{u.email}</p>
                    <CircleX
                      color="#999999"
                      width={20}
                      height={20}
                      className="cursor-pointer"
                    />
                  </li>
                )
            )}
          </ul>
          <div className="flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              tabIndex={3}
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              tabIndex={2}
              variant="contained"
              style={{
                backgroundColor: '#B8341B',
                color: '#FFF',
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ConfirmDeleteUserDialog;
