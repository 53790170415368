import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { Button } from '@mui/material';
import {
  type ColumnsStylesInterface,
  DataGridPremium,
  type GridColDef,
  type GridColumnGroupingModel,
  type GridExcelExportOptions,
  type GridPinnedColumns,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ArrowDownToLine } from 'lucide-react';

import {
  getColsFromRow,
  getGroupByColumns,
  getSupportedPivotDisplayModes,
} from '../common/dataTableFormatter';
import FormSelect from '../components/FormSelect';
import VectorsSection from '../components/scenario/VectorsSection';
import CreateVectorFormDialog from '../components/vector/CreateVectorFormDialog';
import { useGetDatasetById } from '../hooks';
import { useGetDataTableById } from '../hooks/useDataTablesHook';
import {
  useGenerateScenarioData,
  useGetScenarioById,
  useGetScenariosByDataTableId,
} from '../hooks/useScenariosHook';
import { type Granularity } from '../types';
import { useCurrentProject } from '../utils/helpers';

const ProjectViewScenario = () => {
  const currentProject = useCurrentProject();
  const { org_id, project_id } = useParams();

  const apiRef = useGridApiRef();

  const [searchParams] = useSearchParams();

  const [dataTableCols, setDataTableCols] = useState<GridColDef[]>([]);
  const [dataTableRows, setDataTableRows] = useState<Object[]>([]);
  const [aggregate, setAggregate] = useState<string>('');

  const [openDialogCreateVector, setOpenDialogCreateVector] = useState(false);

  const [dataTableColumnGroupingModel, setDataTableColumnGroupingModel] =
    useState<GridColumnGroupingModel>([]);

  const [dataTablePinnedColumns, setDataTablePinnedColumns] =
    useState<GridPinnedColumns>();

  const scenarioId = searchParams.get('scenario_id');
  const datasetId = searchParams.get('dataset_id');
  const dataTableId = searchParams.get('dataTable_id');

  const { data: scenarios } = useGetScenariosByDataTableId(
    {
      dataTableId: Number(dataTableId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id) ?? NaN,
    },
    {
      enabled: !!dataTableId && !!org_id && !!project_id,
    }
  );

  const baseScenario = scenarios?.find((m) => m.is_base);

  const { data: scenario } = useGetScenarioById(
    {
      scenarioId: Number(scenarioId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!scenarioId && !!Number(org_id) && !!project_id,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!org_id && !!project_id,
    }
  );

  const { data: dataTable } = useGetDataTableById(
    {
      dataTableId: Number(dataTableId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!dataTableId && !!org_id && !!project_id,
    }
  );

  const {
    data: baseScenarioData,
    mutate: generateBaseScenarioData,
    isPending: isGeneratingBaseScenarioData,
  } = useGenerateScenarioData({
    onError: (error) => {
      console.error(error);
    },
  });

  const { mutate: generateScenarioData, isPending: isGenerateScenarioData } =
    useGenerateScenarioData({
      onSuccess: (data) => {
        const colSpec: any[] = [];

        if (!scenario || !dataTable?.schema) {
          return;
        }

        const availableDisplayModes = getSupportedPivotDisplayModes(
          dataTable.schema
        );

        const granularity = watch('timeline') as Granularity;

        const { cols, colGroupingModel } = getColsFromRow(
          data[0],
          dataTable?.schema,
          granularity,
          baseScenarioData ?? (data as any[]),
          colSpec
        );

        setDataTableCols(cols);
        setDataTableRows(data);

        let pinnedCols: string[] = [];

        if (availableDisplayModes.length > 0) {
          pinnedCols = getGroupByColumns(dataTable.schema) ?? [];
        }

        setDataTableColumnGroupingModel(colGroupingModel);
        setDataTablePinnedColumns({ left: pinnedCols });
      },
      onError: (error) => {
        console.error(error);
      },
    });

  const aggColumns = useMemo(
    () =>
      dataTable?.schema.pivot?.aggregate_columns.map((c) => ({
        label: c,
        value: c,
      })) ?? [],
    [dataTable]
  );

  const form = useForm({
    defaultValues: {
      timeline: 'yearly',
      aggregateColumn: '',
    },
  });

  const { watch, setValue } = form;

  const selectedColumn = watch('aggregateColumn');
  const selectedTimeline = watch('timeline');

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};
    dataTableCols.forEach((colDef) => {
      colStyles[colDef.field] = {
        numFmt: '#,##0.00',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: getExportFileName(),
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  const getExportFileName = () => {
    const datasetName = dataset ? dataset.name : 'dataset_unknown';

    const dataTableName = dataTable ? dataTable.name : 'table_unknown';

    const scenarioName = scenario ? scenario.name : 'scenario_unknown';

    return datasetName + '_' + dataTableName + '_' + scenarioName;
  };

  const handleGenerateScenarioData = useCallback(() => {
    if (!selectedColumn || !selectedTimeline || !dataTable || !org_id) {
      return;
    }

    const requestBody = dataTable.schema.pivot
      ? {
          pivot: {
            index_fields: dataTable.schema.pivot?.groupby_columns ?? [],
            timeline_type: selectedTimeline as Granularity,
            agg_field: selectedColumn,
          },
        }
      : {};

    generateScenarioData({
      scenarioId: Number(scenarioId),
      orgId: Number(org_id),
      projectId: Number(project_id),
      data: requestBody,
    });
  }, [
    selectedColumn,
    selectedTimeline,
    dataTable,
    scenarioId,
    dataTableId,
    aggColumns,
    org_id,
    baseScenarioData,
  ]);

  useEffect(() => {
    if (
      !selectedColumn ||
      !selectedTimeline ||
      !dataTable ||
      !org_id ||
      !baseScenario
    ) {
      return;
    }

    const requestBody = dataTable.schema.pivot
      ? {
          pivot: {
            index_fields: dataTable.schema.pivot.groupby_columns ?? [],
            timeline_type: selectedTimeline as Granularity,
            agg_field: selectedColumn,
          },
        }
      : {};

    generateBaseScenarioData({
      scenarioId: Number(baseScenario?.id),
      orgId: Number(org_id),
      projectId: Number(project_id),
      data: requestBody,
    });
  }, [scenarioId, org_id, project_id, baseScenario, selectedColumn, dataTable]);

  useEffect(() => {
    if (aggColumns.length > 0) {
      setAggregate((aggColumns[0] as { value: string }).value);
      setValue('aggregateColumn', aggColumns[0].value, {
        shouldDirty: false,
        shouldValidate: false,
      });
    }
  }, [aggColumns]);

  useEffect(() => {
    handleGenerateScenarioData();
  }, [
    selectedColumn,
    scenarioId,
    baseScenarioData,
    selectedTimeline,
    dataTable,
  ]);

  const isGuestProject = currentProject?.is_guest_project;

  return (
    <div className="flex flex-col space-y-6">
      {!scenario?.is_base && (
        <div className="flex flex-col gap-3 rounded-[4px] border border-[#E4E7EC] bg-[#FFF] px-6 py-4">
          <h2 className="text-xl font-bold">Description</h2>
          <p>{scenario?.description}</p>
        </div>
      )}
      {!scenario?.is_base && !isGuestProject && (
        <div className="space-y-3">
          <div className="flex justify-between">
            <h2 className="text-3xl font-bold">Vectors</h2>
            <CreateVectorFormDialog
              open={openDialogCreateVector}
              title="Add new vector"
              handleCloseDialog={() => {
                setOpenDialogCreateVector(false);
              }}
              handleOpenDialog={() => {
                setOpenDialogCreateVector(true);
              }}
            />
          </div>
          <VectorsSection handleActivate={handleGenerateScenarioData} />
        </div>
      )}
      <div className="space-y-2">
        <h2 className="text-3xl font-bold">Data</h2>
        <FormProvider {...form}>
          <form className="flex items-end justify-between">
            <div className="flex w-[25%] gap-3">
              <FormSelect
                name="aggregateColumn"
                fullWidth
                options={aggColumns}
                defaultValue={aggColumns[0]?.value}
                label="Aggregate Column"
              />
            </div>
            <div>
              <Button
                onClick={handleExportToExcel}
                variant="outlined"
                className="space-x-2"
              >
                <ArrowDownToLine />
                <label className="font-semibold"> Export to Excel</label>
              </Button>
            </div>
          </form>
        </FormProvider>
        <div className="h-[380px] py-6">
          <DataGridPremium
            apiRef={apiRef}
            rows={dataTableRows}
            columns={dataTableCols}
            pagination={false}
            disableSelectionOnClick
            loading={isGenerateScenarioData || isGeneratingBaseScenarioData}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={dataTableColumnGroupingModel}
            density="compact"
            pinnedColumns={dataTablePinnedColumns}
            onPinnedColumnsChange={(pinnedColumns) =>
              setDataTablePinnedColumns(pinnedColumns)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectViewScenario;
