import React from 'react';

import { useCurrentProject } from '../../utils/helpers';
import ProjectsFilter from '../LastModifiedFilter';
import SearchInput from '../SearchInput';

import CreateDatasetDialog from './CreateDatasetDialog';
import DatasetsTable from './DatasetsTable';

const DatasetsView = () => {
  const project = useCurrentProject();

  return (
    <div className="flex flex-col gap-6 px-12 py-6">
      <h1 className="text-2xl font-bold">Dataset</h1>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="w-[500px]">
            <SearchInput />
          </div>
          <ProjectsFilter />
        </div>
        {project?.role === 'project_owner' && <CreateDatasetDialog />}
      </div>
      <DatasetsTable />
    </div>
  );
};

export default DatasetsView;
