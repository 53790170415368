import React from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, IconButton, Stack } from '@mui/material';
import { CircleX } from 'lucide-react';

import FormInput from '../FormInput';

type AddFormProps = {
  defaultFormValue?: string[];
  data: string[];
  id: string;
  label?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  setData: (data: string[]) => void;
  handlePrimaryAction: (data: string[]) => void;
  handleSecondaryAction?: () => void;
};

const AddForm = ({
  id,
  label,
  data,
  setData,
  handlePrimaryAction,
  handleSecondaryAction,
  defaultFormValue,
  inputProps,
}: AddFormProps) => {
  const form = useForm({
    defaultValues: {
      [id]: '',
    },
  });

  const {
    handleSubmit,
    setError,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  const onSubmit: SubmitHandler<any> = (_) => {
    const value = watch(id);

    if (defaultFormValue?.includes(value.toLowerCase())) {
      setError(id, {
        message: `${value} is already exist.`,
      });
      return;
    }

    const commaItems = value.split(',').map((item) => item.trim());

    const spaceItems = value.split(' ').map((item) => item.trim());

    const items = Array.from(
      new Set(commaItems.concat(spaceItems).filter((item) => item))
    ).filter(
      (item) =>
        !item.includes(',') &&
        !item.includes(' ') &&
        !defaultFormValue?.includes(item)
    );

    if (data.length === 0 && !value) {
      setError(id, {
        message: 'This field is required.',
      });
      return;
    }

    handlePrimaryAction(value.length ? [...data, ...items] : data);
    setData([]);
  };

  const handleAdd = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const value = form.getValues()[id];

    if (defaultFormValue?.includes(value.toLowerCase())) {
      setError(id, {
        message: `${value} is already exist.`,
      });
      return;
    }

    const commaItems = value.split(',').map((item) => item.trim());

    const spaceItems = value.split(' ').map((item) => item.trim());

    const items = Array.from(
      new Set(commaItems.concat(spaceItems).filter((item) => item))
    ).filter((item) => !item.includes(',') && !item.includes(' '));

    if (value.length === 0) {
      setError(id, {
        message: 'This field is required.',
      });
      return;
    }

    setData(Array.from(new Set([...data, ...items])));
    reset();
    form.setValue(id, '');
  };

  return (
    <FormProvider {...form}>
      <form className="space-y-6">
        <FormInput
          fullWidth
          required={data.length === 0}
          label={label}
          inputProps={{
            ...inputProps,
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAdd(e);
            }
          }}
          helperText={`Press comma (,) or space bar to type more ${label}.`}
          labelClassName="text-[#2196F3] font-semibold"
          name={id}
          endAdornment={
            <IconButton onClick={handleAdd} aria-label="add">
              <Add
                style={{
                  width: '16px',
                  height: '16px',
                  color: '#666666',
                }}
              />
            </IconButton>
          }
        />
        <div>
          <Stack
            direction="row"
            style={{
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {data.map((item, index) => (
              <Chip
                key={index}
                label={
                  <div className="flex items-center gap-[6px]">
                    {item}
                    <CircleX
                      width={20}
                      height={20}
                      className="cursor-pointer"
                      onClick={() => {
                        setData(data.filter((rec) => rec !== item));
                      }}
                    />
                  </div>
                }
                style={{
                  fontSize: '16px',
                  backgroundColor: data.includes(item) ? '#01285F' : '#F8F8F8',
                  color: data.includes(item) ? '#FFF' : '#666',
                }}
              />
            ))}
          </Stack>
        </div>
        <div className="flex-end flex justify-end gap-3">
          <Button
            onClick={handleSecondaryAction}
            variant="outlined"
            tabIndex={3}
            style={{
              color: '#666',
              borderColor: '#B3B3B3',
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={handleSubmit((event) => onSubmit(event))}
            tabIndex={2}
            variant="contained"
            style={{
              backgroundColor: '#2196F3',
              color: '#FFF',
            }}
          >
            Save
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default AddForm;
