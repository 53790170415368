import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Checkbox as MuiCheckbox, styled, Tooltip } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { Copy, Info, Pencil } from 'lucide-react';

import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';
import CreateDatasetDialog from '../components/dataset/CreateDatasetDialog';
import InviteFormDialog from '../components/organizations/InviteFormDialog';
import CreateProjectDialog from '../components/projects/CreateProjectDialog';
import Spinner from '../components/Spinner';
import {
  useCreateDataset,
  useGetDatasetById,
  useGetDatasets,
  useGetProjects,
} from '../hooks';
import Archive from '../icons/Archive';
import Invite from '../icons/Invite';
import cn from '../utils/cn';
import { useCurrentOrganization, useCurrentProject } from '../utils/helpers';

import {
  type TableType,
  type TypeDataset,
  type TypeProject,
} from './dataTypes';
import IconButton from './IconButton';
import { formatDatasetConfig } from './utils';

export type DataRow = {
  id: number;
  name: string;
  description: string | undefined;
  modified: string | undefined;
  actions?: React.ReactNode;
};

type DataTableProps = {
  data: DataRow[];
  type: TableType;
  handleSelect: (id: number) => void;
};

const Checkbox = styled(MuiCheckbox)({
  padding: 0,
});

const columnHelper = createColumnHelper<DataRow>();

const roles = [
  {
    label: 'Project Owner',
    value: 'project_owner',
  },
  {
    label: 'Project Member',
    value: 'project_member',
  },
];

const getCopyDatasetName = ({
  name,
  datasets,
  dataset,
}: {
  datasets: TypeDataset[];
  dataset: TypeDataset;
  name: string;
}) => {
  const getCopyVersion = (str: string) => {
    const match = str.match(/\((\d+)\)$/);
    return match ? match[1] : null;
  };

  const updateCopyVersion = (str: string, newNumber: number) =>
    str.replace(/\((\d+)\)$/, `(${newNumber})`);

  const maxCopyVersion = datasets
    ? Math.max(...datasets.map((d) => Number(getCopyVersion(d.name))))
    : 0;

  const currentVersion = Number(getCopyVersion(name));

  const latestVersionDataset =
    datasets?.find((d) =>
      maxCopyVersion === 0
        ? d.name === name.concat(`(${maxCopyVersion})`)
        : d.name === name
    ) ?? dataset;

  return maxCopyVersion !== 0 && currentVersion !== 0
    ? updateCopyVersion(latestVersionDataset?.name ?? '', maxCopyVersion + 1)
    : name.concat(`(${maxCopyVersion + 1})`);
};

const columns = ({
  handleSelect,
  type,
}: {
  type: 'project' | 'dataset';
  handleSelect: (id: number) => void;
}) => [
  columnHelper.accessor('name', {
    cell: (info) => {
      const { register, watch } = useFormContext();

      const watchData = watch();

      const isSelected = watchData[info.row.original.id];

      return (
        <div className="group flex w-[300px] items-center gap-2 py-3 text-start">
          <Checkbox
            {...register(info.row.original.id.toString())}
            checked={isSelected}
            className={cn('opacity-0 transition group-hover:opacity-100', {
              'opacity-100': isSelected,
            })}
          />
          <p
            onClick={() => handleSelect(info.row.original.id)}
            className="w-full"
          >
            {info.getValue()}
          </p>
        </div>
      );
    },
    header: () => {
      const { watch, setValue } = useFormContext();

      const formProjects = watch();

      const handleOnClick = () => {
        Object.keys(formProjects).forEach((key) => {
          setValue(key, true);
        });
      };

      return (
        <div className="items-centert flex gap-2">
          <Checkbox onClick={handleOnClick} />
          <p>Name</p>
        </div>
      );
    },
  }),
  columnHelper.accessor('description', {
    id: 'description',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div onClick={() => handleSelect(id)} className="w-[350px]">
          {info.getValue()}
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="w-[350px] text-start text-gray-400"
        >
          --
        </div>
      );
    },
    header: () => <div>Description</div>,
  }),
  columnHelper.accessor('modified', {
    id: 'modified',
    cell: (info) => {
      const id = info.row.original.id;

      return info.getValue() ? (
        <div className="w-[100px]" onClick={() => handleSelect(id)}>
          <p>{format(info.getValue() as string, 'Y/MM/dd')}</p>
        </div>
      ) : (
        <div
          onClick={() => handleSelect(id)}
          className="w-[100px] text-start text-gray-400"
        >
          --
        </div>
      );
    },
    header: () => <div>Modified</div>,
  }),
  columnHelper.accessor('actions', {
    id: 'actions',
    cell: (info) => {
      const id = info.row.original.id;
      const { project_id: projectId } = useParams();

      const { org_id } = useParams();

      const { data: projects } = useGetProjects(Number(org_id) ?? NaN, {
        enabled: !!org_id && type === 'project',
      });

      const { data: dataset } = useGetDatasetById(
        {
          datasetId: id ?? NaN,
          orgId: Number(org_id),
          projectId: Number(projectId),
        },
        {
          enabled: !!projectId && (type === 'dataset' || (!!id && !!org_id)),
        }
      );

      const currentOrg = useCurrentOrganization();

      const project = useCurrentProject(
        type === 'project' ? id : Number(projectId)
      );

      const editRecord =
        type === 'project' ? projects?.find((p) => p.id === id) : dataset;

      const { mutateAsync: createDataset } = useCreateDataset();

      const { data: datasets } = useGetDatasets(Number(projectId) ?? NaN, {
        enabled: !!projectId && type === 'dataset',
      });

      const { watch } = useFormContext();

      const data = watch();

      const datasetData =
        dataset && editRecord
          ? formatDatasetConfig(editRecord?.id, dataset)
          : undefined;

      const isIndeterminateAmount = Object.keys(data).filter(
        (n) => data[n]
      ).length;

      const isAlowTableAction =
        type === 'project'
          ? project?.role === 'project_owner' ||
            currentOrg?.role === 'organization_admin'
          : type === 'dataset'
            ? project?.role === 'project_owner'
            : false;

      const isAllowed =
        type === 'project'
          ? (editRecord as TypeProject)?.role === 'project_owner'
          : true;

      const handleDuplicateDataset = useCallback(() => {
        if (
          type === 'dataset' &&
          editRecord &&
          currentOrg &&
          datasets &&
          dataset
        ) {
          const { project, name, description, configuration } =
            editRecord as TypeDataset;

          const datasetData = {
            project,
            name: getCopyDatasetName({
              name,
              datasets,
              dataset,
            }),
            description,
            configuration,
          };

          createDataset({
            orgId: Number(org_id),
            projectId: project,
            data: datasetData,
          });
        }
      }, [type, editRecord]);

      return isAllowed ? (
        <div className="w-[200px] opacity-0 transition group-hover:opacity-100">
          <div className="flex gap-2">
            {isAlowTableAction && isIndeterminateAmount < 2 && (
              <div className="flex grow justify-end gap-4 font-semibold text-[#666]">
                {type === 'project' && project && (
                  <InviteFormDialog
                    to="project"
                    projectId={project.id}
                    roles={roles}
                    trigger={{
                      label: <Invite />,
                      style: {
                        color: '#666',
                        padding: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'none !important',
                      },
                    }}
                  />
                )}
                {editRecord && type === 'project' && (
                  <CreateProjectDialog
                    defaultValues={{
                      id: editRecord.id,
                      name: editRecord.name,
                      description: editRecord.description,
                    }}
                    trigger={{
                      className:
                        'flex py-0 px-0 cursor-pointer items-center bg-transparent text-[#666]',
                      label: <Pencil width={20} height={20} />,
                    }}
                  />
                )}
                {type === 'dataset' && editRecord && (
                  <CreateDatasetDialog
                    defaultValues={datasetData}
                    trigger={{
                      className:
                        'text-[#666] px-0 flex bg-transparent p-0 gap-1 items-center',
                      label: <Pencil width={20} height={20} />,
                    }}
                  />
                )}
                {type === 'dataset' && editRecord && (
                  <Copy onClick={handleDuplicateDataset} size={20} />
                )}
                <IconButton icon={<Archive />} />
                {editRecord && (
                  <ConfirmDeleteDialog
                    type={type}
                    row={{
                      id: editRecord.id,
                      name: editRecord.name,
                      description: editRecord.description,
                      modified: editRecord.updated_at,
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex w-[200px] justify-end">
          <Tooltip
            style={{
              fontSize: '14px !important',
            }}
            placement="top-start"
            title="Only the project owner or members can access this project."
            arrow
          >
            <Info size={20} color="#999999" />
          </Tooltip>
        </div>
      );
    },
    header: () => <div />,
  }),
];

const EditBar = ({ rows, type }: { rows: DataRow[]; type: TableType }) => {
  const { watch, setValue } = useFormContext();
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const { project_id: projectId, org_id } = useParams();

  const data = watch();

  const editRecord = rows.filter((row) => data[row.id])[0];

  const { mutateAsync: createDataset } = useCreateDataset({
    onSuccess: () => {
      setValue(editRecord.id.toString(), false);
    },
  });

  const currentOrg = useCurrentOrganization();

  const project = useCurrentProject(
    type === 'project' ? editRecord?.id : Number(projectId)
  );

  const { data: datasets } = useGetDatasets(Number(projectId) ?? NaN, {
    enabled: !!projectId && type === 'dataset',
  });

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: editRecord?.id ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(projectId),
    },
    {
      enabled:
        !!projectId &&
        (type === 'dataset' || (!!editRecord?.id && !!currentOrg?.id)),
    }
  );

  useEffect(() => {
    const indeterminate = Object.keys(data).some((key) => data[key]);

    setIsIndeterminate(indeterminate);
  }, [data]);

  const isIndeterminateAmount = Object.keys(data).filter((n) => data[n]).length;

  const handleOnClick = () => {
    setIsIndeterminate(false);
    Object.keys(data).forEach((key) => {
      setValue(key, false);
    });
  };

  const datasetData =
    dataset && editRecord
      ? formatDatasetConfig(editRecord?.id, dataset)
      : undefined;

  const isAlowTableAction =
    type === 'project'
      ? project?.role === 'project_owner' ||
        currentOrg?.role === 'organization_admin'
      : type === 'dataset'
        ? project?.role === 'project_owner'
        : false;

  const handleDuplicateDataset = useCallback(() => {
    if (type === 'dataset' && editRecord && currentOrg && datasets && dataset) {
      const { project, name, description, configuration } = dataset;

      const datasetData = {
        project,
        name: getCopyDatasetName({
          name,
          datasets,
          dataset,
        }),
        description,
        configuration,
      };

      createDataset({
        orgId: Number(org_id),
        projectId: project,
        data: datasetData,
      });
    }
  }, [type, editRecord]);

  return (
    <div className="flex w-full justify-between border border-[#E4E7EC] bg-[#F8F8F8] px-6 py-3">
      <div className="flex items-center gap-2">
        <Checkbox onClick={handleOnClick} indeterminate={isIndeterminate} />
        <p className="font-bold text-[#4D4D4D]">
          {isIndeterminateAmount} selected
        </p>
      </div>
      {isAlowTableAction && (
        <div className="flex grow justify-end gap-4 font-semibold text-[#666]">
          {isIndeterminateAmount < 2 &&
            type === 'project' &&
            isAlowTableAction &&
            project && (
              <InviteFormDialog
                to="project"
                projectId={project.id}
                roles={roles}
                trigger={{
                  label: (
                    <>
                      <Invite />
                      <p className="font-semibold">Invite</p>
                    </>
                  ),
                  style: {
                    color: '#666',
                    padding: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'none !important',
                  },
                }}
              />
            )}
          {isIndeterminateAmount < 2 && type === 'project' && editRecord && (
            <CreateProjectDialog
              defaultValues={{
                id: editRecord.id,
                name: editRecord.name,
                description: editRecord.description,
              }}
              trigger={{
                className:
                  'flex py-0 cursor-pointer items-center bg-transparent text-[#666]',
                label: (
                  <>
                    <Pencil width={20} height={20} />
                    <p className="font-semibold">Edit</p>
                  </>
                ),
              }}
            />
          )}
          {isIndeterminateAmount < 2 && type === 'dataset' && editRecord && (
            <CreateDatasetDialog
              defaultValues={datasetData}
              trigger={{
                className:
                  'text-[#666] flex bg-transparent p-0 gap-1 items-center',
                label: (
                  <>
                    <Pencil width={20} height={20} />
                    <p className="font-semibold">Edit</p>
                  </>
                ),
              }}
            />
          )}
          {type === 'dataset' && editRecord && (
            <button
              type="button"
              onClick={handleDuplicateDataset}
              className="flex items-center gap-1"
            >
              <Copy size={20} />
              <span>Copy</span>
            </button>
          )}
          <IconButton label="Archive" icon={<Archive />} />
          {isIndeterminateAmount < 2 && editRecord && (
            <ConfirmDeleteDialog label="Delete" type={type} row={editRecord} />
          )}
        </div>
      )}
      <div />
    </div>
  );
};

const BaseTable = ({ data, handleSelect, type }: DataTableProps) => {
  const form = useForm();

  const { watch } = form;

  const { project_id: projectId } = useParams();

  const isIndeterminate = Object.keys(watch()).some((key) => watch()[key]);

  const currentOrg = useCurrentOrganization();

  const { data: projects } = useGetProjects(Number(currentOrg?.id) ?? NaN, {
    enabled: !!currentOrg?.id && type === 'project',
  });

  const { isRefetching: isRefetchingDatasets } = useGetDatasets(
    Number(projectId) ?? NaN,
    {
      enabled: !!projectId && type === 'dataset',
    }
  );

  const table = useReactTable({
    data,
    columns: columns({
      handleSelect,
      type,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full">
      <FormProvider {...form}>
        <form>
          {isIndeterminate && <EditBar rows={data} type={type} />}
          <table className="w-full">
            <thead className={cn({ hidden: isIndeterminate })}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className={cn(
                        'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                      )}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="relative">
              {isRefetchingDatasets && (
                <div className="absolute flex h-full w-full items-center justify-center bg-gray-200 opacity-50">
                  <Spinner />
                </div>
              )}
              {table.getRowModel().rows.map((row) => {
                const isSelected = watch()[row.original.id];

                const isAllowed =
                  type === 'project'
                    ? (
                        projects?.find(
                          (p) => p.id === row.original.id
                        ) as TypeProject
                      )?.role === 'project_owner'
                    : true;

                return (
                  <tr
                    key={row.id}
                    className={cn(
                      'cursor-pointer text-[#333] transition px-6 py-3',
                      {
                        'bg-[#F5F9FF]': isSelected,
                        'text-[#999999] bg-[#FFFFFF] disabled': !isAllowed,
                        'group hover:bg-[#F8F8F8]': isAllowed,
                      }
                    )}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="border-b border-[#E4E7EC] px-6 first:border-l last:border-r"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {!table.getRowModel().rows.length && (
            <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
              <p className="text-lg text-[#999]">Please create a {type}.</p>
            </div>
          )}
        </form>
      </FormProvider>
      <div className="h-4" />
    </div>
  );
};

export default BaseTable;
