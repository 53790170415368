import { mainApi } from '../utils/api';

export type GetDataTablesByDatasetIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetDataTableByIdInput = {
  orgId: number;
  projectId: number;
  dataTableId: number;
};

const getDataTablesByDatasetId = ({
  orgId,
  projectId,
  datasetId,
}: GetDataTablesByDatasetIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/data_tables/?dataset=${datasetId}`
  );

const getDataTableById = ({
  orgId,
  projectId,
  dataTableId,
}: GetDataTableByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/data_tables/${dataTableId}/`
  );

export { getDataTablesByDatasetId, getDataTableById };
