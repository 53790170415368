import React, { useEffect, useState } from 'react';

import { ArrowUpward } from '@mui/icons-material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { CircleAlert } from 'lucide-react';

import { useGetSentInvitations } from '../../hooks/useSentInvitations';
import cn from '../../utils/cn';
import { useCurrentOrganization } from '../../utils/helpers';

import SentInvitationDialog from './SentInvitationDialog';

export type DataRow = {
  id: number;
  receiver: {
    name?: string;
    email: string;
  };
  sender: {
    name?: string;
    email: string;
  };
  project: string;
  model: string;
  acceptedAt: string;
  createdAt: string;
};

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('receiver', {
    cell: (info) => {
      const { name, email } = info.getValue();

      return (
        <div className="group flex flex-col items-start py-3 text-start">
          <p className="font-semibold">{name ?? 'Unknown'}</p>
          <p className="text-[#666]">{email}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-2">
        <p>Receiver</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('project', {
    id: 'project',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>Project Name</div>,
  }),
  columnHelper.accessor('model', {
    id: 'model',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>Model Name</div>,
  }),
  columnHelper.accessor('sender', {
    cell: (info) => {
      const { name, email } = info.getValue();

      return (
        <div className="group flex flex-col items-start py-3">
          <p className="font-semibold">{name ?? 'Unknown'}</p>
          <p className="text-[#666]">{email}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-2">
        <p>Sender</p>
      </div>
    ),
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'yyyy/MM/dd')}
        </div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>Date Requested</div>,
  }),
  columnHelper.accessor('acceptedAt', {
    id: 'acceptedAt',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'yyyy/MM/dd')}
        </div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>Date Accepted</div>,
  }),
  columnHelper.accessor('acceptedAt', {
    id: 'acceptedAt',
    cell: (info) => {
      const [open, setOpen] = useState(false);

      const value = info.getValue();

      const currentOrg = useCurrentOrganization();

      const { data: sentInvitations } = useGetSentInvitations(
        { orgId: Number(currentOrg?.id) ?? NaN },
        {
          enabled: !!currentOrg?.id,
        }
      );

      const handleOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

      const invitationId = info.row.original.id;

      const invitation = sentInvitations?.find((r) => r.id === invitationId);

      return (
        <div className="flex justify-between gap-5">
          {value ? (
            <div className="w-full">
              {format(value as string, 'yyyy/MM/dd')}
            </div>
          ) : (
            <div className="w-full text-center text-gray-400">--</div>
          )}
          <CircleAlert color="#2196F3" onClick={handleOpen} />
          <SentInvitationDialog
            open={open}
            handleOnClose={handleClose}
            data={{
              name: invitation?.model.name ?? '',
              description: invitation?.model.description ?? '',
              from:
                invitation?.shared_by.first_name +
                ' ' +
                invitation?.shared_by.last_name,
              to: invitation?.email ?? '',
              organization: invitation?.shared_by.organizations[0].name ?? '',
            }}
          />
        </div>
      );
    },
    header: () => <div>Shared By</div>,
    size: 200,
  }),
];

const SentInvitationsTable = () => {
  const [data, setData] = useState<DataRow[]>([]);
  const currentOrg = useCurrentOrganization();

  const { data: sentInvitations } = useGetSentInvitations(
    { orgId: Number(currentOrg?.id) ?? NaN },
    {
      enabled: !!currentOrg?.id,
    }
  );

  useEffect(() => {
    if (sentInvitations) {
      setData(
        sentInvitations.map((r) => ({
          id: r.id,
          receiver: {
            name: r.receiver.full_name,
            email: r.receiver.email,
          },
          project: r.project.name,
          model: r.model.name,
          acceptedAt: r.updated_at,
          createdAt: r.created_at,
          sender: {
            name: r.shared_by.first_name + ' ' + r.shared_by.last_name,
            email: r.shared_by.email,
          },
        })) as DataRow[]
      );
    }
  }, [sentInvitations]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full">
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
            >
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={cn(
                    'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer px-6 py-3 transition">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!table.getRowModel().rows.length && (
        <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
          <p className="text-lg text-[#999]">No sharing requests available</p>
        </div>
      )}
      <div className="h-4" />
    </div>
  );
};

export default SentInvitationsTable;
