import React from 'react';

import { useCurrentOrganization } from '../../utils/helpers';
import InviteFormDialog from '../organizations/InviteFormDialog';
import SearchInput from '../SearchInput';
import UsersTable from '../UsersTable';

const roles = [
  {
    label: 'Account Admin',
    value: 'organization_admin',
  },
  {
    label: 'Project Owner',
    value: 'project_owner',
  },
  {
    label: 'Project Member',
    value: 'project_member',
  },
];

const UserManagement = () => {
  const currentOrg = useCurrentOrganization();

  const isOrgAdmin = currentOrg?.role === 'organization_admin';

  return (
    <div className="flex flex-col gap-6 px-12 py-6">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <h1 className="text-3xl font-bold text-[#333]">User Management</h1>
          <p className="text-[#666]">Coming soon.</p>
        </div>
        <div>{isOrgAdmin && <InviteFormDialog roles={roles} />}</div>
      </div>
      <div className="flex gap-6">
        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Total users</p>
          <p className="text-4xl font-semibold text-[#B8341B]">8</p>
        </div>

        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Active users</p>
          <p className="text-4xl font-semibold text-[#B8341B]">6</p>
        </div>

        <div className="flex flex-col border border-[#E4E7EC] px-6 pt-3">
          <p className="w-[118px] text-[#666666]">Account Admins</p>
          <p className="text-4xl font-semibold text-[#B8341B]">2</p>
        </div>
      </div>
      <div className="w-[300px]">
        <SearchInput />
      </div>
      <div>
        <UsersTable />
      </div>
    </div>
  );
};

export default UserManagement;
