import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material';
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { Toaster } from 'sonner';

import AccountSettings from './components/AccountSettings';
import AuthorizedRoute from './components/AuthorizedRoute';
import InvitationVerification from './components/organizations/InvitationVerification';
import ProjectMembersManagement from './components/projects/ProjectMembersManagement';
import ProjectSettings from './components/projects/ProjectSettings';
import ProtectedRoute from './components/ProtectedRoute';
import SharedModelVault from './components/sharedModelVault/SharedModelVault';
import SharingRequestDetail from './components/SharingRequestDetailView';
import UserAccountDetails from './components/UserAccountDetails';
import UserManagement from './components/userManagement/UserManagement';
import AccountActivation from './landing/AccountActivation';
import Login from './landing/Login';
import LoginByLinkEmailSent from './landing/LoginByLinkEmailSent';
import LoginByLinkFail from './landing/LoginByLinkFail';
import LoginByLink from './landing/LoginByLinkForm';
import LoginByLinkVerify from './landing/LoginByLinkVerify';
import PasswordResetFail from './landing/PasswordResetFail';
import PasswordResetForm from './landing/PasswordResetForm';
import PasswordResetVerify from './landing/PasswordResetVerify';
import ArchivedAssets from './main/ArchivedAssets';
import Home from './main/Home';
import PasswordChange from './main/PasswordChange';
import ProjectView from './main/ProjectView';
import routes from './routes';

import './App.css';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY as string);

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App relative flex">
        <div className="relative flex w-full flex-col">
          <div className="flex">
            <div className="w-full">
              <Routes>
                <Route path={routes.login} element={<Login />} />
                <Route
                  path={routes.organization.invitation_verification}
                  element={<InvitationVerification />}
                />
                <Route
                  path={routes.loginByLink.index}
                  element={<LoginByLink />}
                />
                <Route
                  path={routes.loginByLink.sent}
                  element={<LoginByLinkEmailSent />}
                />
                <Route
                  path={routes.auth.verifyLoginByLink}
                  element={<LoginByLinkVerify />}
                />
                <Route
                  path={routes.loginByLink.fail}
                  element={<LoginByLinkFail />}
                />
                <Route
                  path={routes.auth.verifyResetPassword}
                  element={<PasswordResetVerify />}
                />
                <Route
                  path={routes.resetPassword.fail}
                  element={<PasswordResetFail />}
                />
                <Route
                  path={routes.resetPassword.setPassword}
                  element={<PasswordResetForm />}
                />
                <Route element={<ProtectedRoute />}>
                  <Route
                    path={routes.accountActivation}
                    element={<AccountActivation />}
                  />
                  <Route path={routes.home} element={<Home />} />
                  <Route path={routes.projects.index} element={<Home />} />

                  <Route element={<AuthorizedRoute />}>
                    <Route
                      path={routes.projects.detail}
                      element={<ProjectView />}
                    />
                    <Route
                      path={routes.projects.members}
                      element={<ProjectMembersManagement />}
                    />
                    <Route
                      path={routes.projects.settings}
                      element={<ProjectSettings />}
                    />
                  </Route>
                  <Route
                    path={routes.userManagement.index}
                    element={<UserManagement />}
                  />
                  <Route path={routes.account} element={<AccountSettings />} />
                  <Route
                    path={routes.userManagement.detail}
                    element={<UserAccountDetails />}
                  />
                  <Route
                    path={routes.sharedModelVault.index}
                    element={<SharedModelVault />}
                  />
                  <Route
                    path={routes.sharedModelVault.detail}
                    element={<SharingRequestDetail />}
                  />
                  <Route
                    path={routes.archivedAssets.index}
                    element={<ArchivedAssets />}
                  />
                  <Route
                    path={routes.changePassword}
                    element={<PasswordChange />}
                  />
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-right"
        toastOptions={{
          style: {
            padding: '0px',
            backgroundColor: 'black',
          },
        }}
      />
    </ThemeProvider>
  );
}

export default App;
