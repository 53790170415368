import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { ArrowUpward } from '@mui/icons-material';
import { Menu as MuiMenu } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { CircleAlert } from 'lucide-react';
import { EllipsisVertical } from 'lucide-react';

import {
  useAcceptReceivedSharingInvitation,
  useGetReceivedSharingInvitations,
  useRejectReceivedSharingInvitation,
} from '../../../hooks/useReceviedSharingInvitations';
import { useGetSentInvitations } from '../../../hooks/useSentInvitations';
import { type AcceptStatus } from '../../../types';
import cn from '../../../utils/cn';
import { useCurrentOrganization } from '../../../utils/helpers';
import SentInvitationDialog from '../SentInvitationDialog';

export type DataRow = {
  id: number;
  sender: {
    name?: string;
    email: string;
  };
  receiver: {
    name?: string;
    email: string;
  };
  organization: string;
  model: string;
  acceptedAt?: string;
  status: AcceptStatus;
  createdAt: string;
};

const Menu = styled(MuiMenu)({
  '& .MuiMenu-list': {
    paddingTop: '0px',
    paddingBottom: '0px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    gap: '8px',
  },
});

const columnHelper = createColumnHelper<DataRow>();

const columns = [
  columnHelper.accessor('sender', {
    cell: (info) => {
      const { name, email } = info.getValue();

      return (
        <div className="group flex flex-col items-start py-3 text-start">
          <p className="w-full font-semibold">{name}</p>
          <p className="text-sm text-[#666]">{email}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-2">
        <p>Sender</p>
        <ArrowUpward
          style={{
            height: '20px',
            width: '20px',
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('organization', {
    id: 'organization',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>Organization</div>,
  }),
  columnHelper.accessor('model', {
    id: 'model',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">{info.getValue()}</div>
      ) : (
        <div className="w-full text-start text-gray-400">--</div>
      ),
    header: () => <div>Model Name</div>,
  }),
  columnHelper.accessor('receiver', {
    cell: (info) => {
      const value = info.getValue();

      return (
        <div className="group flex flex-col items-start py-3 text-start">
          <p className="w-full font-semibold">{value?.name}</p>
          <p className="text-sm text-[#666]">{value?.email}</p>
        </div>
      );
    },
    header: () => (
      <div className="flex items-center gap-2">
        <p>Receiver</p>
      </div>
    ),
  }),
  columnHelper.accessor('createdAt', {
    id: 'createdAt',
    cell: (info) =>
      info.getValue() ? (
        <div className="w-full">
          {format(info.getValue() as string, 'yyyy/MM/dd')}
        </div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      ),
    header: () => <div>Date Received</div>,
  }),
  columnHelper.accessor('status', {
    id: 'status',
    cell: (info) => {
      const status = info.getValue();

      const StatusLabel = ({
        label,
        value,
      }: {
        value: string;
        label: string;
      }) => (
        <div className="flex justify-start py-2">
          <p
            className={cn(
              'w-fit rounded-full bg-[#E1FBE7] px-6 py-1 text-green-600',
              {
                'bg-[#EEF6FF] text-[#117DD3]': value === 'active',
                'text-[#C38D04] bg-[#FFF8E0]': value === 'pending',
                'bg-[#FFF4F2] text-[#B8341B]': value === 'reject',
              }
            )}
          >
            {label}
          </p>
        </div>
      );

      return status ? (
        <div className="w-full">
          {status === 'Accepted' ? (
            <StatusLabel value="active" label="Active" />
          ) : null}
          {status === 'Pending' ? (
            <StatusLabel value="pending" label="Pending" />
          ) : null}
          {status === 'Rejected' ? (
            <StatusLabel value="reject" label="Reject" />
          ) : null}
        </div>
      ) : (
        <div className="w-full text-center text-gray-400">--</div>
      );
    },
    header: () => <div>Status</div>,
  }),
  columnHelper.accessor('acceptedAt', {
    id: 'acceptedAt',
    cell: (info) => {
      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
      const [openDialog, setOpenDialog] = useState(false);

      const { mutate: acceptInvitation } = useAcceptReceivedSharingInvitation();
      const { mutate: rejectInvitation } = useRejectReceivedSharingInvitation();

      const openDropdown = Boolean(anchorEl);

      const currentOrg = useCurrentOrganization();

      const { data: sentInvitations } = useGetSentInvitations(
        { orgId: Number(currentOrg?.id) ?? NaN },
        {
          enabled: !!currentOrg?.id,
        }
      );

      const invitationId = info.row.original.id;

      const invitation = sentInvitations?.find((r) => r.id === invitationId);

      const handleAcceptInvitation = () => {
        acceptInvitation({
          orgId: Number(currentOrg?.id),
          invitationId,
        });
      };

      const handleRejectInvitation = () => {
        rejectInvitation({
          orgId: Number(currentOrg?.id),
          invitationId,
        });
      };

      const handleOpenDialog = () => {
        setOpenDialog(true);
      };

      const handleCloseDialog = () => {
        setOpenDialog(false);
      };

      const handleOpenDropdown = (
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        setAnchorEl(event.currentTarget);
      };

      const handleCloseDropdown = () => {
        setAnchorEl(null);
      };

      return (
        <div className="flex min-w-[200px] justify-between">
          {info.getValue() ? (
            <p>{format(info.getValue() as string, 'yyyy/MM/dd')}</p>
          ) : (
            <p>--</p>
          )}
          <div className="flex gap-2">
            <CircleAlert color="#2196F3" onClick={handleOpenDialog} />
            <button onClick={handleOpenDropdown}>
              <EllipsisVertical size={20} color="#666666" />
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openDropdown}
              onClose={handleCloseDropdown}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <button
                onClick={handleAcceptInvitation}
                className="w-[100px] rounded-[4px] bg-[#E1FBE7] py-1 text-center font-semibold  text-[#0B9919]"
              >
                Accept
              </button>
              <button
                onClick={handleRejectInvitation}
                className="w-[100px] rounded-[4px] bg-[#FFF4F2] py-1 text-center font-semibold text-[#B8341B]"
              >
                Reject
              </button>
            </Menu>
          </div>
          <SentInvitationDialog
            open={openDialog}
            handleOnClose={handleCloseDialog}
            data={{
              name: invitation?.model.name ?? '',
              description: invitation?.model.description ?? '',
              from:
                invitation?.shared_by.first_name +
                ' ' +
                invitation?.shared_by.last_name,
              to: invitation?.email ?? '',
              organization: invitation?.shared_by.organizations[0].name ?? '',
            }}
          />
        </div>
      );
    },
    header: () => <div>Date Accepted</div>,
    size: 200,
  }),
];

const ReceivedInvitationsTable = () => {
  const currentOrg = useCurrentOrganization();

  const [data, setData] = useState<DataRow[]>([]);

  const { data: receiveSharingInvitations } = useGetReceivedSharingInvitations({
    orgId: Number(currentOrg?.id),
  });

  useEffect(() => {
    if (receiveSharingInvitations) {
      setData(
        receiveSharingInvitations.map((r) => ({
          id: r.id,
          sender: {
            name: r.shared_by.first_name + ' ' + r.shared_by.last_name,
            email: r.shared_by.email,
          },
          // @todo: update this
          organization: r.shared_by.organizations[0].name,
          model: r.model.name,
          // @todo: update this
          acceptedAt: undefined,
          status: r.accept_status,
          createdAt: r.created_at,
        })) as DataRow[]
      );
    }
  }, [receiveSharingInvitations]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full">
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="!rounded-md border-[#E4E7EC] bg-[#F8F8F8] first:border-l last:border-r"
            >
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={cn(
                    'first:border-l border-t border-b last:border-r border-[#E4E7EC] px-6 py-3 text-start text-sm text-[#4D4D4D]'
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="cursor-pointer px-6 py-3 transition">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="border-b border-[#E4E7EC] px-6 text-[#333] first:border-l last:border-r"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!table.getRowModel().rows.length && (
        <div className="flex justify-center border border-t-0 border-[#E4E7EC] py-20">
          <p className="text-lg text-[#999]">No invitations available</p>
        </div>
      )}
      <div className="h-4" />
    </div>
  );
};

export default ReceivedInvitationsTable;
