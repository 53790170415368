import { type Granularity } from '../types';
import { mainApi } from '../utils/api';

export type CreateScenarioInput = {
  orgId: number;
  projectId: number;
  data: {
    name: string;
    description?: string;
    data_table: number;
  };
};

export type UpdateScenariosInput = {
  orgId: number;
  projectId: number;
  id: number;
  data: {
    scenarios: number[];
  };
};

export type UpdateScenarioByIdInput = {
  orgId: number;
  projectId: number;
  id: number;
  data: {
    name: string;
    description?: string;
    data_table: number;
  };
};

export type GenerateScenarioDataInput = {
  scenarioId: number;
  orgId: number;
  projectId: number;
  data: {
    group_by?: {
      by_fields: string[];
      agg_field: string;
      agg_func: string;
    };
    pivot?: {
      index_fields: string[];
      timeline_type: Granularity;
      agg_field: string;
      agg_func?: string;
    };
  };
};

export type DeleteScenarioInput = {
  orgId: number;
  projectId: number;
  scenarioId: number;
};

export type CreateScenarioVectorInput = {
  orgId: number;
  projectId: number;
  scenarioId: number;
  data: {
    name: string;
    description?: string;
    field_name: string;
    delta: number;
    delta_percentage: number;
    new_value: number;
    criterias: string;
    is_active?: true;
    scenario: number;
  };
};

export type GetScenariosByModelIdInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type GetScenariosByDatasetIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetScenariosByDataTableIdInput = {
  orgId: number;
  projectId: number;
  dataTableId: number;
};

export type GetScenarioVectorsInput = {
  orgId: number;
  projectId: number;
  scenarioId: number;
};

export type GetScenarioByIdInput = {
  orgId: number;
  projectId: number;
  scenarioId: number;
};

export type CloneScenarioInput = {
  orgId: number;
  projectId: number;
  scenarioId: number;
};

const getScenarioById = ({
  orgId,
  projectId,
  scenarioId,
}: GetScenarioByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/scenarios/${scenarioId}/`
  );

const getScenariosByDatasetId = ({
  orgId,
  projectId,
  datasetId,
}: GetScenariosByDatasetIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/scenarios/?dataset=${datasetId}`
  );

const createScenario = ({ orgId, projectId, data }: CreateScenarioInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/scenarios/`, data);

const updateScenarioById = ({
  orgId,
  projectId,
  id,
  data,
}: UpdateScenarioByIdInput) =>
  mainApi.put(
    `organizations/${orgId}/projects/${projectId}/scenarios/${id}/`,
    data
  );

const updateModelScenarios = async ({
  orgId,
  projectId,
  id,
  data,
}: UpdateScenariosInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${id}/`,
    data
  );

const cloneScenario = async ({
  orgId,
  projectId,
  scenarioId,
}: CloneScenarioInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/scenarios/${scenarioId}/clone/`
  );

const deleteScenarioById = ({
  orgId,
  projectId,
  scenarioId,
}: DeleteScenarioInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/scenarios/${scenarioId}/`
  );

const getScenariosByModelId = ({
  modelId,
  orgId,
  projectId,
}: GetScenariosByModelIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/scenarios/`
  );

const getScenariosByDataTableId = ({
  orgId,
  projectId,
  dataTableId,
}: GetScenariosByDataTableIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/scenarios/?data_table=${dataTableId}`
  );

const getScenarioVectors = ({
  scenarioId,
  orgId,
  projectId,
}: GetScenarioVectorsInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/vectors/?scenario=${scenarioId}`
  );

const generateScenarioData = ({
  scenarioId,
  orgId,
  projectId,
  data,
}: GenerateScenarioDataInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/scenarios/${scenarioId}/data/`,
    data
  );

export {
  cloneScenario,
  createScenario,
  deleteScenarioById,
  generateScenarioData,
  getScenarioById,
  getScenariosByDatasetId,
  getScenariosByDataTableId,
  getScenariosByModelId,
  getScenarioVectors,
  updateModelScenarios,
  updateScenarioById,
};
