// To convert a string to have a Id format

import { useParams } from 'react-router-dom';

import { type TypeModel, type TypeScenario } from '../common/dataTypes';
import { useGetProjectById } from '../hooks';
import {
  useGetOrganizations,
  useGetOrgMembers,
} from '../hooks/useOrganizationHook';
import { useGetUser } from '../hooks/useUserHook';
import {
  type Granularity,
  type OrganizationRole,
  type ProjectRole,
} from '../types';

import { granularityLabels, monthLabels } from './constants';

// example:  First Officer -> firstOfficer
export const transformToValue = (str: string) => {
  // Helper function to check if the string is already in camelCase
  const isCamelCase = (s: string) => /^[a-z][a-zA-Z]*$/.test(s);

  // Check if the string is already in camelCase
  if (isCamelCase(str)) {
    return str;
  }

  // Transform the string to camelCase if it's not already
  return str
    .split(/\s+/)
    .map(
      (word, index) =>
        index === 0
          ? word.charAt(0).toLowerCase() + word.slice(1).toLowerCase() // Lowercase first letter of the first word
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Uppercase first letter of subsequent words
    )
    .join('')
    .trim(); // Join the transformed words into a single string
};

export const transformToLabel = (str: string) =>
  str
    .replace(/([A-Z])/g, (match, p1) => ` ${p1.toUpperCase()}`)
    .replace(/^./, (match) => match.toUpperCase())
    .replace('_', ' ')
    .trim();

// Generating a list of years base on the current year, 6 years before and 20 years after
export const generateYearList = () => {
  const currentYear = new Date().getFullYear();

  const result = new Set([
    ...Array.from({ length: 15 }, (_, i) =>
      (currentYear - i).toString()
    ).reverse(),
    ...Array.from({ length: 30 }, (_, i) => (currentYear + i).toString()),
  ]);

  return Array.from(result).map((year) => ({
    label: year,
    value: year,
  }));
};

export const arrangeByBase = (items?: (TypeScenario | TypeModel)[]) => {
  if (!items) {
    return [];
  }

  const baseItemIndex = items.findIndex((item) => item.is_base);
  if (baseItemIndex > -1) {
    const [baseItem] = items.splice(baseItemIndex, 1);
    items.unshift(baseItem);
  }
  return items;
};

export const generateMonthList = () =>
  monthLabels.map((label, index) => ({
    label,
    value: String(index + 1),
  }));

export const generateGranularityList = () =>
  granularityLabels.map((label) => ({
    label,
    value: transformToValue(label),
  }));

export const generateWeekList = () =>
  Array.from({ length: 52 }, (_, i) => `${i + 1}`).map((label, index) => ({
    label: `W${label}`,
    value: String(index + 1),
  }));

export const transformDateDisplay = ({
  date,
  granularity,
  to,
}: {
  date?: string;
  granularity: Granularity;
  to: 'value' | 'label';
}) => {
  if (!date) {
    return '';
  }

  const firstIndexValue = date.split('-')[0];
  const year = date.split('-')[1];
  let result;

  const weekList = generateWeekList();
  const monthList = generateMonthList();

  const from = to === 'value' ? 'label' : 'value';

  switch (granularity) {
    case 'monthly':
      result =
        `${monthList.find((month) => month[from] === firstIndexValue)?.[to]}-${year}` ??
        '';
      break;
    case 'weekly':
      result =
        `${weekList.find((week) => week[from] === firstIndexValue)?.[to]}-${year}` ??
        '';
      break;
    default:
      result = date;
  }

  return result;
};

export const useCurrentOrganization = () => {
  const { data: user } = useGetUser();
  const { data: organizations } = useGetOrganizations();

  const { org_id } = useParams();

  const currentOrg =
    user?.organizations.find((o) => o.id === Number(org_id)) ??
    organizations?.[0];

  if (!user || !currentOrg) {
    return null;
  }

  return currentOrg as unknown as {
    id: number;
    name: string;
    role: OrganizationRole;
  };
};

export const useCurrentMember = () => {
  const { data: user } = useGetUser();
  const organization = useCurrentOrganization();

  const { data: org_members } = useGetOrgMembers(organization?.id ?? NaN, {
    enabled: !!organization?.id && organization?.role === 'organization_admin',
  });

  const member = org_members?.find((member) => member.user.id === user?.id);

  return member;
};

export const useCurrentProject = (projectId?: number) => {
  const { project_id } = useParams();
  const organization = useCurrentOrganization();

  const { data: project } = useGetProjectById(
    {
      projectId: projectId ?? Number(project_id) ?? NaN,
      orgId: organization?.id ?? NaN,
    },
    {
      enabled: !!projectId || !!project_id,
    }
  );

  return project;
};

export const useCurrentMembership = () => {
  const { data: user } = useGetUser();

  const organization = useCurrentOrganization();

  const { project_id: projectId } = useParams();

  const { data: org_members } = useGetOrgMembers(organization?.id ?? NaN, {
    enabled: !!organization?.id && organization?.role === 'organization_admin',
  });

  const member = org_members?.find((member) => member.user.id === user?.id);

  const projectMember = member?.project_memberships.find(
    (m) => m.project.id === Number(projectId)
  );

  return {
    project: {
      role: projectMember?.role,
      ...projectMember?.project,
    },
    organization,
  } as {
    project: {
      id: number;
      name: string;
      role: ProjectRole;
    };
    organization: {
      id: number;
      name: string;
      role: OrganizationRole;
    };
  };
};
