import React, { type SyntheticEvent, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  Box,
  Button,
  styled,
  Tab as MuiTab,
  Tabs as MuiTabs,
} from '@mui/material';
import {
  type ColumnsStylesInterface,
  DataGridPremium,
  type GridColDef,
  type GridExcelExportOptions,
  type GridPinnedColumns,
  type GridPinnedRowsProp,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { ArrowDownToLine } from 'lucide-react';

import {
  getColsFromRowReport,
  transformDataReport,
} from '../../common/dataTableFormatter';
import { useGetDatasetById } from '../../hooks';
import { useGetModelById, useGetModelOutput } from '../../hooks/useModelsHook';

import { type Report, Reports, TABS } from './ModelOutputTabs';

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

type StyledTabsProps = {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
};

type StyledTabProps = {
  label: string;
  id: string;
};

const Tabs = styled((props: StyledTabsProps) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: '#B8341B',
  },
});

const Tab = styled((props: StyledTabProps) => (
  <MuiTab disableRipple {...props} />
))({
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'none',
  '&.MuiButtonBase-root': {
    padding: '10px 10px !important',
  },
  '&.Mui-selected': {
    color: '#B8341B',
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="h-[380px]"
      role="tabpanel"
      hidden={value !== index}
      id={value}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

type ModelComparisonOutputTabsProps = {
  enable: boolean;
  setEnable: (value: boolean) => void;
  baseId: number;
  targetId: number;
};

const ModelComparisonOutputTabs = ({
  enable,
  setEnable,
  baseId,
  targetId,
}: ModelComparisonOutputTabsProps) => {
  const [index, setIndex] = useState(0);
  const [report, setReport] = useState<Report>('financials');
  const [searchParams] = useSearchParams();

  const [reportTableRows, setReportTableRows] = useState<Object[]>([]);

  const [reportTableCols, setReportTableCols] = useState<GridColDef[]>([]);

  const [pinnedColumns, setPinnedColumns] = useState<GridPinnedColumns>();
  const [pinnedRows, setPinnedRows] = useState<GridPinnedRowsProp>();

  const { org_id } = useParams();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    const value = event.currentTarget.id as Report;

    setIndex(newValue);
    setReport(value);
  };

  const { project_id } = useParams();

  const modelId = searchParams.get('model_id');
  const datasetId = searchParams.get('dataset_id');

  const { data: baseModelOutput, isFetching: isFetchingModelOutput } =
    useGetModelOutput(
      {
        modelId: Number(baseId) ?? NaN,
        orgId: Number(org_id) ?? NaN,
        projectId: Number(project_id),
        data: {
          filters: {},
        },
      },
      {
        enabled: !!baseId && !!org_id && !!project_id && enable,
      }
    );

  const { data: modelOutput, isFetching: isFetchingBaseModelOutput } =
    useGetModelOutput(
      {
        modelId: Number(targetId) ?? NaN,
        orgId: Number(org_id) ?? NaN,
        projectId: Number(project_id),
        data: {
          filters: {},
        },
      },
      {
        enabled: !!modelId && !!org_id && !!project_id && enable,
      }
    );

  const { data: model } = useGetModelById(
    {
      modelId: Number(modelId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!modelId && !!org_id,
    }
  );

  const { data: dataset } = useGetDatasetById(
    {
      datasetId: Number(datasetId) ?? NaN,
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!datasetId && !!Number(org_id) && !!project_id,
    }
  );

  const reports = Object.keys(modelOutput ?? {}) as Report[];

  useEffect(() => {
    setEnable(false);

    if (modelOutput && baseModelOutput && report && modelOutput[report]) {
      const currentReport = modelOutput[report];
      const baseReport = baseModelOutput[report];

      const transformedData = transformDataReport(currentReport, report);
      const transformedBaseData = transformDataReport(baseReport, report);

      const cols = getColsFromRowReport(
        transformedData[0],
        report,
        transformedBaseData
      );

      setReportTableCols(cols);
      setReportTableRows(transformedData);

      if (report === 'crew_requirements') {
        setPinnedColumns({
          left: ['hierarchy', 'seat'],
        });
      } else if (report === 'movement_matrix') {
        setPinnedColumns({
          left: ['employee_id'],
        });
      } else if (report === 'seniority_distribution') {
        setPinnedColumns({
          left: ['step', 'seniority'],
        });
      } else if (report === 'financials') {
        setPinnedColumns({
          left: ['expense_type_verbose'],
        });
      } else if (report === 'training') {
        setPinnedColumns({
          left: ['from', 'to'],
        });
      } else if (report === 'stats') {
        setPinnedColumns({
          left: ['stats_name'],
        });
      } else if (report === 'average_expense') {
        setPinnedColumns({
          left: ['expense_name'],
        });
      } else if (report === 'average_rate') {
        setPinnedColumns({
          left: ['crew_type'],
        });
      }

      if (
        report !== 'movement_matrix' &&
        report !== 'stats' &&
        report !== 'average_expense' &&
        report !== 'average_rate' &&
        report !== 'kpis'
      ) {
        setPinnedRows({
          bottom: [transformedData[transformedData.length - 1]],
        });
      } else {
        setPinnedRows(undefined);
      }
    } else {
      setReportTableCols([]);
      setReportTableRows([]);
    }
  }, [modelOutput, baseModelOutput, report, enable]);

  const apiRef = useGridApiRef();

  const handleExportToExcel = () => {
    const colStyles: ColumnsStylesInterface = {};
    reportTableCols.forEach((colDef) => {
      colStyles[colDef.field] = {
        numFmt: '#,##0.00',
        font: {},
        alignment: {},
        protection: {},
        border: {},
        fill: {
          type: 'pattern',
          pattern: 'none',
        },
      };
    });

    const options: GridExcelExportOptions = {
      fileName: `${dataset?.name}_${model?.name}_${report}`,
      columnsStyles: { ...colStyles },
    };

    apiRef.current.exportDataAsExcel(options);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {reports && (
        <div className="flex items-center justify-between">
          <Tabs value={index} onChange={handleChange}>
            {TABS.map((report) => (
              <Tab id={report} key={report} label={Reports[report as Report]} />
            ))}
          </Tabs>
          <div className="flex items-center gap-1">
            <Button
              size="small"
              onClick={handleExportToExcel}
              variant="outlined"
              className="space-x-1 font-semibold"
            >
              <ArrowDownToLine />
              <label>Export to Excel</label>
            </Button>
          </div>
        </div>
      )}
      {report && (
        <div className="py-6">
          <CustomTabPanel value={report} index={report}>
            <DataGridPremium
              treeData={report === 'financials'}
              getTreeDataPath={(row) => row.tree ?? [row.id.toString()]}
              groupingColDef={{
                headerName:
                  report === 'financials'
                    ? 'Expense'
                    : report === 'average_expense'
                      ? 'Average Expense'
                      : report === 'kpis'
                        ? 'KPIs'
                        : '',
              }}
              apiRef={apiRef}
              rows={reportTableRows}
              columns={reportTableCols}
              pagination={false}
              disableSelectionOnClick
              loading={isFetchingModelOutput || isFetchingBaseModelOutput}
              disableAggregation
              density="compact"
              defaultGroupingExpansionDepth={2}
              initialState={{ aggregation: { model: { gross: 'sum' } } }}
              pinnedColumns={pinnedColumns}
              experimentalFeatures={{ rowPinning: true }}
              pinnedRows={pinnedRows}
            />
          </CustomTabPanel>
        </div>
      )}
    </Box>
  );
};

export default ModelComparisonOutputTabs;
