import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Avatar, Divider, MenuItem } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { Check, Mail } from 'lucide-react';

import IfsNavigateErrorDialog from '../components/dataset/IfsNavigateErrorDialog';
import Link from '../components/Link';
import MenuDropdown from '../components/MenuDropdown';
import NotificationDropdown from '../components/NotificationDropdown';
import { useLogout } from '../hooks';
import { useGetIFS } from '../hooks/useIFSHook';
import { useGetUser } from '../hooks/useUserHook';
import routes from '../routes';
import cn from '../utils/cn';
import { useCurrentOrganization } from '../utils/helpers';

const GROUP_HOME = 'home';
const GROUP_PROJECT = 'project';
const GROUP_USER = 'user';

const NavButton = ({ value }: { value: string }) => {
  const { pathname, search } = useLocation();

  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const searchParams = new URLSearchParams(search);

  const section = searchParams.get('section');

  const navigate = useNavigate();

  const datasetId = Number(searchParams.get('dataset_id'));
  const { project_id: projectId } = useParams();
  const currentOrg = useCurrentOrganization();

  const { data: ifs } = useGetIFS(
    {
      datasetId,
      projectId: Number(projectId),
      orgId: currentOrg?.id ?? NaN,
    },
    {
      enabled: !!datasetId && !!currentOrg?.id && !!projectId,
    }
  );

  const isSwitchDisabled = !_.isEmpty(ifs)
    ? ifs.files?.filter((file) => file.is_imported).length === 0
    : true;

  const handleSwitchSection = (section: string) => {
    if (section === 'dataset') {
      searchParams.delete('section');
      navigate(`${pathname}?${searchParams.toString()}`);
      return;
    }

    if (section === 'scenario' || section === 'model') {
      if (isSwitchDisabled) {
        setOpenErrorDialog(true);
        return;
      }
    }

    searchParams.set('section', section);
    searchParams.delete('mode');
    navigate(`${pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          handleSwitchSection(value.toLowerCase());
        }}
        className={cn(
          'h-full py-[15px] border-b-[2px] box-border transition text-center w-full px-[10px] border-[#F8F8F8] text-[#999]',
          {
            'border-[#B8341B] text-[#B8341B]':
              section === value.toLowerCase() ||
              (!section && value === 'Dataset'),
          }
        )}
      >
        {value}
      </button>
      <IfsNavigateErrorDialog
        open={openErrorDialog}
        handleOnClose={() => {
          setOpenErrorDialog(false);
        }}
      />
    </>
  );
};

function TopBar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentNavGroup, setCurrentNavGroup] = useState('');

  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const { search } = useLocation();

  const { org_id } = useParams();

  const searchParams = new URLSearchParams(search);

  const datasetId = searchParams.get('dataset_id');

  const { data: user } = useGetUser();

  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const { mutateAsync: logout } = useLogout({
    onSuccess: () => {
      localStorage.removeItem('user');
      navigate(routes.login);
    },
  });

  useEffect(() => {
    const path = pathname.split('/')[1];

    if (path === '') {
      setCurrentNavGroup(GROUP_HOME);
    } else if (path === 'projects') {
      setCurrentNavGroup(GROUP_PROJECT);
    } else if (path === 'user-management') {
      setCurrentNavGroup(GROUP_USER);
    }
  }, [pathname]);

  const handleLogout = async () => {
    await logout();
    await queryClient.resetQueries();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      color="default"
      className="flex w-full cursor-pointer border-b border-[#E6E6E6] "
    >
      <div className="flex w-full justify-between bg-white px-12">
        <div className="flex h-full">
          {datasetId && (
            <>
              <NavButton value="Dataset" />
              <NavButton value="Expense" />
              <NavButton value="Scenario" />
              <NavButton value="Model" />
              <NavButton value="Dashboard" />
            </>
          )}
        </div>
        <div className="flex items-center gap-3 py-[9px]">
          <NotificationDropdown />
          <Mail />
          <MenuDropdown
            trigger={
              <div className="flex w-full items-center gap-3">
                <Avatar
                  alt={user?.first_name}
                  src={user?.profile_pic ? user.profile_pic : '#'}
                  style={{
                    width: '35px',
                    height: '35px',
                  }}
                />
                <div className="-space-y-[1px]">
                  <p className="flex items-center text-sm font-semibold">
                    <p className="w-[130px] truncate text-start">
                      {user?.first_name} {user?.last_name}
                    </p>
                    {open ? (
                      <ExpandLess
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    ) : (
                      <ExpandMore
                        style={{
                          width: '20px',
                          height: '20px',
                        }}
                      />
                    )}
                  </p>
                  <p className="flex items-start text-xs font-light">
                    {user?.email}
                  </p>
                </div>
              </div>
            }
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            style={{
              marginTop: '12px',
              marginLeft: '-50px',
            }}
            open={open}
          >
            <MenuItem
              onClick={() => {
                navigate(routes.account.replace(':org_id', org_id ?? ''));
                handleClose();
              }}
            >
              Manage Account
            </MenuItem>
            <Divider
              style={{
                margin: 0,
              }}
            />
            <div className="flex flex-col pt-2">
              <p className="px-4 text-xs text-[#666]">Organizations</p>
            </div>
            <div className="pt-2">
              {user?.organizations?.map((org) => (
                <MenuItem
                  key={org.id}
                  onClick={() => {
                    navigate(
                      routes.projects.index.replace(
                        ':org_id',
                        org.id.toString()
                      )
                    );
                    handleClose();
                  }}
                >
                  <div className="flex items-center justify-between gap-10 py-1">
                    <div className="flex gap-2">
                      <Avatar
                        src="#"
                        alt={`${org.name} avatar`}
                        style={{
                          width: '25px',
                          height: '25px',
                        }}
                      />
                      <p>{org.name}</p>
                    </div>
                    {org.id === Number(org_id) && (
                      <Check size={20} color="#333333" />
                    )}
                  </div>
                </MenuItem>
              ))}
            </div>
            <div className="px-4 py-2">
              <Link href={routes.organization.viewAll}>
                View all organizations
              </Link>
            </div>
            <Divider
              style={{
                margin: 0,
              }}
            />
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </MenuDropdown>
        </div>
      </div>
    </div>
  );
}

export default TopBar;
