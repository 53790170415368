import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Link } from 'react-router-dom';

import MuiDrawer from '@mui/material/Drawer';
import { type CSSObject, styled, type Theme } from '@mui/material/styles';
import { Archive, Folder, Settings, Users, WalletCards } from 'lucide-react';

import { useGetDatasets, useGetProjectById } from '../../hooks';
import logo from '../../logo.svg';
import cn from '../../utils/cn';

import ExpenseDrawerSection from './ExpenseDrawerSection';
import ModelDrawerSection from './ModelDrawerSection';
import ScenarioDrawerSection from './ScenarioDrawerSection';

const drawerWidth = 280;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderRight: 'none',
  backgroundColor: '#01285F',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  borderRight: 'none',
  width: `calc(${theme.spacing(8)} + 1px)`,
  backgroundColor: '#01285F',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  whiteSpace: 'nowrap',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

type DrawerItemProps = {
  open: boolean;
  href: string;
  label: string;
  icon: React.ReactNode;
};

const DrawerItem = ({ label, icon, href, open }: DrawerItemProps) => {
  const location = useLocation();

  return (
    <Link
      to={href}
      className={cn(
        'flex w-full transition gap-2 text-white rounded-md p-3 text-start hover:bg-[#21438C]',
        {
          'bg-[#21438C] font-semibold':
            location.pathname.split('/').includes(href) ||
            location.pathname === href,
        }
      )}
    >
      {icon}
      <p
        className={cn('transition opacity-100', {
          'opacity-0 pointer-events-none hidden': !open,
        })}
      >
        {label}
      </p>
    </Link>
  );
};

export default function CustomDrawer({ open }: { open: boolean }) {
  const [searchParams] = useSearchParams();

  const datasetId = searchParams.get('dataset_id');
  const section = searchParams.get('section');

  const navigate = useNavigate();

  const { project_id, org_id } = useParams();

  const { data: project } = useGetProjectById(
    {
      projectId: Number(project_id) ?? NaN,
      orgId: Number(org_id) ?? NaN,
    },
    {
      enabled: !!project_id && !!org_id,
    }
  );

  const { data: datasets } = useGetDatasets(project?.id ?? NaN, {
    enabled: !!project,
  });

  const defaultDataset = datasets?.find(
    (dataset) => dataset.id === Number(datasetId)
  );

  const showDrawerItems = !datasetId && !project;

  return (
    <Drawer
      variant="permanent"
      open={open}
      className="relative !z-20"
      style={{
        backgroundColor: '#01285F',
      }}
    >
      <div className="sticky top-0 flex cursor-pointer items-center justify-start gap-3 border-b border-[#6A88BB] bg-background p-4">
        <img
          onClick={() => navigate(`/${org_id}/projects`)}
          src={logo}
          className="min-h-auto min-w-[38px]"
          alt="ISBE Group Logo"
          width={38}
        />
        <p
          onClick={() => navigate(`/${org_id}/projects`)}
          className="font-garamond font-bold text-white"
        >
          ISBE Group
        </p>
      </div>
      <div
        className={cn('transition flex flex-col opacity-100', {
          'opacity-0 pointer-events-none h-0': !open,
          'sticky top-[57px]': open,
        })}
      >
        {project && (
          <p className="!whitespace-normal break-words border-b border-[#6A88BB] bg-background p-3 text-start text-lg font-semibold text-[#FFAD01]">
            {project.name}
          </p>
        )}
        {defaultDataset && (
          <p className="mb-4 !whitespace-normal break-words bg-[#B0E8F7] px-3 py-2 text-lg font-bold text-[#01285F]">
            {defaultDataset?.name}
          </p>
        )}
      </div>
      <div
        className={cn('transition', {
          'opacity-0 pointer-events-none': !open,
        })}
      >
        {section === 'scenario' && <ScenarioDrawerSection />}
        {section === 'model' && <ModelDrawerSection />}
        {section === 'expense' && <ExpenseDrawerSection />}
      </div>
      {project && !defaultDataset && (
        <div className="flex flex-col gap-2 px-3 py-2">
          <DrawerItem
            open={open}
            href={`/${org_id}/projects/${project_id}/`}
            label="Datasets"
            icon={<Folder />}
          />
          <DrawerItem
            open={open}
            href={`/${org_id}/projects/${project_id}/members`}
            label="Members"
            icon={<Users />}
          />
          <DrawerItem
            open={open}
            href={`/${org_id}/projects/${project_id}/settings`}
            label="Settings"
            icon={<Settings />}
          />
        </div>
      )}
      {showDrawerItems && (
        <div className="flex flex-col items-start gap-2 px-3 py-6">
          <DrawerItem
            open={open}
            href={`/${org_id}/projects`}
            label="Projects"
            icon={<Folder />}
          />
          <DrawerItem
            open={open}
            href={`/${org_id}/user-management`}
            label="User Management"
            icon={<Users />}
          />
          <DrawerItem
            open={open}
            href={`/${org_id}/shared-model-vault`}
            label="Shared Model Vault"
            icon={<WalletCards />}
          />
          <DrawerItem
            open={open}
            href={`/${org_id}/archived-assets`}
            label="Archived Assets"
            icon={<Archive />}
          />
        </div>
      )}
    </Drawer>
  );
}
