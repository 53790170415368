import { useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import * as z from 'zod';

import FormInput from '../components/FormInput';
import { useResetPassword } from '../hooks/useAuthHook';
import routes from '../routes';
import { handleQueryError } from '../utils/api';
import cn from '../utils/cn';

const PasswordResetFormSchema = z
  .object({
    password: z
      .string()
      .trim()
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: z
      .string()
      .trim()
      .min(8, 'Password must be at least 8 characters'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Confirm password does not match',
    path: ['confirmPassword'],
  });

type PasswordResetFormSchemaType = z.infer<typeof PasswordResetFormSchema>;

const PasswordResetForm = () => {
  const [error, setError] = useState(Object);
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get('token');

  const navigate = useNavigate();

  const { mutate: resetPassword, isPending } = useResetPassword({
    onSuccess: () => {
      navigate(routes.login);
    },
    onError: (error) => {
      handleQueryError(error, setError);
    },
  });

  const form = useForm<PasswordResetFormSchemaType>({
    resolver: zodResolver(PasswordResetFormSchema),
  });

  const { handleSubmit } = form;

  const onSubmit: SubmitHandler<PasswordResetFormSchemaType> = (data) => {
    if (!token) {
      return;
    }

    resetPassword({
      data: {
        token,
        new_password: data.confirmPassword,
      },
    });
  };

  const handleCTAOnClick = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('type');
    setSearchParams(newSearchParams);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div
      className={cn(
        'space-y-4 bg-background flex items-center justify-center bg-cover px-[40px] pt-7 h-screen 3xl:overflow-hidden',
        {
          'translate-y-0': searchParams.get('type') === 'reset_password',
        }
      )}
    >
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="border-grey-500 flex h-fit w-full max-w-[600px] flex-col items-start gap-3 rounded-xl border-[2px] px-8 py-6 text-white backdrop-blur-sm"
        >
          <div className="flex flex-col pb-6">
            <h1 className="text-2xl font-bold uppercase">
              Create new Password
            </h1>
            <p className="text-sm">
              Your new password must be different from previous used passwords.
            </p>
          </div>
          <FormInput
            fullWidth
            name="password"
            label="Password"
            type="password"
            helperText="Must be at least 8 characters"
            helperTextStyle={{
              color: '#DCEBFE',
            }}
            labelClassName="text-white"
            style={{
              backgroundColor: 'white',
            }}
          />
          <FormInput
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            labelClassName="text-white"
            helperText="Both password must match"
            helperTextStyle={{
              color: '#DCEBFE',
            }}
            style={{
              backgroundColor: 'white',
            }}
          />
          <LoadingButton
            loading={isPending}
            type="submit"
            style={{
              backgroundColor: '#B8341B',
              color: 'white',
              width: '100%',
              marginTop: 20,
              padding: '12px 0px',
              borderRadius: '4px',
            }}
          >
            Submit
          </LoadingButton>
          <button
            type="button"
            onClick={handleCTAOnClick}
            className="w-full py-5 text-center"
          >
            <p>Back to Login</p>
          </button>
          <div className="w-full text-center font-light">
            <p>@{currentYear} ISBE Group</p>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default PasswordResetForm;
