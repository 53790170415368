import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import { ChevronDown, ChevronUp } from 'lucide-react';

import { useCurrentOrganization } from '../../utils/helpers';
import MenuDropdown from '../MenuDropdown';
import InviteFormDialog from '../organizations/InviteFormDialog';
import SearchInput from '../SearchInput';

import ProjectMembersTable from './ProjectMembersTable';

const roles = [
  {
    label: 'Account Admin',
    value: 'organization_admin',
  },
  {
    label: 'Project Owner',
    value: 'project_owner',
  },
  {
    label: 'Project Member',
    value: 'project_member',
  },
];

const RoleFilterDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleFilterByRole = (role: 'owner' | 'member') => {
    searchParams.set('role', role);

    setSearchParams(searchParams);
  };

  return (
    <MenuDropdown
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      open={open}
      trigger={
        <div className="flex w-full gap-2 px-3 text-[#666666]">
          <p>Roles</p>
          {open ? <ChevronUp width={15} /> : <ChevronDown width={15} />}
        </div>
      }
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      triggerClassName="rounded-md border border-[#CCCCCC] pl-3 py-1.5"
    >
      <MenuItem
        onClick={() => {
          handleFilterByRole('owner');
        }}
        style={{
          width: '100px',
        }}
      >
        Owner
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleFilterByRole('member');
        }}
      >
        Member
      </MenuItem>
    </MenuDropdown>
  );
};

const ProjectMembersManagement = () => {
  const currentOrg = useCurrentOrganization();

  const isOrgAdmin = currentOrg?.role === 'organization_admin';

  const { project_id } = useParams();

  return (
    <div className="flex flex-col gap-6 px-12 py-6">
      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          <h1 className="text-3xl font-bold text-[#333]">Members</h1>
        </div>
        <div>
          {isOrgAdmin && (
            <InviteFormDialog
              to="project"
              roles={roles}
              projectId={Number(project_id)}
            />
          )}
        </div>
      </div>
      <div className="flex w-full gap-2">
        <SearchInput />
        <RoleFilterDropdown />
      </div>
      <div>
        <ProjectMembersTable />
      </div>
    </div>
  );
};

export default ProjectMembersManagement;
