import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ProjectsView from '../components/projects/ProjectsView';
import { useGetUser } from '../hooks/useUserHook';

function Home() {
  const { data: user } = useGetUser();

  const { org_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Home | ISBE Group';
  }, []);

  useEffect(() => {
    if (!Number(org_id) && user?.organizations) {
      navigate(`/${user.organizations[0].id}/projects/`);
    }
  }, [org_id, user?.organizations]);

  return <ProjectsView />;
}

export default Home;
