import React from 'react';

import { Button, Dialog } from '@mui/material';

type ConfirmDiscardsDialogProps = {
  open: boolean;
  content: React.ReactNode;
  handleOnClose: () => void;
  handleOnSubmit: () => void;
};

const ConfirmDiscardsDialog = ({
  open,
  handleOnClose,
  handleOnSubmit,
  content,
}: ConfirmDiscardsDialogProps) => (
  <Dialog
    open={open}
    aria-labelledby="alertTitle"
    aria-describedby="alertContent"
  >
    <div className="flex flex-col gap-6 p-6">
      <h2 className="text-xl font-bold">
        Are you sure you want to discard all changes?{' '}
      </h2>
      <div className="text-base text-[#666]">{content}</div>
      <div className="flex justify-end gap-3">
        <Button
          onClick={handleOnClose}
          variant="outlined"
          tabIndex={3}
          style={{
            color: '#666',
            borderColor: '#B3B3B3',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOnSubmit}
          tabIndex={2}
          variant="contained"
          style={{
            backgroundColor: '#B8341B',
            color: '#FFF',
          }}
        >
          Discard
        </Button>
      </div>
    </div>
  </Dialog>
);

export default ConfirmDiscardsDialog;
