import React from 'react';

const VerificationFailed = () => (
  <div className="flex items-center justify-center py-20">
    <div className="flex flex-col items-center gap-4 text-center text-white">
      <h1 className="text-[48px] font-bold ">Oops!!!</h1>
      <img src="/images/time.png" width={132} height={132} />
      <p className="text-2xl font-bold">Email Verification Failed!</p>
      <p>
        We're sorry, but it seems the verification link has expired or invalid.
      </p>
      <p>Please register again.</p>
    </div>
  </div>
);

export default VerificationFailed;
