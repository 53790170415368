import React, {
  type HtmlHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  FormProvider,
  useForm,
  type UseFormClearErrors,
  type UseFormSetError,
} from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Tooltip as MuiTooltip,
  tooltipClasses,
  type TooltipProps,
} from '@mui/material';
import _ from 'lodash';
import * as z from 'zod';

import { useCreateDataset } from '../../hooks';
import {
  useGetDatasetById,
  useUpdateDataset,
} from '../../hooks/useDatasetsHook';
import { useCalculateEndPeriod } from '../../hooks/useUtilsHook';
import HelpCircle from '../../icons/HelpCircle';
import { type Granularity, type Position } from '../../types';
import { handleQueryError } from '../../utils/api';
import cn from '../../utils/cn';
import { LaborGroup } from '../../utils/constants';
import {
  generateGranularityList,
  generateMonthList,
  generateWeekList,
  generateYearList,
  transformDateDisplay,
  transformToLabel,
  transformToValue,
  useCurrentOrganization,
} from '../../utils/helpers';
import ConfirmDiscardsDialog from '../ConfirmDiscardsDialog';
import FormAutoComplete from '../FormAutoComplete';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';
import SectionLabel from '../SectionLabel';
import SectionLine from '../SectionLine';

import AircraftTable from './Aircraft/AircraftTable';
import ConfirmUpdateDatasetDialog from './ConfirmUpdateDatasetDialog';
import ErrorsAlertDialog from './ErrorsAlertDialog';
import PositionsSection from './PositionsSection';

const CreateDatasetSchema = z.object({
  name: z.string().min(2, 'This field is required').trim(),
  description: z.string().trim().optional(),
  granularity: z.enum(['yearly', 'monthly', 'weekly']),
  startDate: z.string().trim(),
  endDate: z.string().trim(),
  startPeriodYear: z.string().trim(),
  startPeriodMonth: z.string().trim(),
  startPeriodWeek: z.string().trim(),
  retirementAge: z.string().trim(),
  yearsOfService: z.string().trim(),
  duration: z.string().trim(),
  aircrafts: z.array(
    z.object({
      hierarchy: z.number().optional(),
      aircraft: z.string().nullable(),
      airports: z.array(z.string()).nullable(),
    })
  ),
  laborGroup: z.enum(['pilot', 'flight_attendant']),
});

type CreateDatasetSchemaType = z.infer<typeof CreateDatasetSchema> & {
  id: number;
  datasetId: number;
  positions: (Position | string)[];
};

type CreateDatasetDialogProps = {
  trigger?: {
    label: React.ReactNode;
    className?: HtmlHTMLAttributes<HTMLButtonElement>['className'];
  };
  defaultValues?: CreateDatasetSchemaType;
};

const longHelperText = `To configure the dataset, start by selecting the appropriate timeline - yearly, monthly, or weekly.
                        Next, define your list of aircraft types and the corresponding airports (bases) for these aircraft.
                        When selecting the labor group, highlight the default ones you wish to use, keeping in mind that only one labor group can be selected. If necessary, you can add new positions that are not listed.`;

const shortHelperText = `Only ONE Labor Group can be used in a dataset`;

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 280,
    borderRadius: 8,
    fontSize: '14px',
    color: '#CCCCCC',
    padding: '12px 10px',
    lineHeight: '20px',
    backgroundColor: '#101828',
    left: 10,
  },

  [`& .${tooltipClasses.arrow}`]: {
    color: '#101828',
    left: '-8px !important',
  },
});

const handleValidateError = (
  data: CreateDatasetSchemaType,
  clearErrors: UseFormClearErrors<CreateDatasetSchemaType>,
  setFormError: UseFormSetError<CreateDatasetSchemaType>
) => {
  let isError = false;
  clearErrors();

  const requiredFields = [
    {
      key: 'name',
      message: 'Dataset name is required',
    },
    {
      key: 'granularity',
      message: 'Granularity is required',
    },
    {
      key: 'laborGroup',
      message: 'Labor Group is required',
    },
    {
      key: 'startPeriodYear',
      message: 'Start Period Year is required',
    },
    {
      key: 'positions',
      message: 'Positions are required',
    },
    {
      key: 'yearsOfService',
      message: 'Years of Service is required',
    },
    {
      key: 'retirementAge',
      message: 'Retirement Age is required',
    },
    {
      key: 'duration',
      message: 'Duration is required',
    },
  ];

  for (const { key, message } of requiredFields) {
    const value = data[key as keyof CreateDatasetSchemaType];

    if (
      Array.isArray(value)
        ? !value.length
        : value === undefined || value === null || value === ''
    ) {
      isError = true;
      setFormError(key as keyof CreateDatasetSchemaType, { message });
    }
  }

  if (!data.aircrafts.length || !data.aircrafts[0].aircraft) {
    isError = true;
    setFormError('aircrafts', {
      message: 'Aircraft and Airports are required',
    });
  } else {
    const emptyAircrafts = data.aircrafts.filter(
      (a) => !a.airports && a.aircraft
    );

    if (emptyAircrafts.length) {
      isError = true;
      setFormError('aircrafts', {
        message: `Aircraft ${emptyAircrafts.map((a) => a.aircraft).join(', ')} must have at least one airport`,
      });
    }
  }

  return isError;
};

const CreateDatasetDialog = ({
  trigger,
  defaultValues,
}: CreateDatasetDialogProps) => {
  const [openConfirmUpdateDialog, setOpenConfirmUpdateDialog] = useState(false);

  const { project_id: projectId } = useParams();

  const currentOrg = useCurrentOrganization();

  const [openDialogCreateDataset, setOpenDialogCreateDataset] = useState(false);

  const [openDialogConfirmDiscards, setOpenDialogConfirmDiscards] =
    useState(false);

  const [error, setError] = useState(Object);

  const yearList = useMemo(() => generateYearList(), []);
  const monthList = useMemo(() => generateMonthList(), []);
  const granularityList = useMemo(() => generateGranularityList(), []);
  const laborGroupList = useMemo(() => LaborGroup, []);
  const weekList = useMemo(() => generateWeekList(), []);

  const [searchParams, setSearchParams] = useSearchParams();
  const newQueryParameters: URLSearchParams = new URLSearchParams();

  const { mutateAsync: calculateEndPeriod } = useCalculateEndPeriod();

  const { mutateAsync: createDataset } = useCreateDataset({
    onSuccess: () => {
      handleCloseConfirmUpdate();
    },
    onError: (error) => {
      handleQueryError(error, setError);
    },
  });

  const { mutateAsync: updateDataset } = useUpdateDataset({
    onSuccess: () => {
      setOpenConfirmUpdateDialog(false);
    },
    onError: (error) => {
      handleQueryError(error, setError);
    },
  });

  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const datasetId = Number(searchParams.get('dataset_id')) ?? NaN;

  const form = useForm<CreateDatasetSchemaType>({
    resolver: zodResolver(CreateDatasetSchema),
    defaultValues: {
      name: '',
      duration: '',
      description: '',
      granularity: 'yearly',
      startPeriodYear: yearList[0].value,
      aircrafts: [
        {
          aircraft: null,
          airports: [''],
          hierarchy: 1,
        },
      ],
      yearsOfService: '5',
      retirementAge: '65',
      laborGroup: 'pilot',
    },
  });

  const {
    setValue,
    formState: { isSubmitting, errors, dirtyFields },
    watch,
    clearErrors,
    setError: setFormError,
    reset,
  } = form;

  const watchData = watch();

  const {
    granularity,
    laborGroup,
    startPeriodYear,
    duration,
    startPeriodMonth,
    startPeriodWeek,
    startDate,
  } = watchData;

  const { refetch: refetchDatasetById } = useGetDatasetById(
    {
      datasetId,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(projectId),
    },
    {
      enabled: !!datasetId && !!currentOrg?.id && !!projectId,
    }
  );

  const isDirty =
    !_.isEmpty(dirtyFields) ||
    (defaultValues?.aircrafts
      ? !_.isEqual(
          defaultValues?.aircrafts,
          watchData.aircrafts?.filter((a) => a.aircraft)
        )
      : false) ||
    (defaultValues?.positions
      ? !_.isEqual(
          defaultValues?.positions?.flatMap((p) => transformToValue(p)),
          watchData.positions?.flatMap((p) => transformToValue(p))
        )
      : false);

  useEffect(() => {
    if (Object.keys(errors).length) {
      setOpenErrorDialog(true);
    }
  }, [Object.keys(errors).length]);

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (defaultValues) {
      setValue(
        'startPeriodMonth',
        defaultValues.startPeriodMonth.length
          ? defaultValues.startPeriodMonth
          : '1'
      );
      setValue(
        'startPeriodWeek',
        defaultValues.startPeriodWeek.length
          ? defaultValues.startPeriodWeek
          : '1'
      );
      return;
    }

    if (granularity === 'monthly') {
      setValue('startPeriodMonth', '1');
      setValue('startPeriodWeek', '');
    } else if (granularity === 'weekly') {
      setValue('startPeriodWeek', '1');
      setValue('startPeriodMonth', '');
    } else {
      setValue('startPeriodMonth', '');
      setValue('startPeriodWeek', '');
    }
  }, [granularity, laborGroup, defaultValues]);

  useEffect(() => {
    const startYear =
      startPeriodYear?.split('-')[startPeriodYear.split('-').length - 1];

    let startDate;

    switch (granularity) {
      case 'yearly':
        startDate = `${startYear}`;
        break;
      case 'monthly':
        const month =
          monthList.find((month) => month.value === startPeriodMonth)?.label ??
          startPeriodMonth;

        startDate = `${month}-${startYear}`;
        break;
      case 'weekly':
        const week =
          weekList.find((week) => week.value === startPeriodWeek)?.label ??
          startPeriodWeek;

        startDate = `${week}-${startYear}`;
        break;
      default:
        startDate = '';
    }

    setValue('startDate', startDate);
  }, [
    granularity,
    duration,
    startPeriodYear,
    startPeriodMonth,
    startPeriodWeek,
    openDialogCreateDataset,
  ]);

  useEffect(() => {
    const start_period = transformDateDisplay({
      date: startDate,
      granularity: granularity as Granularity,
      to: 'value',
    });

    if (!start_period || !duration) {
      setValue('endDate', '');
      return;
    }

    calculateEndPeriod({
      start_period,
      duration: Number(duration),
      granularity: granularity as Granularity,
    }).then((res) => {
      const endDate = transformDateDisplay({
        date: res.end_period,
        granularity: granularity as Granularity,
        to: 'label',
      });

      setValue('endDate', endDate);
    });
  }, [startDate, duration, granularity]);

  const handleOpenDialogCreateDataset = () => {
    setOpenDialogCreateDataset(true);
  };

  const handleCloseDialogCreateDataset = () => {
    if (!isDirty) {
      setOpenDialogCreateDataset(false);
      return;
    }

    setOpenDialogConfirmDiscards(true);
  };

  const handleCloseConfirmDiscards = () => {
    setOpenDialogConfirmDiscards(false);
  };

  const handleOpenConfirmUpdate = () => {
    setOpenConfirmUpdateDialog(true);
  };

  const handleCloseConfirmUpdate = () => {
    setOpenConfirmUpdateDialog(false);
  };

  const handleConfirmDiscards = () => {
    setOpenDialogConfirmDiscards(false);
    setOpenDialogCreateDataset(false);
    reset();
  };

  const onSubmit = async (type: 'create' | 'update') => {
    const {
      name,
      description,
      granularity,
      startPeriodYear,
      duration,
      laborGroup,
      yearsOfService,
      retirementAge,
      aircrafts,
      endDate,
      positions,
    } = watchData;

    const periodYear =
      startPeriodYear.split('-')[startPeriodYear.split('-').length - 1];

    const isError = handleValidateError(watchData, clearErrors, setFormError);

    if (isError) {
      setOpenErrorDialog(true);
      return;
    }

    if (!currentOrg || isError) {
      return;
    }

    const formattedAircrafts = aircrafts
      .filter((a) => a.aircraft)
      .reduce(
        (acc, aircraft) => {
          acc.push({
            name: aircraft.aircraft,
            airports: aircraft.airports,
          });
          return acc;
        },
        [] as {
          name: string | null;
          airports: string[] | null;
        }[]
      );

    const formattedPositions = positions
      .map((pos) => pos)
      .reduce(
        (acc, pos) => {
          acc.push({
            name: transformToLabel(pos),
          });
          return acc;
        },
        [] as {
          name: string;
        }[]
      );

    const datasetData = {
      project: Number(projectId),
      name,
      description,
      configuration: {
        granularity,
        start_period:
          granularity === 'monthly'
            ? `${startPeriodMonth}-${periodYear}`
            : granularity === 'weekly'
              ? `${startPeriodWeek}-${periodYear}`
              : startPeriodYear,
        end_period: transformDateDisplay({
          date: endDate,
          granularity: granularity as Granularity,
          to: 'value',
        }),
        duration: Number(duration),
        aircrafts: formattedAircrafts,
        labor_group: laborGroup,
        positions: formattedPositions,
        years_of_service: Number(yearsOfService),
        retirement_age: Number(retirementAge),
      },
    };

    let dataset: { id: number };

    try {
      if (type === 'update' && defaultValues) {
        // Update dataset
        const { id } = defaultValues;

        dataset = await updateDataset({
          id,
          orgId: currentOrg.id ?? NaN,
          projectId: Number(projectId),
          data: datasetData,
        });
      } else {
        // Create dataset
        dataset = await createDataset({
          orgId: currentOrg.id ?? NaN,
          projectId: Number(projectId),
          data: {
            ...datasetData,
            name: name === defaultValues?.name ? `${name} Copy` : name,
          },
        });
      }

      if (dataset) {
        reset();
        refetchDatasetById();
        setOpenDialogCreateDataset(false);

        newQueryParameters.set('dataset_id', dataset.id.toString());
        setSearchParams(newQueryParameters);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <button
        type="button"
        className={cn(
          'rounded-[4px] px-3 py-1 bg-[#B8341B] text-white',
          trigger?.className
        )}
        onClick={handleOpenDialogCreateDataset}
      >
        {trigger?.label ? (
          trigger.label
        ) : (
          <div className="flex items-center">
            <Add /> New
          </div>
        )}
      </button>
      <Dialog
        open={openDialogCreateDataset}
        onClose={handleCloseDialogCreateDataset}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="w-full bg-[#01285F] py-5 text-center">
          <h2 className="text-2xl font-bold text-white">
            Dataset Configuration Form
          </h2>
        </DialogTitle>
        <DialogContent>
          <div className="px-12 pt-6">
            <p className="text-sm text-[#666]">
              The Dataset Configuration Form allows users to gradually add
              parameters and save their progress. Once the form is fully
              completed, users can generate and download the Input File Set
              (Excel files). These files are then filled out by the user and
              uploaded back into the system.
            </p>
            <FormProvider {...form}>
              <form className="my-6 space-y-6">
                <div className="space-y-3">
                  <FormInput
                    label="Name"
                    name="name"
                    inputProps={{
                      maxLength: 100,
                    }}
                    placeholder="Dataset Name"
                    helperText="The name must be unique within a Project."
                    fullWidth
                  />
                  <FormInput
                    label="Description"
                    name="description"
                    placeholder="(Optional)"
                    fullWidth
                    multiline
                    rows={5}
                  />
                </div>
                <div className="flex items-center gap-3 pt-4">
                  <div className="flex items-center gap-2">
                    <h3 className="text-nowrap text-xl font-bold text-[#01285F]">
                      IFS (Input File Set) Parameters
                    </h3>
                    <Tooltip title={longHelperText} placement="top-end" arrow>
                      <div>
                        <HelpCircle />
                      </div>
                    </Tooltip>
                  </div>
                  <SectionLine />
                </div>
                <div>
                  <SectionLabel label="Reporting Period" />
                  <div className="grid grid-cols-2 gap-3">
                    <FormSelect
                      label="Granularity"
                      name="granularity"
                      defaultValue={defaultValues?.granularity ?? 'yearly'}
                      options={granularityList}
                    />
                    <div className="flex gap-3">
                      <FormAutoComplete
                        fullWidth
                        label="Start Period"
                        name="startPeriodYear"
                        type="number"
                        inputProps={{
                          maxLength: 4,
                        }}
                        defaultValue={
                          defaultValues?.startPeriodYear.split('-')[
                            defaultValues?.startPeriodYear.split('-').length - 1
                          ]
                        }
                        options={yearList}
                      />
                      {granularity === 'monthly' && (
                        <FormSelect
                          fullWidth
                          label=""
                          name="startPeriodMonth"
                          defaultValue="1"
                          options={monthList}
                        />
                      )}
                      {granularity === 'weekly' && (
                        <FormAutoComplete
                          fullWidth
                          label=""
                          name="startPeriodWeek"
                          defaultValue="1"
                          options={weekList}
                        />
                      )}
                    </div>
                    <FormInput
                      name="duration"
                      type="number"
                      label="Duration"
                      defaultValue="5"
                      placeholder={`No. of ${granularity ? (granularity === 'yearly' ? 'years' : granularity === 'monthly' ? 'months' : 'weeks') : ''}`}
                    />
                    <div className="col-span-2 grid grid-cols-2 gap-3">
                      <FormInput
                        label="Start"
                        disabled
                        name="startDate"
                        defaultValue={watchData.startDate}
                        style={{
                          textTransform: 'capitalize',
                        }}
                      />
                      <FormInput
                        label="End"
                        disabled
                        name="endDate"
                        defaultValue={watchData.endDate}
                        style={{
                          textTransform: 'capitalize',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <SectionLabel
                    label="Aircraft and Airports"
                    description={`Please enter the aircraft and their respective bases. Don't forget to set
                      the hierarchy of the aircraft in order of importance, as this will affect
                      prioritization in later steps.`}
                  />
                  <AircraftTable defaultValue={defaultValues?.aircrafts} />
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <h3 className="text-nowrap text-lg font-bold text-[#01285F]">
                      Labor Group and Positions
                    </h3>
                    <Tooltip title={shortHelperText} placement="top-end" arrow>
                      <div>
                        <HelpCircle />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="flex gap-3 pt-4">
                    <FormSelect
                      fullWidth
                      label="Labor Group"
                      name="laborGroup"
                      defaultValue={defaultValues?.laborGroup ?? 'pilot'}
                      options={laborGroupList}
                    />
                    <FormInput
                      fullWidth
                      label="Retirement Age"
                      name="retirementAge"
                      type="number"
                    />
                  </div>
                </div>
                {laborGroup && (
                  <PositionsSection
                    group={laborGroup}
                    defaultValue={defaultValues?.positions}
                    setFormValue={setValue}
                  />
                )}
                <div>
                  <div className="flex items-center gap-2">
                    <h3 className="text-nowrap text-lg font-bold text-[#01285F]">
                      Years of Service
                    </h3>
                    <Tooltip
                      title="Number of years of employment / seniority"
                      placement="top-end"
                      arrow
                    >
                      <div>
                        <HelpCircle />
                      </div>
                    </Tooltip>
                  </div>
                  <div className="grid grid-cols-2 py-3">
                    <FormInput
                      name="yearsOfService"
                      fullWidth
                      type="number"
                      label="Years of Service"
                    />
                  </div>
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialogCreateDataset}
                    variant="outlined"
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={!isDirty}
                    onClick={() => {
                      if (!isDirty) {
                        setOpenDialogCreateDataset(false);
                        return;
                      }

                      if (defaultValues) {
                        handleOpenConfirmUpdate();
                      } else {
                        onSubmit('create');
                      }
                    }}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    Save
                  </LoadingButton>
                </div>
                <ErrorsAlertDialog
                  open={openErrorDialog}
                  handleClose={handleCloseErrorDialog}
                />
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmDiscardsDialog
        open={openDialogConfirmDiscards}
        handleOnClose={handleCloseConfirmDiscards}
        handleOnSubmit={handleConfirmDiscards}
        content={<p>You will lost your progress once it discarded.</p>}
      />
      <ConfirmUpdateDatasetDialog
        open={openConfirmUpdateDialog}
        handleOnClose={handleCloseConfirmUpdate}
        handlePrimaryAction={() => onSubmit('create')}
        handleSecondaryAction={() => onSubmit('update')}
      />
    </>
  );
};

export default CreateDatasetDialog;
