import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import { getDataTableById, getDataTablesByDatasetId } from '../api';
import {
  type GetDataTableByIdInput,
  type GetDataTablesByDatasetIdInput,
} from '../api/dataTables';
import { type TypeDataTable } from '../common/dataTypes';

import { type DefaultQueryError } from './index';

export const useGetDataTablesByDatasetId = (
  input: GetDataTablesByDatasetIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeDataTable[]>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getDataTablesByDatasetId', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDataTable[]> =
        await getDataTablesByDatasetId(input);

      return data;
    },
    ...opts,
  });

export const useGetDataTableById = (
  input: GetDataTableByIdInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeDataTable>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getDataTableById', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeDataTable> =
        await getDataTableById(input);

      return data;
    },
    ...opts,
  });
