import React, { useEffect, useMemo } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Plus } from 'lucide-react';
import { z } from 'zod';

import { type TypeScenarioVectorCriteria } from '../../common/dataTypes';
import { useUpdateVector } from '../../hooks';
import { useGetDataTableById } from '../../hooks/useDataTablesHook';
import { useCreateVector } from '../../hooks/useVectorHook';
import cn from '../../utils/cn';
import {
  transformToLabel,
  transformToValue,
  useCurrentOrganization,
} from '../../utils/helpers';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

type CreateScenarioFormDialogProps = {
  open: boolean;
  title: string;
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: () => void;
  };
  showTrigger?: boolean;
  defaultValues?: CreateVectorSchemaType;
  handleOpenDialog: () => void;
  handleCloseDialog: () => void;
  dataset?: string;
  dataTable?: string;
};

const CreateVectorSchema = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  description: z.string().optional(),
  aircraft_type: z.string({
    required_error: 'Select an aircraft type',
  }),
  crew_type: z.string({
    required_error: 'Select a crew type',
  }),
  from: z.string({
    required_error: 'Select a start period',
  }),
  to: z.string({
    required_error: 'Select an end period',
  }),
  action: z.string({
    required_error: 'Select an action',
  }),
  field: z.string({
    required_error: 'Select a field',
  }),
  type: z.string({
    required_error: 'Select a type',
  }),
  amount: z.coerce.number().gt(0, 'Enter a positive number'),
});

type CreateVectorSchemaType = z.infer<typeof CreateVectorSchema>;

const VECTOR_DIRECTION_INCREASE = 'increase';
const VECTOR_DIRECTION_DECREASE = 'decrease';
const VECTOR_DIRECTION_SET = 'set';
const VECTOR_TYPE_DEFAULT = 'default';
const VECTOR_TYPE_PERCENTAGE = 'percentage';

const CreateVectorFormDialog = ({
  open,
  title,
  trigger,
  showTrigger = true,
  defaultValues,
  handleOpenDialog,
  handleCloseDialog,
}: CreateScenarioFormDialogProps) => {
  const currentOrg = useCurrentOrganization();

  const form = useForm<CreateVectorSchemaType>({
    resolver: zodResolver(CreateVectorSchema),
    defaultValues,
  });

  const { formState, handleSubmit, watch, reset } = form;

  const { isSubmitting } = formState;

  const [searchParams] = useSearchParams();

  const { project_id, org_id } = useParams();

  const dataTableId = searchParams.get('dataTable_id');
  const scenarioId = searchParams.get('scenario_id');

  const { data: dataTable } = useGetDataTableById(
    {
      dataTableId: Number(dataTableId) ?? NaN,
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
    },
    {
      enabled: !!dataTableId,
    }
  );

  const { mutate: createVector } = useCreateVector({
    onError(error) {
      console.error(error);
    },
    onSuccess() {
      handleCloseDialogCreateVector();
    },
  });

  const { mutate: updateVector } = useUpdateVector({
    onSuccess() {
      handleCloseDialogCreateVector();
    },
  });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const filterableColumns = dataTable?.schema.filterable_columns ?? [];

  const filterableFormFields = useMemo(() => {
    const data = dataTable?.data ?? null;

    if (!data) {
      return;
    }

    return filterableColumns.reduce(
      (acc, cur) => {
        const curData = Array.from(
          new Set(Object.values(data[cur] ?? {}))
        ) as string[];

        if (cur === 'period') {
          acc['from'] = curData;
          acc['to'] = curData;

          return acc;
        }

        acc[cur] = ['All'].concat(curData);

        return acc;
      },
      {} as Record<string, string[]>
    );
  }, [dataTable?.data]);

  const handleOpenDialogCreateVector = () => {
    trigger?.onClick?.();
    handleOpenDialog();
  };

  const handleCloseDialogCreateVector = () => {
    reset();
    handleCloseDialog();
  };

  const onSubmit: SubmitHandler<CreateVectorSchemaType> = (data) => {
    const { from, to, action, field, type, amount, name, description } = data;

    const tempCriterias = filterableColumns.reduce((acc, cur) => {
      const tempValue = {
        field_name: '',
        value: '',
        value_from: '',
        value_to: '',
      } as TypeScenarioVectorCriteria;

      if (cur === 'period') {
        tempValue.field_name = cur;
        tempValue.value_from = from;
        tempValue.value_to = to;
        tempValue.operator = 'in';

        acc.push(tempValue);
        return acc;
      }

      tempValue.field_name = cur;
      tempValue.value = data[cur as keyof CreateVectorSchemaType];
      tempValue.operator = 'eq';

      acc.push(tempValue);

      return acc;
    }, [] as TypeScenarioVectorCriteria[]);

    const tempVector: any = {
      name,
      description,
      scenario: Number(scenarioId),
      field_name: field,
      delta: undefined,
      delta_percentage: undefined,
      new_value: undefined,
      criterias: tempCriterias,
    };

    if (action === VECTOR_DIRECTION_INCREASE) {
      if (type === VECTOR_TYPE_DEFAULT) {
        tempVector.delta = amount;
      } else if (type === VECTOR_TYPE_PERCENTAGE) {
        tempVector.delta_percentage = amount;
      }
    } else if (action === VECTOR_DIRECTION_DECREASE) {
      if (type === VECTOR_TYPE_DEFAULT) {
        tempVector.delta = -amount;
      } else if (type === VECTOR_TYPE_PERCENTAGE) {
        tempVector.delta_percentage = -amount;
      }
    } else if (action === VECTOR_DIRECTION_SET) {
      tempVector.new_value = amount;
    }

    if (defaultValues) {
      updateVector({
        orgId: Number(org_id),
        projectId: Number(project_id),
        vectorId: Number(defaultValues.id),
        data: tempVector,
      });
      return;
    }

    createVector({
      orgId: currentOrg?.id ?? NaN,
      projectId: Number(project_id),
      scenarioId: Number(scenarioId),
      data: tempVector,
    });
  };

  const aggColumns = useMemo(
    () =>
      dataTable?.schema.pivot?.aggregate_columns.map((c) => ({
        label: c,
        value: c,
      })) ?? [],
    [dataTable]
  );

  return (
    <>
      {showTrigger && (
        <button
          onClick={handleOpenDialogCreateVector}
          className="text-start"
          type="button"
          style={trigger?.style}
        >
          {trigger ? (
            trigger.label
          ) : (
            <Button
              variant="contained"
              style={{
                height: '35px',
                color: 'white',
                padding: '2px 12px',
                display: 'flex',
                gap: '4px',
                fontSize: '16px',
                backgroundColor: '#B8341B',
                alignItems: 'center',
              }}
            >
              <Plus width={20} height={20} />
              <p>New</p>
            </Button>
          )}
        </button>
      )}
      <Dialog
        open={!!open}
        onClose={handleCloseDialogCreateVector}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-xl font-bold">{title}</h2>
        </DialogTitle>
        <DialogContent>
          <div className="z-50">
            <FormProvider {...form}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mb-2 space-y-6"
              >
                <div className="space-y-3">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="col-span-2 space-y-2">
                      <FormInput name="name" label="Name" fullWidth />
                      <FormInput
                        name="description"
                        label="Description"
                        multiline
                        rows={3}
                        fullWidth
                      />
                    </div>
                    {filterableFormFields &&
                      Object.entries(filterableFormFields).map(
                        ([key, value]) => (
                          <div
                            className={cn('col-span-2', {
                              'col-span-1': key === 'from' || key === 'to',
                            })}
                          >
                            <FormSelect
                              fullWidth
                              defaultValue={
                                key === 'to'
                                  ? value[value.length - 1]
                                  : transformToValue(value[0])
                              }
                              label={transformToLabel(key)}
                              name={key}
                              options={value
                                .filter((v) => v)
                                ?.map((v) => ({
                                  label: v,
                                  value: v,
                                }))}
                            />
                          </div>
                        )
                      )}
                  </div>
                  <div className="flex gap-3">
                    <FormSelect
                      name="action"
                      label="Action"
                      fullWidth
                      defaultValue="increase"
                      options={[
                        {
                          label: 'INCREASE',
                          value: 'increase',
                        },
                        {
                          label: 'DECREASE',
                          value: 'decrease',
                        },
                        {
                          label: 'SET',
                          value: 'set',
                        },
                      ]}
                    />
                    <FormSelect
                      name="field"
                      label="Field"
                      defaultValue={aggColumns[0]?.value}
                      fullWidth
                      options={aggColumns}
                    />
                  </div>
                  <div className="flex gap-3">
                    <FormSelect
                      name="type"
                      label="Type"
                      fullWidth
                      disabled={watch('action') === VECTOR_DIRECTION_SET}
                      defaultValue="percentage"
                      options={[
                        {
                          label: 'Percentage',
                          value: 'percentage',
                        },
                        {
                          label: 'Fixed amount (per month)',
                          value: 'fixedAmount',
                        },
                      ]}
                    />
                    <FormInput
                      name="amount"
                      label="Amount"
                      fullWidth
                      type="number"
                    />
                  </div>
                </div>
                <div className="flex-end flex justify-end gap-3">
                  <Button
                    onClick={handleCloseDialogCreateVector}
                    variant="outlined"
                    style={{
                      color: '#666',
                      borderColor: '#B3B3B3',
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                    style={{
                      backgroundColor: '#2196F3',
                      color: '#FFF',
                    }}
                  >
                    Save
                  </LoadingButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateVectorFormDialog;
