import React, { useRef, useState } from 'react';
import { type SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  styled,
} from '@mui/material';
import { UserPlus, X } from 'lucide-react';
import * as z from 'zod';

import { useShareModel } from '../../hooks/useModelsHook';
import { useGetProjectMembers } from '../../hooks/useProjectsHook';
import { useGetUser } from '../../hooks/useUserHook';
import cn from '../../utils/cn';
import { useCurrentOrganization } from '../../utils/helpers';
import SuccessDialog from '../SuccessDialog';

const Select = styled(MuiSelect)({
  fontSize: '14px',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none !important',
  },

  '& .MuiSelect-select': {},
  '& .MuiSelect-nativeInput': {},
});

const EmailSchema = z.string().email('Invalid email address');

type EmailSchemaType = z.infer<typeof EmailSchema>;

const MenuItem = styled(MuiMenuItem)({
  fontSize: '14px',
});

const InputChips = ({
  emails,
  onRemoveEmail,
}: {
  emails: { value: string; isError?: boolean }[];
  onRemoveEmail: (email: string) => void;
}) => (
  <>
    {emails.map((email) => (
      <div
        className={cn(
          'flex w-fit items-center gap-2 rounded-[4px] border-2 border-[#CCCCCC] p-1',
          {
            'border-[#B8341B] text-[#B8341B]': email.isError,
          }
        )}
      >
        <p className="text-xs">{email.value}</p>
        <X
          className="cursor-pointer"
          width={20}
          height={20}
          color={email.isError ? '#B8341B' : '#CCCCCC'}
          onClick={() => {
            onRemoveEmail(email.value);
          }}
        />
      </div>
    ))}
  </>
);

type ShareModelFormDialogProps = {
  roles: {
    label: string;
    value: string;
  }[];
  defaultProjects?: {
    id: number;
    name: string | null;
  }[];
  trigger?: {
    label: React.ReactNode;
    style?: React.CSSProperties;
  };
};

const ShareModelFormDialog = ({
  trigger,
  defaultProjects,
}: ShareModelFormDialogProps) => {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams('');

  const modelId = searchParams.get('model_id');

  const { project_id: projectId } = useParams();

  const project =
    defaultProjects && defaultProjects.length < 2 ? defaultProjects?.[0] : null;

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const [emails, setEmails] = useState<{ value: string; isError?: boolean }[]>(
    []
  );

  const ref = useRef<HTMLInputElement>(null);

  const form = useForm();

  const { handleSubmit, formState, setError, reset, register, clearErrors } =
    form;

  const { isSubmitting, errors } = formState;

  const currentOrg = useCurrentOrganization();

  const { data: currentUser } = useGetUser();

  const { data: members } = useGetProjectMembers(
    {
      projectId: project?.id ?? NaN,
      orgId: currentOrg?.id ?? NaN,
    },
    {
      enabled: !!project?.id && !!currentOrg?.id,
    }
  );

  const { mutate: shareModel } = useShareModel({
    onSuccess: () => {
      handleOpenSuccessDialog();
    },
  });

  const handleOpenSuccessDialog = () => {
    setOpenSuccessDialog(true);
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    reset();
    setEmails([]);
    setOpen(false);
  };

  const handleAddEmail = (email: EmailSchemaType) => {
    if (currentUser?.email === email) {
      setError('email', {
        message: "You're not able to share to yourself.",
      });
      setEmails((prev) => [
        ...prev,
        {
          value: email,
          isError: true,
        },
      ]);
      if (ref.current) {
        ref.current.value = '';
      }
      return;
    }

    const { error } = EmailSchema.safeParse(email);

    if (error) {
      setError('email', {
        message: error.errors[0].message,
      });
      return;
    }

    setEmails((prev) => [...prev, { value: email }]);

    if (ref.current) {
      ref.current.value = '';
    }
  };

  const handleRemoveEmail = (email: string) => {
    setEmails((prev) => prev.filter((e) => e.value !== email));

    if (errors.email) {
      clearErrors('email');
    }
  };

  const onSubmit: SubmitHandler<any> = async () => {
    if (!modelId) {
      return;
    }

    const currentEmail = ref.current?.value;

    if (currentEmail === currentUser?.email) {
      setError('email', {
        message: "You're not able to share to yourself.",
      });
      return;
    }

    if (currentEmail) {
      shareModel({
        projectId: Number(projectId),
        modelId: Number(modelId),
        orgId: currentOrg?.id ?? NaN,
        data: {
          guests: JSON.stringify([
            {
              email: currentEmail,
              role: 'project_owner',
            },
          ]),
        },
      });
    } else {
      shareModel({
        projectId: Number(projectId),
        modelId: Number(modelId),
        orgId: currentOrg?.id ?? NaN,
        data: {
          guests: JSON.stringify(
            emails.map((email) => ({
              email: email.value,
              role: 'project_owner',
            }))
          ),
        },
      });
    }

    setOpen(false);
  };

  const isDisabled = isSubmitting || !!emails.find((e) => e.isError) || false;

  return (
    <div>
      <Button
        type="button"
        variant="outlined"
        onClick={handleOpenDialog}
        style={{
          color: '#B8341B',
          borderColor: '#B8341B',
          display: 'flex',
          gap: 4,
          borderRadius: 4,
          padding: '6px 12px',
          ...trigger?.style,
        }}
      >
        {trigger?.label ?? (
          <>
            <UserPlus width={20} height={20} />
            <p>Share</p>
          </>
        )}
      </Button>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle className="w-full">
          <h2 className="pt-2 text-2xl font-bold capitalize">
            Share this model
          </h2>
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="mb-2 space-y-6">
              <div className="flex flex-col gap-2">
                <div
                  className={cn(
                    'flex items-center justify-between rounded-[4px] border-2 border-[#2196F3] px-2',
                    {
                      'border-[#B8341B]': errors.email,
                    }
                  )}
                >
                  <div className="flex w-full flex-wrap items-center gap-1 py-2">
                    <InputChips
                      emails={emails}
                      onRemoveEmail={handleRemoveEmail}
                    />
                    <input
                      ref={ref}
                      name="email"
                      type="email"
                      onKeyDown={(e) => {
                        const keyCode = e.key;

                        const isEmpty =
                          e.currentTarget.value.replace(',', '').length === 0;

                        if (isEmpty && ref.current) {
                          ref.current.value = '';
                          return;
                        }

                        if (keyCode === 'Enter' || keyCode === ',') {
                          handleAddEmail(
                            e.currentTarget.value.replace(',', '')
                          );
                        }
                      }}
                      className="grow outline-none"
                    />
                  </div>
                  <p className="w-[30%] grow rounded-[4px] p-2">
                    Project Owner
                  </p>
                </div>
                {errors['email'] && (
                  <p className="px-2 text-sm text-[#B8341B]">
                    {errors['email'].message as string}
                  </p>
                )}
              </div>
              {members &&
                members.map((m) => (
                  <div className="flex justify-between">
                    <div className="flex items-center gap-3">
                      <Avatar src={m.user.profile_pic} alt="member avatar" />
                      <div className="flex flex-col">
                        <label className="font-semibold">
                          {m.user.first_name} {m.user.last_name}
                        </label>
                        <p className="text-xs text-[#666]">{m.user.email}</p>
                      </div>
                    </div>
                    <Select
                      id="demo-simple-select"
                      size="small"
                      {...register(`members.${m.id}.role`)}
                    >
                      <MenuItem value="project_owner">Project Owner</MenuItem>
                      <MenuItem value="project_member">Project Member</MenuItem>
                    </Select>
                  </div>
                ))}
              <div className="flex-end flex justify-end gap-3">
                <Button
                  onClick={handleCloseDialog}
                  variant="outlined"
                  type="button"
                  style={{
                    color: '#666',
                    borderColor: '#B3B3B3',
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  onClick={handleSubmit((event) => onSubmit(event))}
                  loading={isSubmitting}
                  disabled={isDisabled}
                  type="button"
                  variant="contained"
                >
                  Send
                </LoadingButton>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <SuccessDialog
        open={openSuccessDialog}
        handleOnClose={handleCloseSuccessDialog}
        content="We've sent the invitation to approved users."
      />
    </div>
  );
};

export default ShareModelFormDialog;
