export default {
  home: '/',
  accountActivation: '/account-activation/',
  account: '/:org_id/account/',
  login: '/login',
  loginByLink: {
    index: '/login-by-link/',
    sent: '/login-by-link/sent/',
    fail: '/login-by-link/fail/',
    verify: '/login-by-link/verify/:token/',
  },
  auth: {
    verifyLoginByLink: '/auth/login-by-link/verify/:token/',
    verifyResetPassword: '/auth/password-reset/verify/:token/',
  },
  organization: {
    index: 'organization/',
    viewAll: 'organizations/',
    detail: 'organization/:org_id/',
    members: 'organization/:org_id/members/',
    invitations: 'organizations/:org_id/invitations/',
    invitation_verification:
      'organizations/:org_id/invitations/:invitation_id/verify/:token',
  },
  projects: {
    index: '/:org_id/projects/',
    detail: '/:org_id/projects/:project_id/',
    members: '/:org_id/projects/:project_id/members/',
    settings: '/:org_id/projects/:project_id/settings/',
    accessListById: '/:org_id/projects/:project_id/access-list/',
  },
  sharedModelVault: {
    index: '/:org_id/shared-model-vault/',
    detail: '/:org_id/shared-model-vault/:request_id/',
  },
  archivedAssets: {
    index: '/:org_id/archived-assets/',
  },
  resetPassword: {
    index: '/password-reset/',
    sent: '/password-reset/sent/',
    fail: '/password-reset/fail/',
    setPassword: '/password-reset/set-password/',
  },
  changePassword: '/password-change/',
  userManagement: {
    index: '/:org_id/user-management/',
    detail: '/:org_id/user-management/:user_id/',
  },
};
