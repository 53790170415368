import React, { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Dialog } from '@mui/material';
import { FileDown } from 'lucide-react';

import { type TypeDataset, type TypeIFS } from '../../common/dataTypes';
import useIfsConverter from '../../hooks/useIfsConverter';

type DownloadTemplatesDialogProps = {
  dataset: TypeDataset;
  ifs: TypeIFS;
};

const DownloadTemplatesDialog = ({
  dataset,
  ifs,
}: DownloadTemplatesDialogProps) => {
  const [open, setOpen] = useState(false);

  const { saveIfsAsZip } = useIfsConverter();

  const { files } = ifs;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadAllTemplates = () => {
    if (!ifs) {
      alert(
        'This dataset has no configuration, please add configuration first.'
      );
      return;
    }

    saveIfsAsZip(ifs, dataset.name);
    setOpen(false);
  };

  return (
    <>
      <LoadingButton
        onClick={handleOpen}
        variant="outlined"
        style={{
          display: 'flex',
          color: '#2196F3',
          width: 200,
          alignItems: 'center',
          gap: '4px',
        }}
      >
        <FileDown size={20} />
        Download Templates
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alertTitle"
        aria-describedby="alertContent"
      >
        <div className="flex flex-col gap-6 p-6">
          <div className="flex items-center justify-between">
            <h1 className="text-xl font-semibold">Download Files</h1>
            <Button onClick={handleDownloadAllTemplates} variant="outlined">
              <FileDown size={20} />
              Download all
            </Button>
          </div>
          <div className="flex flex-col gap-4">
            <p className="font-bold text-[#666666]">Name</p>
            {files?.map((file, index) => (
              <div key={index} className="flex justify-between">
                <p className="w-[500px] truncate">
                  {file.name.replace(file.name.split('.')[1], '')}
                </p>
                <button
                  onClick={() => {
                    saveIfsAsZip(
                      {
                        ...ifs,
                        files: [file],
                      },
                      dataset.name
                    );
                  }}
                  className="cursor-pointer"
                >
                  <FileDown size={20} color="#2196F3" />
                </button>
              </div>
            ))}
          </div>
          <div className="flex justify-end gap-3">
            <Button
              onClick={handleClose}
              variant="outlined"
              tabIndex={3}
              style={{
                color: '#666',
                borderColor: '#B3B3B3',
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DownloadTemplatesDialog;
