import React, { useEffect, useState } from 'react';
import { FormProvider, type SubmitHandler, useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Dialog, DialogTitle } from '@mui/material';
import * as z from 'zod';

import { useCreateProject } from '../../hooks';
import { useUpdateProject } from '../../hooks/useProjectsHook';
import { handleQueryError } from '../../utils/api';
import cn from '../../utils/cn';
import { useCurrentOrganization } from '../../utils/helpers';
import FormInput from '../FormInput';

const CreateProjectSchema = z.object({
  name: z.string().trim().trim().min(1, 'Project name is required'),
  description: z.string().trim().optional(),
});

type CreateProjectSchemaType = z.infer<typeof CreateProjectSchema>;

type CreateProjectDialogProps = {
  trigger?: {
    className?: React.HTMLAttributes<HTMLButtonElement>['className'];
    label: React.ReactNode;
  };
  defaultValues?: CreateProjectSchemaType & {
    id: number;
  };
};

const CreateProjectDialog = ({
  defaultValues,
  trigger,
}: CreateProjectDialogProps) => {
  const form = useForm<CreateProjectSchemaType>({
    resolver: zodResolver(CreateProjectSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = form;

  const currentOrg = useCurrentOrganization();

  const [openDialogCreateProject, setOpenDialogCreateProject] = useState(false);

  const [error, setError] = useState(Object);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { mutateAsync: createProject, isPending: isCreatingProject } =
    useCreateProject({
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  const { mutateAsync: updateProject, isPending: isUpdatingProject } =
    useUpdateProject({
      onSuccess() {
        handleCloseDialogCreateProject();
      },
      onError: (error) => {
        handleQueryError(error, setError);
      },
    });

  const handleOpenDialogCreateProject = () => {
    setOpenDialogCreateProject(true);
  };

  const handleCloseDialogCreateProject = () => {
    setOpenDialogCreateProject(false);
    reset();
  };

  const handleCreateProject: SubmitHandler<CreateProjectSchemaType> = async (
    data
  ) => {
    if (!currentOrg) {
      return;
    }

    if (defaultValues) {
      await updateProject({
        projectId: defaultValues.id,
        orgId: currentOrg.id,
        data,
      });

      return;
    }

    await createProject({
      orgId: currentOrg.id,
      data: {
        ...data,
        organization: currentOrg.id,
      },
    });

    setOpenDialogCreateProject(false);
  };

  return (
    <>
      <button
        color="primary"
        type="button"
        onClick={handleOpenDialogCreateProject}
        className={cn(
          'flex items-center rounded-[4px] px-3 py-2 gap-2 bg-[#B8341B] text-white',
          trigger?.className
        )}
      >
        {trigger?.label ?? (
          <>
            <Add
              style={{
                height: '22px',
                width: '22px',
              }}
            />
            New Project
          </>
        )}
      </button>
      <Dialog
        open={openDialogCreateProject}
        onClose={handleCloseDialogCreateProject}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {defaultValues ? `Edit ${defaultValues.name}` : 'Create New Project'}
        </DialogTitle>
        <FormProvider {...form}>
          <form
            onSubmit={handleSubmit(handleCreateProject)}
            className="space-y-4 px-6 pb-4"
          >
            <FormInput
              label="Project Name"
              fullWidth
              inputProps={{
                maxLength: 100,
              }}
              name="name"
            />
            <FormInput
              label="Project Description"
              fullWidth
              multiline
              rows={4}
              placeholder="(Optional)"
              name="description"
            />
            {typeof error.detail === 'string' && (
              <Alert severity="error">{error.detail}</Alert>
            )}
            <div className="flex justify-end gap-4">
              <Button
                onClick={handleCloseDialogCreateProject}
                variant="outlined"
                style={{
                  color: '#666',
                  borderColor: '#B3B3B3',
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isCreatingProject ?? isUpdatingProject}
                type="submit"
                variant="contained"
              >
                {defaultValues ? 'Save' : 'Create'}
              </LoadingButton>
            </div>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default CreateProjectDialog;
