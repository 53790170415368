import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useGetPreDefinedExpenses } from '../../hooks/useExpenseHook';

import CreateExpenseForm from './CreateExpenseForm';
import ExpenseDetailForm from './ExpenseDetailForm';

const ProjectViewExpense = () => {
  const [searchParams] = useSearchParams();

  const { org_id, project_id } = useParams();

  const expenseSetId = searchParams.get('expense_set_id');
  const expenseId = searchParams.get('expense_id');
  const datasetId = searchParams.get('dataset_id');

  const { data: preDefinedExpenses } = useGetPreDefinedExpenses(
    {
      orgId: Number(org_id) ?? NaN,
      projectId: Number(project_id) ?? NaN,
      datasetId: Number(datasetId) ?? NaN,
    },
    {
      enabled: !!org_id && !!project_id && !!datasetId,
    }
  );

  const expense = preDefinedExpenses?.find((e) => e.id === Number(expenseId));

  if (expenseSetId && expenseId && expense) {
    return (
      <ExpenseDetailForm
        type="default"
        defaultValues={{
          name: expense.name,
          group: expense.name,
          description: expense.description,
        }}
      />
    );
  }

  return <CreateExpenseForm />;
};

export default ProjectViewExpense;
