import {
  useMutation,
  type UseMutationOptions,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';

import { getIFS } from '../api';
import {
  generateIFS,
  type GenerateIFSInput,
  type GetIFSInputInput,
  importIFS,
  type ImportIFSInput,
} from '../api/ifsSchema';
import { type TypeIFS } from '../common/dataTypes';

import { type DefaultMutationError, type DefaultQueryError } from './index';

export const useGetIFS = (
  input: GetIFSInputInput,
  opts?: Partial<UseQueryOptions<Awaited<TypeIFS>, DefaultQueryError>>
) =>
  useQuery({
    queryKey: ['getIFS', input],
    queryFn: async () => {
      const { data }: AxiosResponse<TypeIFS> = await getIFS(input);

      return data;
    },
    ...opts,
  });

export const useGenerateIFS = (
  opts?: Partial<
    UseMutationOptions<Awaited<any>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['generateIFS'],
    mutationFn: async (input: GenerateIFSInput) => {
      const { data }: AxiosResponse<TypeIFS> = await generateIFS(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getIFS'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
    onError(error, variables, context) {
      opts?.onError?.(
        error as unknown as DefaultMutationError,
        variables,
        context
      );
    },
  });
};

export const useImportIFS = (
  opts?: Partial<
    UseMutationOptions<Awaited<any>, DefaultMutationError, any, any>
  >
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['importIFS'],
    mutationFn: async (input: ImportIFSInput) => {
      const data = await importIFS(input);

      return data;
    },
    onSuccess(data, variables, context) {
      queryClient.refetchQueries({
        queryKey: ['getIFS'],
      });
      queryClient.refetchQueries({
        queryKey: ['getDataTablesByDatasetId'],
      });
      queryClient.refetchQueries({
        queryKey: ['getPreDefinedExpenses'],
      });
      opts?.onSuccess?.(data, variables, context);
    },
  });
};
