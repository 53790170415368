import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useLoginByVerifyLink } from '../hooks';

function LoginByLinkVerify() {
  // Get the token from the magic link
  const { token } = useParams();
  const navigate = useNavigate();

  const { mutate } = useLoginByVerifyLink({
    onSuccess: (data) => {
      navigate('/', { replace: true });
    },
    onError: (e) => {
      navigate('/login-by-link/fail/', { replace: true });
    },
  });

  useEffect(() => {
    if (!token) {
      navigate('/login-by-link/fail/', { replace: true });
      return;
    }

    const data = {
      token,
    };

    try {
      mutate({ data });
    } catch (error) {
      console.log('Error');
    }
  }, [token]);

  return <div className="h-screen w-screen bg-blue-950" />;
}

export default LoginByLinkVerify;
