import { mainApi } from '../utils/api';

export type CreateModelInput = {
  orgId: number;
  projectId: number;
  data: {
    name: string;
    description?: string;
    dataset: number;
    scenarios: number[];
  };
};

export type UpdateModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  data: {
    name?: string;
    description?: string;
    dataset: number;
    scenarios?: number[];
  };
};

export type GetModelsInput = {
  id: number;
  params: Record<string, any>;
};

export type ShareModelInput = {
  modelId: number;
  orgId: number;
  projectId: number;
  data: {
    guests: string;
  };
};

export type GetModelsByDatasetIdInput = {
  orgId: number;
  projectId: number;
  datasetId: number;
};

export type GetModelByIdInput = {
  modelId: number;
  orgId: number;
  projectId: number;
};

export type GetModelOutputInput = {
  orgId: number;
  projectId: number;
  modelId: number;
  data: {
    filters?: {
      aircraft_type?: string;
      employee_id?: number;
    };
  };
};

export type DeleteModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type CloneModelInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

export type ShareModelToHostInput = {
  orgId: number;
  projectId: number;
  modelId: number;
};

const shareModel = ({ orgId, data, projectId, modelId }: ShareModelInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/share/`,
    data
  );

const shareModelToHost = ({
  orgId,
  projectId,
  modelId,
}: ShareModelToHostInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/share_to_host/`
  );

const cloneModel = async ({ orgId, projectId, modelId }: CloneModelInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/clone/`
  );

const getModelsByDatasetId = ({
  orgId,
  projectId,
  datasetId,
}: GetModelsByDatasetIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/?dataset=${datasetId}`
  );

const getModelById = ({ orgId, projectId, modelId }: GetModelByIdInput) =>
  mainApi.get(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`
  );

const runModelOutput = ({
  modelId,
  orgId,
  projectId,
  data,
}: GetModelOutputInput) =>
  mainApi.post(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/output/`,
    data
  );

const createModel = ({ orgId, projectId, data }: CreateModelInput) =>
  mainApi.post(`organizations/${orgId}/projects/${projectId}/models/`, data);

const deleteModelById = ({ orgId, projectId, modelId }: DeleteModelInput) =>
  mainApi.delete(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`
  );

const updateModel = ({ orgId, projectId, modelId, data }: UpdateModelInput) =>
  mainApi.patch(
    `organizations/${orgId}/projects/${projectId}/models/${modelId}/`,
    data
  );

export {
  cloneModel,
  createModel,
  deleteModelById,
  getModelById,
  runModelOutput,
  getModelsByDatasetId,
  shareModel,
  shareModelToHost,
  updateModel,
};
